import styled from 'styled-components';
import { TEXT_DEFAULT_STYLES } from '../../config/styles';

interface WrapperProps {
  disableCountry?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: ${(p) => (!p.disableCountry ? 'space-between' : 'flex-end')};
  height: 44px;
`;
export const Location = styled.div`
  display: flex;
  align-items: center;
  width: 144px;
  padding-left: 14px;
`;
export const Country = styled.div`
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-size: 14px;
  ${TEXT_DEFAULT_STYLES}
`;
export const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
`;
export const Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 120px;
  height: 40px;
`;
