import React, { useState } from 'react';

import './SignUp.scss';
import { LogoIcon } from '../../../assets/icons';
import { Title } from '../components';
import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { Form } from './components/form';
import { Loader } from '../../../components/Loader';

export const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PageWrapper className="signup" noFooter>
      <div className="logo">
        <LogoIcon width={97} height={66} />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Title text="Welcome to" type="signup-title" />
          <div className="form__wrapper">
            <Form setIsLoading={setIsLoading} />
          </div>
        </>
      )}
    </PageWrapper>
  );
};
