import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

export const useShareCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (campaignId: number): Promise<User> =>
      axios.post('users/share', { campaignId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.GET_CAMPAIGN]);
      },
    },
  );
};
