import React, { useMemo, useState } from 'react';
import { MenuItem, Menu } from '@material-ui/core';

import './Location.scss';
import { Button } from '../../../../components/Button/Button';
import { COLORS } from '../../../../../../config/constants';
import { useCountries } from '../../../../../../hooks/query';
import { Country } from '../../../../../../types/country.types';

interface Props {
  countryId: number;
  cityId: number;
  selectCountry: (id: number) => void;
  selectCity: (id: number) => void;
}

export const Location = ({
  countryId,
  cityId,
  selectCity,
  selectCountry,
}: Props) => {
  const { data: countries = [] } = useCountries();

  const [ddCountry, setDdCountry] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const onCloseCountry = () => setDdCountry(null);

  const [ddCity, setDdCity] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const onCloseCity = () => setDdCity(null);

  const { selectedCountry, selectedCity } = useMemo(() => {
    const selectedCountry =
      countries.find(({ id }) => id === countryId) ||
      ({
        name: 'Country',
      } as Country);
    const selectedCity = selectedCountry?.cities?.find(
      ({ id }) => id === cityId,
    ) || {
      name: 'City',
    };

    return { selectedCountry, selectedCity };
  }, [countryId, cityId, countries]);

  return (
    <div className="productDetails__location">
      <div className="title">Location</div>
      <div className="buttons">
        <Button
          type="arrowDown"
          title={selectedCountry.name}
          onClick={(e: any) => setDdCountry(e.currentTarget)}
        />
        <Menu
          style={{ width: '100%' }}
          anchorEl={ddCountry}
          getContentAnchorEl={null}
          keepMounted
          open={!!ddCountry}
          onClose={onCloseCountry}
        >
          {countries.map(({ name, id }) => (
            <MenuItem
              style={{
                backgroundColor:
                  countryId === id ? COLORS.COLOR_LIGHT_GRAY_1 : 'white',
              }}
              key={id}
              value={name}
              onClick={() => {
                selectCountry(id);
                onCloseCountry();
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
        <Button
          type="arrowDown"
          title={selectedCity.name}
          onClick={(e: any) => setDdCity(e.currentTarget)}
        />
        <Menu
          style={{ width: '100%' }}
          anchorEl={ddCity}
          getContentAnchorEl={null}
          keepMounted
          open={!!ddCity}
          onClose={onCloseCity}
        >
          {countryId && selectedCountry.cities ? (
            selectedCountry.cities.map(({ id, name }, index: number) => (
              <MenuItem
                style={{
                  backgroundColor:
                    cityId === id ? COLORS.COLOR_LIGHT_GRAY_1 : 'white',
                }}
                key={index}
                value={name}
                onClick={() => {
                  selectCity(id);
                  onCloseCity();
                }}
              >
                {name}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Please choose country first</MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
};
