import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '21'}
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.457647 2.59483C-0.130053 2.02145 -0.152587 1.06876 0.407316 0.466919C0.967219 -0.134919 1.89753 -0.157995 2.48523 0.415377C2.48523 0.415377 11.5544 9.40063 11.5791 9.42671C12.1587 10.0476 12.137 11.0321 11.5307 11.6256L2.48532 20.5829C1.89855 21.1573 0.968202 21.1358 0.407316 20.5349C-0.153569 19.934 -0.132591 18.9813 0.454171 18.4069L7.95268 10.8531C8.14618 10.6582 8.14622 10.3436 7.95276 10.1487L0.457647 2.59483Z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
};

export const ArrowRightBoldIcon = React.memo(SVGComponent);
