import DummyAvatar from '../assets/images/DefaultMyAccountAvatar.svg';

export interface Image {
  id: number;
  url: string;
}

export const getImage = (image: Image | null, defaultImage = DummyAvatar) => {
  return image?.url || defaultImage;
};
