import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '21'}
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.457648 18.4052C-0.130052 18.9785 -0.152587 19.9312 0.407316 20.5331C0.967219 21.1349 1.89753 21.158 2.48523 20.5846C2.48523 20.5846 11.5544 11.5994 11.5791 11.5733C12.1587 10.9524 12.137 9.96789 11.5307 9.37436L2.48532 0.417113C1.89856 -0.157266 0.968203 -0.135781 0.407318 0.465096C-0.153567 1.06597 -0.13259 2.01871 0.454173 2.59309L7.95268 10.1469C8.14618 10.3418 8.14622 10.6564 7.95276 10.8513L0.457648 18.4052Z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
};

export const ArrowRightIcon = React.memo(SVGComponent);
