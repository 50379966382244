import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { Follower } from './shared/components/Follower';
import { FollowingHeader } from './shared/components/Header';
import { useUser } from '../../hooks/query/useUser';
import { useAuthContext } from '../../contexts/authContext';

interface QueryParams {
  username: string;
}

export const UserFollowings = () => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const { username } = useParams<QueryParams>();
  const { data: user, isSuccess: isUserSuccess } = useUser({ username });

  useEffect(() => {
    if (loggedInAccount?.username === username) {
      history.push('/account/following');
    }
  }, [username]);

  const onAccessoryClick = () => history.push(`/users/${username}/followers`);

  return (
    <PageWrapper>
      <FollowingHeader onAccessoryClick={onAccessoryClick} />
      {isUserSuccess &&
        user.following.map(({ id, profileImage, name, username }) => (
          <Follower
            avatar={profileImage}
            name={name}
            username={username}
            key={id}
          />
        ))}
    </PageWrapper>
  );
};
