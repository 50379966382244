import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import './CampaignRegistration.scss';
import { Navbar } from './components/Navbar/Navbar';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { ProductDetails } from './stages/ProductDetails/ProductDetails';
import { Footer } from './components/Footer/Footer';
import { Commission } from './stages/Commission/Commission';
import { Price } from './stages/Price/Price';
import {
  useAddProductImages,
  useCampaign,
  useCompany,
  useCreateCampaign,
  useDeleteProductImage,
  useUpdateCampaign,
} from '../../hooks/query';
import { useAuthContext } from '../../contexts/authContext';
import { Category } from '../../types/category.types';
import ErrorModal from '../../components/Error/ErrorModal';
import { Image } from '../../utils/profileImage.util';
import { SHIPPING_OPTIONS } from './campaignRegistration.helpers';
import { ProductOption } from '../../types/company.types';
import { isCompanyLoggedIn } from '../../utils/react-query-config.util';

export interface CreateCampaignFormData {
  title: string;
  description: string;
  shortDescription: string;
  productImages: Array<File | Image>;
  productCategoryIds: number[];
  countryId: number | null;
  cityId: number | null;
  hasProductOptions: boolean;
  productOptions: ProductOption[];
  hasProductSizes: boolean;
  shippingOption: SHIPPING_OPTIONS;
  shippingInfo?: string;
  priceOfShipping?: number;
  returnPolicy: string;
  price: number | null;
  retailPrice: number | null;
  goal: number | null;
  moreThanOne: boolean;
  commissionRate: number | null;
  startDate?: Date;
  endDate?: Date;
  hasDuration: boolean;
}

export type KeyValueCollection<T> = {
  [K in keyof T]?: T[K];
};

export interface UpdateCampaignFormData {
  description: string;
  shortDescription: string;
  productCategoryIds: number[];
  countryId: number | null;
  cityId: number | null;
}

interface QueryParams {
  campaignId: string | undefined;
}

export const STAGE_OPTIONS = {
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  GOAL_PRICE: 'GOAL_PRICE',
  COMMISSION: 'COMMISSION',
  EDIT_PROJECT_DETAILS: 'EDIT_PROJECT_DETAILS',
};

export const CampaignRegistration = () => {
  const params = useParams<QueryParams>();
  const { campaignId } = params;
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();

  const [formData, setFormData] = useState<CreateCampaignFormData>({
    title: '',
    description: '',
    shortDescription: '',
    productImages: [],
    productCategoryIds: [],
    countryId: null,
    cityId: null,
    hasProductOptions: false,
    productOptions: [],
    hasProductSizes: false,
    shippingOption: SHIPPING_OPTIONS.FREE,
    shippingInfo: '',
    priceOfShipping: undefined,
    returnPolicy: '',
    price: null,
    retailPrice: null,
    goal: null,
    moreThanOne: true,
    hasDuration: false,
    commissionRate: null,
    startDate: undefined,
    endDate: undefined,
  });
  const [stage, setStage] = useState<string>(
    campaignId
      ? STAGE_OPTIONS.EDIT_PROJECT_DETAILS
      : STAGE_OPTIONS.PROJECT_DETAILS,
  );
  const { mutateAsync: createCampaign } = useCreateCampaign();
  const { mutateAsync: updateCampaign } = useUpdateCampaign();
  const { mutateAsync: addProductImage } = useAddProductImages();
  const { mutateAsync: deleteProductImage } = useDeleteProductImage();

  const id = loggedInAccount?.id as number;
  const { data: company } = useCompany(
    { id },
    { enabled: isCompanyLoggedIn(loggedInAccount) },
  );
  const { data: campaignData } = useCampaign(
    { id: campaignId as string },
    { enabled: !!campaignId },
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (campaignData) {
      const {
        title,
        description,
        shortDescription,
        productCategories,
        country,
        city,
        hasProductOptions,
        productOptions,
        hasProductSizes,
        shippingOption,
        shippingInfo,
        priceOfShipping,
        returnPolicy,
        price,
        retailPrice,
        goal,
        moreThanOne,
        commissionRate,
        hasDuration,
        productImages,
      } = campaignData;

      setFormData({
        title,
        description,
        shortDescription,
        productCategoryIds: productCategories.map(({ id }: Category) => id),
        countryId: country.id,
        cityId: city.id,
        hasProductOptions,
        productOptions,
        hasProductSizes,
        shippingOption,
        shippingInfo,
        priceOfShipping,
        returnPolicy,
        price,
        retailPrice,
        goal,
        moreThanOne,
        hasDuration,
        commissionRate: commissionRate * 100,
        productImages,
      });
    }
  }, [campaignData]);

  const handleSubmit = async () => {
    try {
      const {
        commissionRate,
        description,
        productCategoryIds,
        countryId,
        cityId,
        shortDescription,
      } = formData;

      if (campaignId) {
        await updateCampaign({
          id: parseInt(campaignId),
          data: {
            productCategoryIds,
            countryId,
            cityId,
            description,
            shortDescription,
          },
        });

        const currentImageIds =
          campaignData?.productImages.map(({ id }) => id) || [];

        const formDataImageIds = formData.productImages
          .filter((image) => {
            return !!(image as Image).id;
          })
          .map((image) => (image as Image).id || []);

        const imagesToUpload = formData.productImages.filter((image) => {
          return !(image as Image).id;
        });

        const imageIdsToDelete = currentImageIds.filter(
          (id) => !formDataImageIds.includes(id),
        );

        // TODO Rewrite this with single patch update
        await addProductImage({
          id: parseInt(campaignId),
          images: imagesToUpload as File[],
        });
        await Promise.all(
          imageIdsToDelete.map((id) =>
            deleteProductImage({
              id: parseInt(campaignId),
              imageId: id,
            }),
          ),
        );
      } else {
        await createCampaign({
          id: loggedInAccount?.id as number,
          stripeCompanyId: company?.stripeCompanyId as string,
          data: {
            ...formData,
            commissionRate: commissionRate ? commissionRate / 100 : 0,
          },
        });
      }
      history.push('/');
    } catch (err) {
      setErrorMessage('Failed to create campaign');
      setIsErrorModalOpen(true);
    }
  };

  const changeStage = (forward = true) => {
    switch (stage) {
      case STAGE_OPTIONS.PROJECT_DETAILS:
        if (forward) {
          setStage(STAGE_OPTIONS.GOAL_PRICE);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        return;
      case STAGE_OPTIONS.GOAL_PRICE:
        setStage(
          forward ? STAGE_OPTIONS.COMMISSION : STAGE_OPTIONS.PROJECT_DETAILS,
        );
        return;
      case STAGE_OPTIONS.COMMISSION:
      case STAGE_OPTIONS.EDIT_PROJECT_DETAILS:
        if (!forward) {
          setStage(STAGE_OPTIONS.GOAL_PRICE);
        } else {
          // Handle submit
          handleSubmit();
        }
    }
  };

  const updateFormData = (key: string, value: any) => {
    setFormData(() => ({
      ...formData,
      [key]: value,
    }));
  };

  const batchUpdateFormData = (
    fields: KeyValueCollection<CreateCampaignFormData>,
  ) => {
    setFormData(() => ({
      ...formData,
      ...fields,
    }));
  };

  const handleErrorButtonClick = () => {
    setErrorMessage('');
    setIsErrorModalOpen(false);
  };

  return (
    <PageWrapper className="campaignRegistration" noFooter>
      <Navbar stage={stage} />
      {(stage === STAGE_OPTIONS.PROJECT_DETAILS ||
        stage === STAGE_OPTIONS.EDIT_PROJECT_DETAILS) && (
        <ProductDetails
          isEdit={!!campaignId}
          formData={formData}
          updateFormData={updateFormData}
        />
      )}
      {stage === STAGE_OPTIONS.GOAL_PRICE && (
        <Price
          formData={formData}
          updateFormData={updateFormData}
          batchUpdateFormData={batchUpdateFormData}
        />
      )}
      {stage === STAGE_OPTIONS.COMMISSION && (
        <Commission formData={formData} updateFormData={updateFormData} />
      )}
      <Footer
        onClickBackButton={() => changeStage(false)}
        onClickNextButton={() => changeStage()}
        nextButtonTitle={
          stage === STAGE_OPTIONS.COMMISSION ||
          stage === STAGE_OPTIONS.EDIT_PROJECT_DETAILS
            ? 'Finish'
            : 'Next'
        }
        backButtonTitle={
          stage === STAGE_OPTIONS.PROJECT_DETAILS ||
          stage === STAGE_OPTIONS.EDIT_PROJECT_DETAILS
            ? 'Cancel'
            : 'Back'
        }
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        subtitle={errorMessage}
        onButtonClick={handleErrorButtonClick}
      />
    </PageWrapper>
  );
};
