import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';

interface OnboardStripeAccountArgs {
  accountId: string;
  url: string;
}

export const useOnboardStripeAccount = () => {
  return useMutation(
    async ({ accountId, url }: OnboardStripeAccountArgs): Promise<any> =>
      axios.post('orders/create-account-link', { accountId, url }),
    {
      onSuccess: async (data) => {
        window.location.replace(data.url);
      },
    },
  );
};
