import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CompanyV2 } from '../../types/company.types';

export const useDeleteCompanyProfileImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number): Promise<CompanyV2> =>
      axios.delete(`companies/${id}/profile-image`),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.GET_COMPANY]),
    },
  );
};
