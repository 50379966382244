import React from 'react';

import './Navbar.scss';
import { NavbarStateProps } from './navbar.interface';
import { Stage } from './Stage/Stage';
import { stageTitles } from './navbar.config';
import { StageStateStatus } from './Stage/stage.interface';
import { STAGE_OPTIONS } from '../../CampaignRegistration';

export const Navbar = ({ stage }: NavbarStateProps) => {
  const stageList = stageTitles.map((title, index) => {
    let status: StageStateStatus = 'inactive';

    const list = Object.keys(STAGE_OPTIONS);

    if (index < list.indexOf(stage)) status = 'accepted';
    if (index === list.indexOf(stage)) status = 'active';

    return <Stage key={title} status={status} title={title} />;
  });

  return (
    <div className="navbar">
      <div className="title">Create a new campaign</div>
      <div className="stages">{stageList}</div>
    </div>
  );
};
