import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';

interface CreateCompanyStripeArgs {
  email: string;
  companyId: number;
}

export const useCreateCompanyStripe = () => {
  return useMutation(
    async ({ email, companyId }: CreateCompanyStripeArgs): Promise<any> =>
      axios.post('companies/create-company-stripe', { email, companyId }),
  );
};
