import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';

interface IsCompanyOnboardArgs {
  companyId: number;
}

export const useIsCompanyOnboard = () => {
  return useMutation(
    async ({ companyId }: IsCompanyOnboardArgs): Promise<any> =>
      axios.post('companies/check-is-company-onboard', { companyId }),
  );
};
