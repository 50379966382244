import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={29}
      height={16}
      viewBox="0 0 29 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.375 9.5c-2.25 0-6.75 1.125-6.75 3.375a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25c0-2.25-4.5-3.375-6.75-3.375zm7.448.18c.933.81 1.552 1.867 1.552 3.195v2.25H26.5a2.25 2.25 0 002.25-2.25c0-1.732-2.666-2.813-4.927-3.195zm-7.448-2.43a3.375 3.375 0 100-6.75 3.375 3.375 0 000 6.75zm5.625 0A3.375 3.375 0 0022 .5c-.36 0-.709.056-1.035.157A5.596 5.596 0 0122 3.875a5.561 5.561 0 01-1.035 3.206c.326.113.675.169 1.035.169zM10.384 5.634a1.059 1.059 0 00-1.508.011L4.875 9.761l-2.142-2.21a1.048 1.048 0 10-1.505 1.46l3.647 3.75 5.517-5.637a1.059 1.059 0 00-.008-1.49z"
        fill="#FF6D0A"
      />
    </svg>
  );
}

export const AccountMarkIcon = React.memo(SvgComponent);
