import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../config/constants';

type InitializeData = {
  campaignId: number;
  url: string;
};

export const useInitializeOrder = () => {
  return useMutation(
    async (data: InitializeData): Promise<string> =>
      axios.post('orders/initialize', data),
    {
      onSuccess: async (data) => {
        const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
        const result = await stripe?.redirectToCheckout({
          sessionId: data,
        });

        if (result?.error) {
          return result.error;
        }
      },
    },
  );
};
