import React from 'react';
import { CampaignV2 } from '../../../../types/company.types';

import { OrderRow } from '../../components/OrderRow';
import { FooterContainer } from './OrderExpandedCancelled.style';
import { CampaignExpandedCancelledProps } from '../types';
import { OrderActionButtonCTA } from '../styles';

export const OrderExpandedCancelled = ({
  startDate,
  endDate,
}: Omit<CampaignV2, 'status'>) => (
  <>
    <OrderRow title="Campaign started:" date={startDate as Date} />
    {endDate && <OrderRow title="Campaign ended:" date={endDate} />}
  </>
);

export const CampaignExpandedCancelled = ({
  startDate,
  endDate,
  cancelDate,
  onRenew,
}: CampaignExpandedCancelledProps) => (
  <>
    <OrderRow title="Campaign started:" date={startDate as Date} />
    {endDate && <OrderRow title="Campaign ended:" date={endDate} />}
    {cancelDate && (
      <OrderRow title="Campaign cancelled:" date={cancelDate as Date} />
    )}
    <FooterContainer>
      <OrderActionButtonCTA onClick={onRenew}>Renew</OrderActionButtonCTA>
    </FooterContainer>
  </>
);
