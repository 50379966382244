import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CompanyV2 } from '../../types/company.types';

interface UserCompaniesParams {
  id: number;
}

interface UserCompaniesConfig {
  enabled?: boolean;
}

export const useUserCompanies = (
  { id }: UserCompaniesParams,
  config: UserCompaniesConfig = {},
) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_COMPANIES],
    async (): Promise<CompanyV2[]> => {
      return axios.get(`users/${id}/companies`);
    },
    { ...config },
  );
};
