import React, { ChangeEvent, useState } from 'react';

import './ProductDetails.scss';
import { InputTextarea } from '../../components/Input/Input';
import { ProductImages } from './components/ProductImages/ProductImages';
import { CategoryBlock } from './components/Category/Category';
import { Location } from './components/Location/Location';
import { CategoriesPopup } from '../../components/CategoriesPopup';
import { CreateCampaignFormData } from '../../CampaignRegistration';
import { useCategories } from '../../../../hooks/query';
import { Image } from '../../../../utils/profileImage.util';
import { ShippingBlock } from './components/Shipping/Shipping';
import { ProductOptions } from './components/ProductOptions/ProductOptions';
import { ProductSizes } from './components/ProductSizes/ProductSizes';

interface Props<T> {
  formData: T;
  updateFormData: (key: keyof T, value: T[keyof T]) => void;
  isEdit: boolean;
}

export const ProductDetails = ({
  formData,
  updateFormData,
  isEdit = false,
}: Props<CreateCampaignFormData>) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  const { data: categories = [] } = useCategories();

  const toggleCategory = (id: number) => {
    const ids = formData.productCategoryIds;
    updateFormData(
      'productCategoryIds',
      ids.includes(id) ? ids.filter((_id) => id !== _id) : [...ids, id],
    );
  };

  const openModal = () => setOpenModal(true);

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.item(0);
    if (image) {
      updateFormData('productImages', [...formData.productImages, image]);
    }
  };

  const onUploadedImageRemove = (imageToRemove: Image) => {
    updateFormData(
      'productImages',
      formData.productImages.filter(
        (image) => (image as Image).id !== imageToRemove.id,
      ),
    );
  };

  const onNewImageRemove = (imageToRemove: File) => {
    updateFormData(
      'productImages',
      formData.productImages.filter(
        (image) => (image as File).name !== imageToRemove.name,
      ),
    );
  };

  const onImageRemove = (
    imageToRemove: Image | File,
    isUploadedImage = false,
  ) => {
    if (isUploadedImage) {
      onUploadedImageRemove(imageToRemove as Image);
    } else {
      onNewImageRemove(imageToRemove as File);
    }
  };

  const selectCountry = (id: number) => {
    updateFormData('countryId', id);
  };

  const selectCity = (id: number) => {
    updateFormData('cityId', id);
  };

  return (
    <div className="campaignRegistrations__productDetails">
      <div className="container">
        <div className="title">Add the information about your campaign</div>
        <div className="campaign-title">
          <InputTextarea
            readOnly={isEdit}
            title="Campaign title"
            value={formData.title}
            onChange={(e) => updateFormData('title', e.target.value)}
          />
        </div>
        <div className="short-description">
          <InputTextarea
            title="Short Description"
            value={formData.shortDescription}
            onChange={(e) => updateFormData('shortDescription', e.target.value)}
          />
        </div>
        <div className="product-description">
          Compose a concise one-sentence product description that captures the
          essence of your item and entices potential buyers to click on your
          campaign.
        </div>
        <div className="short-description">
          <InputTextarea
            title="Product Description"
            value={formData.description}
            onChange={(e) => updateFormData('description', e.target.value)}
          />
        </div>
        <div className="product-description">
          Please provide a detailed and compelling product description to inform
          potential buyers about your offering.
        </div>
        <div className="product-images">
          <ProductImages
            images={formData.productImages}
            onUpload={onUpload}
            onImageRemove={onImageRemove}
          />
        </div>
        <ProductOptions formData={formData} updateFormData={updateFormData} />
        <ProductSizes formData={formData} updateFormData={updateFormData} />
        <div className="category">
          <CategoryBlock
            openModal={openModal}
            categories={categories.filter(({ id }) =>
              formData.productCategoryIds.includes(id),
            )}
            onCategoryRemove={toggleCategory}
          />
        </div>
        <div className="location">
          <Location
            countryId={formData.countryId as number}
            cityId={formData.cityId as number}
            selectCountry={selectCountry}
            selectCity={selectCity}
          />
        </div>
        <ShippingBlock formData={formData} updateFormData={updateFormData} />
        <div className="return-policy">
          <InputTextarea
            title="Return policy"
            value={formData.returnPolicy}
            onChange={(e) => updateFormData('returnPolicy', e.target.value)}
          />
        </div>
      </div>
      <CategoriesPopup
        categories={categories}
        categoryIds={formData.productCategoryIds}
        toggleCategories={toggleCategory}
        isOpen={isOpenModal}
        setOpen={setOpenModal}
      />
    </div>
  );
};
