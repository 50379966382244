import React, { Dispatch } from 'react';
import { Circle } from '../../../../components/RadioButtonItem/Circle';
import styles from './filterModal.module.scss';
import Modal from 'react-modal';
import { WINDOW } from '../../../../config/constants';
import { useSearchContext, OPTIONS } from '../../../../contexts/searchContext';

interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<any>;
}

const FilterModal = ({ isOpen, setIsOpen }: FilterModalProps) => {
  const { filter, setFilter } = useSearchContext();

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          maxWidth: WINDOW.MAX_WIDTH - 125,
          display: 'flex',
        },
        overlay: {
          zIndex: 100,
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.title}>Arrange the list by:</div>
        <div className={styles.optionsContainer}>
          {Object.entries(OPTIONS).map(([key, value]) => (
            <div
              className={styles.optionContainer}
              key={key}
              onClick={() => setFilter(value as string)}
            >
              <Circle isVisible={value === filter} />
              <div className={styles.optionText}>{value as string}</div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
