import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { FirestoreService } from '../../services/core/firestore.service';

interface PaymentIntentParams {
  amount: number;
}

interface PaymentIntentResponse {
  client_secret: string;
}

export class PaymentVm {
  campaignId: string;

  constructor(campaignId: string) {
    this.campaignId = campaignId;
  }

  public createPaymentIntent = async () => {
    const { campaignData } = await FirestoreService.getCampaignById(
      this.campaignId,
    );
    if (campaignData) {
      const getPaymentIntent = httpsCallable<
        PaymentIntentParams,
        PaymentIntentResponse
      >(functions, 'getPaymentIntent');
      const result = await getPaymentIntent({
        amount: campaignData.price * 100,
      } as PaymentIntentParams);
      return result.data.client_secret;
    }
  };
}
