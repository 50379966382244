import styled from 'styled-components';
import { COLORS } from '../../config/constants';
import { Text } from '../Text';

export const ArrowWrapper = styled.div`
  cursor: pointer;
`;
export const Header = styled(Text)`
  color: ${COLORS.COLOR_BLACK};
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
`;
export const Description = styled(Text)`
  color: ${COLORS.COLOR_BLACK};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.07999999821186066px;
  text-align: center;

  color: ${COLORS.COLOR_BLACK};
`;
export const ContentWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 262px;
  height: 144px;
`;
export const ButtonDescription = styled(Text)`
  color: ${COLORS.COLOR_BLACK};

  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4099999964237213px;
  text-align: center;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  width: 213px;
`;
export const Frame = styled.div`
  align-items: center;
  background-color: ${COLORS.COLOR_WHITE};
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 15px 30px;
  position: relative;
  padding: 40px, 15px, 30px, 15px;
  border-radius: 20px;
  width: 90%;
  border: 1px solid rgba(238, 238, 238, 1);
  box-shadow: -2px 2px 12px 0px rgba(0, 0, 0, 0.13);
`;

export const ButtonWrapper = styled.div`
  border: 1px solid;
  border: 1px solid rgba(107, 113, 122, 1);
  border-radius: 28px;
  height: 50px;
  width: 253px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
