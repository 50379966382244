import styled from 'styled-components';
import { Text } from '../../../../components/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 12px;
  margin-top: 23px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;
export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

export const ProductsSlider = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  margin-top: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductWrapper = styled.div`
  min-width: 221px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
export const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  width: 165px;
  height: 46px;
  margin-left: 12px;
  cursor: pointer;
`;

export const LogoContainer = styled.div`
  border-radius: 30px;
  width: 60px;
  height: 60px;
  display: flex;
`;
