import styled from 'styled-components';

//Grid
export const DisplayGridWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  margin-top: 14px;
`;
export const DisplayGridProductWrapper = styled.div`
  display: flex;
  width: 220px;
  justify-content: center;
  align-items: center;

  @media (max-width: 420px) {
    width: auto;
  }

  @media (max-width: 380px) {
    width: 170px;
  }
`;

//Vertical
export const DisplayVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
`;

export const DisplayVerticalProductWrapper = styled.div`
  margin-bottom: 12px;
`;

//Horizontal
export const DisplayHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 41px;
`;
