import React, { Dispatch, useContext, useState } from 'react';
import { useDebounce } from '../hooks';
import { useCategories, useCountries } from '../hooks/query';
import { Category } from '../types/category.types';

interface ISearchContext {
  input: string;
  debouncedInput: string;
  setInput: Dispatch<string>;
  filter: string;
  setFilter: Dispatch<string>;
  // queryParamFilter: Record<string, string>;
  queryParamFilter: any;
  selectedCategoryIds: number[];
  deliveryLocationIds: number[];
  handleCategoryClick: (id: number) => void;
  handleDeliveryLocationIdSelect: (id: number) => void;
  isCategoriesSuccess: boolean;
  categories: Category[];
  getCountryNameFromId: (id: number) => string;
}

interface Props {
  children: React.ReactNode;
}

export const OPTIONS = {
  DATE_OLDEST: 'Date of creation, oldest',
  DATE_LATEST: 'Date of creation, latest',
  TRENDING: 'Trending, most',
  PROGRESS_FINISHING: 'Progress bar, finishing',
  PROGRESS_STARTING: 'Progress bar, starting',
  COMMISSION_HIGHEST: 'Commission, highest',
};

export type OptionsValue = (typeof OPTIONS)[keyof typeof OPTIONS];

export const useSearchContext = () => {
  return useContext(SearchContext);
};

const SearchContext = React.createContext<ISearchContext>({} as ISearchContext);

const mapFilterOptionToQueryParam = (value: OptionsValue) => {
  switch (value) {
    case OPTIONS.DATE_OLDEST:
      return { createdAt: 'ASC' };
    case OPTIONS.DATE_LATEST:
      return { createdAt: 'DESC' };
    case OPTIONS.TRENDING:
      return { trending: 'ASC' };
    case OPTIONS.PROGRESS_FINISHING:
      return { progress: 'DESC' };
    case OPTIONS.PROGRESS_STARTING:
      return { progress: 'ASC' };
    case OPTIONS.COMMISSION_HIGHEST:
      return { commissionRate: 'DESC' };
  }
  return { createdAt: 'DESC' };
};

export const SearchProvider = ({ children }: Props) => {
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce<string>(input, 750);
  const [filter, setFilter] = useState<string>(OPTIONS.DATE_LATEST);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const [deliveryLocationIds, setDeliveryLocationIds] = useState<number[]>([]);
  const { data: countries = [] } = useCountries();
  const { data: categories = [], isSuccess: isCategoriesSuccess } =
    useCategories();

  const handleCategoryClick = (id: number) => {
    if (selectedCategoryIds.includes(id)) {
      setSelectedCategoryIds(selectedCategoryIds.filter((_id) => _id !== id));
    } else {
      setSelectedCategoryIds([...selectedCategoryIds, id]);
    }
  };

  const handleDeliveryLocationIdSelect = (id: number) => {
    if (deliveryLocationIds.includes(id)) {
      setDeliveryLocationIds(deliveryLocationIds.filter((_id) => _id !== id));
    } else {
      setDeliveryLocationIds([...deliveryLocationIds, id]);
    }
  };

  const getCountryNameFromId = (id: number) => {
    return countries.find((country) => country.id === id)?.name || '';
  };

  return (
    <SearchContext.Provider
      value={{
        input,
        setInput,
        debouncedInput,
        filter,
        setFilter,
        queryParamFilter: mapFilterOptionToQueryParam(filter),
        selectedCategoryIds,
        handleCategoryClick,
        handleDeliveryLocationIdSelect,
        deliveryLocationIds,
        isCategoriesSuccess,
        categories,
        getCountryNameFromId,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
