import React from 'react';
import styles from './errorMessage.module.scss';

interface Props {
  children: string;
}

const ErrorMessage = ({ children }: Props) => {
  return <div className={styles.container}>{children}</div>;
};

export default ErrorMessage;
