import React from 'react';

import './Button.scss';
import {
  ButtonStateProps,
  ButtonSocialNetworkStateProps,
} from './Button.interfaces';

export const Button = ({
  type,
  children,
  className,
  onClick,
  disabled = false,
}: ButtonStateProps) => {
  return (
    <button
      className={`${className} button pointer`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const ButtonSocialNetwork = ({
  type = 'button',
  children,
  className,
}: ButtonSocialNetworkStateProps) => {
  return (
    <button
      className={`${className} button-social-network pointer`}
      type={type}
    >
      {children}
    </button>
  );
};
