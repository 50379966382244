import React, { Fragment } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RWebShare } from 'react-web-share';
import 'swiper/swiper-bundle.css';

import './Overview.scss';
import { LikeIcon, ShareArrowIcon } from '../../../../assets/icons';
import { Counter } from '../../../../components/Counter/Counter';
import { UploadedImage } from '../../../../types/company.types';
import { getImage } from '../../../../utils/profileImage.util';

SwiperCore.use([Pagination]);

export interface OverviewStateProps {
  images: UploadedImage[];
  isLiked: boolean;
  likeCount: number;
  shareCount: number;
  onLikeClick: () => void;
  handleShare: () => void;
  shareData: ShareData | null;
}

export const Overview = ({
  images,
  isLiked,
  likeCount,
  onLikeClick,
  shareData,
  shareCount,
  handleShare,
}: OverviewStateProps) => {
  return (
    <Fragment>
      <div className="social-counter__wrapper">
        <div className="social-counter">
          <Counter
            count={likeCount}
            fontCssClass="overview-social-counter-fontConfig"
          >
            <LikeIcon
              color={isLiked ? 'red' : undefined}
              onClick={onLikeClick}
            />
          </Counter>
          {shareData && (
            <Counter
              count={shareCount}
              fontCssClass="overview-social-counter-fontConfig"
            >
              <RWebShare data={shareData} onClick={handleShare}>
                <ShareArrowIcon />
              </RWebShare>
            </Counter>
          )}
        </div>
      </div>
      {images && images.length > 0 && (
        <div className="slider">
          <Swiper pagination={{ clickable: true }} style={{ width: '100%' }}>
            {images.map((image, index) => {
              return (
                <SwiperSlide className="overview-swiper-slide" key={index}>
                  <img src={getImage(image)} alt={`slider-${index}`} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </Fragment>
  );
};
