import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="4"
        y="7"
        width="23.5308"
        height="3.07317"
        rx="1.53658"
        fill={props.color || 'white'}
      />
      <rect
        x="4"
        y="14.0732"
        width="23.5308"
        height="3.07317"
        rx="1.53658"
        fill={props.color || 'white'}
      />
      <rect
        x="4"
        y="21.1465"
        width="23.5308"
        height="3.07317"
        rx="1.53658"
        fill={props.color || 'white'}
      />
    </svg>
  );
};

export const BurgerIcon = React.memo(SVGComponent);
