import React, { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 41}
    height={props.height || 43}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.5 43C31.822 43 41 33.374 41 21.5S31.822 0 20.5 0 0 9.626 0 21.5 9.178 43 20.5 43Z"
      fill="#000"
    />
    <path
      d="M23.142 20.936c.246.712.835 1.34 1.589 1.34.753 0 1.377-.617 1.228-1.356a6.82 6.82 0 0 0-5.329-5.329c-.738-.15-1.355.475-1.355 1.229 0 .753.627 1.342 1.339 1.589a4.093 4.093 0 0 1 2.528 2.527Zm5.584-.02c.107.745.707 1.36 1.46 1.36.754 0 1.372-.614 1.29-1.363a12.246 12.246 0 0 0-10.84-10.839c-.748-.082-1.36.537-1.36 1.29s.613 1.354 1.359 1.46a9.556 9.556 0 0 1 8.091 8.091Zm1.46 6.133c-1.705 0-3.341-.273-4.869-.778a1.369 1.369 0 0 0-1.391.341c-1.79 1.79-4.585 2.286-6.556.699a20.643 20.643 0 0 1-3.13-3.13c-1.588-1.971-1.091-4.767.698-6.557.382-.382.49-.914.34-1.391a15.492 15.492 0 0 1-.777-4.87A1.364 1.364 0 0 0 13.137 10H8.364A1.364 1.364 0 0 0 7 11.364 23.186 23.186 0 0 0 30.186 34.55a1.364 1.364 0 0 0 1.364-1.364v-4.773a1.364 1.364 0 0 0-1.364-1.364Z"
      fill="#fff"
    />
  </svg>
);

export const PhoneBlackIcon = memo(SvgComponent);
