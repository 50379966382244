import styled from 'styled-components';

import { COLORS } from '../../../../../config/constants';
import { Text } from '../../../../../components';

export const Container = styled.div`
  width: 100%;
  height: 105px;
  display: flex;
  padding: 24px 30px 21px 29px;
  background-color: ${COLORS.COLOR_LIGHT_GRAY_2};
`;

interface ImageWrapperProps {
  onClick?: () => void;
}

export const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  cursor: ${({ onClick }: ImageWrapperProps) =>
    onClick ? 'pointer' : 'default'};

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 138px;
  margin-left: 14px;
  height: 60px;
  justify-content: center;
`;

export const NameText = styled(Text)`
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${COLORS.COLOR_DARK_GRAY};
`;

export const NicknameText = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${COLORS.COLOR_ORANGE_1};
`;

export const IconWrapper = styled.div`
  width: 14px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
