import styled from 'styled-components';

interface Props {
  size: 'small' | 'large';
  bgColor: string;
}

export const ItemWrapper = styled.button`
  display: grid;
  align-items: center;
  grid-template-columns: 60px 1fr;
  height: 49px;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.13);
  border-radius: 25px;
  margin-top: 16px;
  text-align: left;
  width: ${(p: Props) => (p.size === 'small' ? '80%' : '100%')};
  border: none;
  background: ${(p: Props) => p.bgColor};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
