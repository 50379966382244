import React from 'react';
import { BoughtButton, Button } from '../MainInfo/Button';
import { Icon } from '../../../../components';
import { RWebShare } from 'react-web-share';
import { OrderStatus } from '../../../../components/Order/OrderButton/OrderButton.interface';
import { COLORS } from '../../../../config/constants';

interface OrderButtonProps {
  status: OrderStatus;
  moreThanOne: boolean;
  isBought: boolean;
  onClick: () => void;
  isCoBuyButtonDisabled: boolean;
  shareData?: ShareData | null;
  handleShare: () => void;
}

const OrderButton = ({
  status,
  moreThanOne,
  isBought,
  onClick,
  isCoBuyButtonDisabled,
  shareData,
  handleShare,
}: OrderButtonProps) => {
  return (
    <div className="buttons">
      {status === 'active' && (moreThanOne || (!moreThanOne && !isBought)) && (
        <Button type="large" onClick={onClick} disabled={isCoBuyButtonDisabled}>
          Co-buy
        </Button>
      )}
      {status === 'active' && !moreThanOne && isBought && (
        <BoughtButton>
          <Icon
            name="price-tag"
            width={25}
            height={25}
            style={{ marginRight: 5 }}
          />
          You placed the order
        </BoughtButton>
      )}
      {status === 'successful' && (
        <BoughtButton>
          <Icon
            name="correct-tick"
            color={COLORS.COLOR_ORANGE_1}
            style={{ marginRight: 5 }}
          />
          Campaign successful
        </BoughtButton>
      )}
      {status === 'unsuccessful' && (
        <BoughtButton>
          <Icon
            name="cross"
            width={25}
            height={25}
            style={{ marginRight: 5 }}
          />
          Campaign unsuccessful
        </BoughtButton>
      )}
      {shareData && (
        <RWebShare data={shareData} onClick={handleShare}>
          <Button type="small">Share</Button>
        </RWebShare>
      )}
    </div>
  );
};

export default OrderButton;
