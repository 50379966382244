import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import ImagePreviewPrice from '../../../../assets/images/home_product_preview_1.png';
import ImagePreviewCommission from '../../../../assets/images/home_product_preview_2.png';
import ImagePreviewCount from '../../../../assets/images/home_product_preview_3.png';
import ImagePreviewLike from '../../../../assets/images/home_product_preview_4.png';
import {
  Wrapper,
  Header,
  ImageWrapper,
  DescriptionHeader,
  Description,
  SliderContainer,
} from './ProductDetailsPreview.styles';
import 'swiper/swiper-bundle.min.css';
import './ProductDetailsPreview.scss';

SwiperCore.use([Pagination]);

const slides = [
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'The progress bar shows how close we are to reaching the campaign goal price and how much commission you can earn.',
    image: ImagePreviewPrice,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'The progress bar shows how close we are to reaching the campaign goal price and how much commission you can earn.',
    image: ImagePreviewCommission,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'The progress bar shows how close we are to reaching the campaign goal price and how much commission you can earn.',
    image: ImagePreviewCount,
  },
  {
    title: 'Progress Bar and goal of the campaign',
    text: 'The progress bar shows how close we are to reaching the campaign goal price and how much commission you can earn.',
    image: ImagePreviewLike,
  },
];

export const ProductDetailsPreview = () => {
  return (
    <Wrapper>
      <Header>Discover the campaign</Header>

      <SliderContainer>
        <Swiper
          style={{
            width: '100%',
          }}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {slides.map(({ image, title, text }, index) => {
            return (
              <SwiperSlide
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                key={index}
              >
                <ImageWrapper>
                  <img src={image} />
                </ImageWrapper>
                <DescriptionHeader>{title}</DescriptionHeader>
                <Description>{text}</Description>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderContainer>
    </Wrapper>
  );
};
