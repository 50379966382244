import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Order } from '../../types/order.types';

interface OrdersConfig {
  enabled?: boolean;
}

export const useOrders = (config: OrdersConfig = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_ORDERS],
    async (): Promise<Order[]> => {
      return axios.get('orders');
    },
    config,
  );
};
