import { DateTime } from 'luxon';

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const start = DateTime.fromJSDate(startDate);
  const end = DateTime.fromJSDate(endDate);
  return Math.floor(end.diff(start, 'days').days);
};

export const getRemainingDays = (endDate: Date) => {
  const start = DateTime.fromJSDate(new Date());
  const end = DateTime.fromJSDate(new Date(endDate));
  return start > end ? null : Math.floor(end.diff(start, 'days').days);
};
