import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.586 13a1 1 0 102 0v-2a1 1 0 10-2 0v2zm7.085 0a1 1 0 102 0V5a1 1 0 00-2 0v8zm-3.585 0a1 1 0 102 0V8a1 1 0 00-2 0v5zM0 6a6 6 0 016-6h6a6 6 0 016 6v6a6 6 0 01-6 6H6a6 6 0 01-6-6V6z"
        fill="#434343"
      />
    </svg>
  );
}

export const StatsIcon = React.memo(SvgComponent);
