import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CompanyV2 } from '../../types/company.types';
import { Config } from '../../types/react-query.types';

export const useCompanies = (config: Config = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_COMPANIES],
    async (): Promise<CompanyV2> => {
      return axios.get(`companies`);
    },
    config,
  );
};
