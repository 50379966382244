import React from 'react';

import {
  AboutCampaignWrapper,
  TitleWrapper,
  InfoWrapper,
  InfoBlock,
  StyledText,
  Button,
  ButtonWrapper,
} from './AboutCampaign.style';
import { Text } from '../../../components';
import { COLORS } from '../../../config/constants';
import { Icon } from '../../../components/Icon/Icon';
import { AboutCampaignProps } from './AboutCampaign.interface';
import { titles, texts } from './AboutCampaign.config';

export const AboutCampaign = ({
  type,
  campaignInfo,
  toggleSwitcherHidden,
  onConnectStripe,
  user,
}: AboutCampaignProps) => {
  const campaignInfoArray = [...Object.values(campaignInfo)];

  const onWithdraw = () => {
    window.location.replace('https://dashboard.stripe.com/');
  };
  return (
    <AboutCampaignWrapper>
      <TitleWrapper>
        <Text
          size={19}
          color={COLORS.COLOR_BLACK}
          weight={700}
          lineHeight="119.2%"
        >
          {titles[type]}
        </Text>
        <Icon
          onClick={toggleSwitcherHidden}
          name="arrow-down-bold"
          color={COLORS.COLOR_DARK_GRAY}
        />
      </TitleWrapper>
      <InfoWrapper>
        {texts[type].map((text, index) => {
          return (
            <InfoBlock key={index}>
              <Text
                size={24}
                color={COLORS.COLOR_BLACK}
                lineHeight="125.7%"
                weight={700}
              >
                {campaignInfoArray[index]}
              </Text>
              <StyledText
                size={10}
                weight={500}
                color={COLORS.COLOR_BLACK}
                lineHeight="127.2%"
              >
                {text}
              </StyledText>
            </InfoBlock>
          );
        })}
      </InfoWrapper>
      {!user?.stripeOnboardCompleted && (
        <ButtonWrapper>
          <Button onClick={onConnectStripe}>Connect with Stripe</Button>
        </ButtonWrapper>
      )}
      {type === 'finished' &&
        user?.stripeCustomerId &&
        user?.stripeOnboardCompleted && (
          <ButtonWrapper onClick={onWithdraw}>
            <Button>Withdraw my commissions</Button>
          </ButtonWrapper>
        )}
    </AboutCampaignWrapper>
  );
};
