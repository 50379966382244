import React from 'react';

import './Footer.scss';
import { FooterStateProps } from './footer.interface';
import { ArrowBack, ArrowNext } from './Arrow/Arrow';

export const Footer = ({
  onClickBackButton,
  onClickNextButton,
  nextButtonTitle,
  backButtonTitle,
}: FooterStateProps) => {
  return (
    <footer className="campaignRegistration__footer">
      <div className="container">
        <ArrowBack onClick={onClickBackButton} title={backButtonTitle} />
        <ArrowNext onClick={onClickNextButton} title={nextButtonTitle} />
      </div>
    </footer>
  );
};
