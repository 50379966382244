import React from 'react';
import styled from 'styled-components';
import { Text, Icon } from '../../../components';
import { COLORS } from '../../../config/constants';

interface GuiderHeaderProps {
  children: string;
}

const GuideHeaderContainer = styled.div`
  display: flex;
  height: 122px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.BACKGROUND_COLOR};
  padding: 0 25px 0 25px;
`;

const GuideHeaderText = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  line-height: 125.7%;
  display: flex;
  align-items: center;
  color: ${COLORS.COLOR_DARK_GRAY};
  margin-left: 10px;
`;

export const GuiderHeader = ({ children }: GuiderHeaderProps) => (
  <GuideHeaderContainer>
    <Icon name="book" width={24} height={18} />
    <GuideHeaderText>{children}</GuideHeaderText>
  </GuideHeaderContainer>
);
