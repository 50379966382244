import React, { useState } from 'react';
import styles from './inputMessage.module.scss';

interface Props {
  onSend: (message: string) => void;
}

const InputMessage = ({ onSend }: Props) => {
  const [value, setValue] = useState('');

  const handleSend = () => {
    onSend(value);
    setValue('');
  };

  const handleKeyDown = (key: any) => {
    if (key.code === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className={styles.sendTextContainer}>
        <div onClick={handleSend} className={styles.text}>
          Send
        </div>
      </div>
    </div>
  );
};

export default InputMessage;
