import React, { Dispatch, useState } from 'react';
import { useHistory } from 'react-router';

import './Footer.scss';
import { ButtonSocialNetwork } from '../Button';
import { GoogleIcon } from '../../../../assets/icons';
import { useGoogleLogin } from '@react-oauth/google';
import {
  LoginPayload,
  useGoogleLogin as useBackendGoogleLogin,
} from '../../../../hooks/query';
import { useAuthContext } from '../../../../contexts/authContext';

interface Props {
  setIsLoading: Dispatch<boolean>;
}

export const Footer = ({ setIsLoading }: Props) => {
  const history = useHistory();
  const { googleLogin } = useAuthContext();
  const [loginError, setLoginError] = useState('');
  const { mutateAsync: loginWithGoogle } = useBackendGoogleLogin();

  const handleGoogleLogin = async (code: string) => {
    try {
      const data = await loginWithGoogle({ code });
      googleLogin(data as LoginPayload);
      setIsLoading(false);
      history.push('/');
    } catch (err) {
      setIsLoading(false);
      setLoginError('Failed to Sign-in with Google account');
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleLogin(codeResponse.code);
    },
    onError: () => {
      setLoginError('Failed to Sign-in with Google account');
      setIsLoading(false);
    },
    flow: 'auth-code',
  });

  const handleGoogleClick = () => {
    setIsLoading(true);
    login();
  };

  return (
    <footer className="authentication_footer">
      <div className="subtitle">Or use your phone or social accounts</div>
      <div className="socials">
        <ButtonSocialNetwork className="black" onClick={() => login()}>
          <GoogleIcon onClick={handleGoogleClick} />
        </ButtonSocialNetwork>
      </div>
      {loginError && <p>{loginError}</p>}
    </footer>
  );
};
