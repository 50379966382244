import styled from 'styled-components';
import { COLORS } from '../../../config/constants';

export const OrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: ${COLORS.COLOR_WHITE};
  border: 1px solid ${COLORS.COLOR_LIGHT_GRAY_1};
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid ${COLORS.COLOR_LIGHT_GRAY_1};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  margin-bottom: 10px;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 81px;
  flex: 1 1 auto;
  padding: 10px 0 10px 10px;
`;

export const TitlesAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;

  .title,
  .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    max-width: 250px;
    height: 22px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: ${COLORS.COLOR_DARK_GRAY};
  }

  .subtitle {
    width: 114px;
    height: 18px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.08px;

    color: ${COLORS.COLOR_DARK_GRAY};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 126.2%;

  color: ${COLORS.COLOR_DARK_GRAY};

  .price {
    width: 110px;
  }

  .pcs {
    display: flex;
    justify-content: center;
    min-width: 80px;
  }

  .participants {
    width: 53px;
  }
`;
