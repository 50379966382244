import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

interface UpdateProfileImageParams {
  id: number;
}

interface UpdateProfileImageConfig {
  enabled?: boolean;
}

export const useUpdateProfileImage = (
  { id }: UpdateProfileImageParams,
  config: UpdateProfileImageConfig = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (image: File): Promise<User> =>
      axios.put(
        `users/${id}/profile-image`,
        { image },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.PROFILE]),
      ...config,
    },
  );
};
