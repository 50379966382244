import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={27}
      height={27}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.317 20.642A2.658 2.658 0 0018.8 18.8h-1.325v-3.975A1.325 1.325 0 0016.15 13.5H8.2v-2.65h2.65a1.325 1.325 0 001.325-1.325v-2.65h2.65a2.65 2.65 0 002.65-2.65v-.543a10.579 10.579 0 013.842 16.96zm-9.142 3.365C6.941 23.358 2.9 18.906 2.9 13.5c0-.822.106-1.617.278-2.372l6.347 6.347V18.8a2.65 2.65 0 002.65 2.65v2.557zM13.5.25a13.25 13.25 0 100 26.5 13.25 13.25 0 000-26.5z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const EarthIcon = React.memo(SvgComponent);
