import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20'}
      height={props.height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="10.0002"
        cy="10"
        rx="5.29412"
        ry="5.29412"
        fill={props.color || 'white'}
      />
    </svg>
  );
};

export const CircleFilledIcon = React.memo(SVGComponent);
