import React, { useMemo } from 'react';
import styles from './sentMessage.module.scss';
import { getImage, Image } from '../../utils/profileImage.util';
import avatar from '../../assets/images/DefaultAccountAvatar.svg';
import { Message } from '../../types/messages.types';
import cs from 'classnames';

interface Props {
  message: string;
  senderId: number;
  profileImage?: Image | null;
  previousMessage?: Message;
}
const SentMessage = ({
  message,
  profileImage = null,
  senderId,
  previousMessage,
}: Props) => {
  const showAvatar = useMemo(() => {
    let result = true;
    if (previousMessage?.sender === senderId) {
      result = false;
    }
    return result;
  }, [previousMessage, senderId]);

  return (
    <div className={styles.wrapper}>
      {showAvatar && (
        <div className={styles.avatarContainer}>
          <img src={getImage(profileImage, avatar)} alt="..." />
        </div>
      )}
      <div
        className={cs({
          [styles.container]: !showAvatar,
          [styles.containerWithAvatar]: showAvatar,
        })}
      >
        <div className={styles.textContainer}>
          <div className={styles.text}>{message}</div>
        </div>
      </div>
    </div>
  );
};

export default SentMessage;
