import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '22'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4918 5.50037C17.8073 4.82652 16.6952 4.83427 16.0205 5.5176L9.4625 12.1595L5.952 8.59219C5.28948 7.91894 4.19718 7.90454 3.51659 8.56008C2.83984 9.21191 2.82626 10.2799 3.48622 10.9482L9.4625 17L18.5049 7.90325C19.1701 7.23407 19.1642 6.16244 18.4918 5.50037Z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
};

export const AcceptIcon = React.memo(SVGComponent);
