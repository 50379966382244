import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { Notification } from '../../types/company.types';

interface Params {
  userId: number;
}

export const useUserNotifications = (
  { userId }: Params,
  config: Config = {},
) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_NOTIFICATIONS],
    async (): Promise<Notification[]> => {
      return axios.get(`notifications/user-notifications/${userId}`);
    },
    config,
  );
};
