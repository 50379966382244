import React from 'react';
import { COLORS } from '../../config/constants';
import { Switch } from './Switch';
import { LocalStorageService } from '../../services/core/localStorage.service';

interface ThemeChangerProps {
  selectedIndex: number;
  setSelected: (e: any) => void;
}

const SWITCHES = [
  [
    {
      circleColor: 'white',
      textColor: 'white',
      borderColor: '#434343',
      label: 'white',
      bgColor: '#434343',
    },
    {
      circleColor: COLORS.COLOR_ORANGE_1,
      textColor: '#ABABAB',
      borderColor: '#ABABAB',
      label: 'orange',
      bgColor: 'transparent',
    },
    {
      circleColor: 'black',
      textColor: '#ABABAB',
      borderColor: '#ABABAB',
      label: 'dark',
      bgColor: 'transparent',
    },
  ],
  [
    {
      circleColor: 'white',
      textColor: 'black',
      borderColor: 'black',
      label: 'white',
      bgColor: 'transparent',
    },
    {
      circleColor: COLORS.COLOR_ORANGE_1,
      textColor: COLORS.COLOR_ORANGE_1,
      borderColor: 'white',
      label: 'orange',
      bgColor: 'white',
    },
    {
      circleColor: 'black',
      textColor: 'black',
      borderColor: 'black',
      label: 'dark',
      bgColor: 'transparent',
    },
  ],
  [
    {
      circleColor: 'white',
      textColor: 'white',
      borderColor: 'black',
      label: 'white',
      bgColor: '#252525',
    },
    {
      circleColor: COLORS.COLOR_ORANGE_1,
      textColor: 'white',
      borderColor: 'black',
      label: 'orange',
      bgColor: '#252525',
    },
    {
      circleColor: 'black',
      textColor: 'black',
      borderColor: '#D4D4D4',
      label: 'dark',
      bgColor: '#D4D4D4',
    },
  ],
];

const ThemeChanger = ({ selectedIndex, setSelected }: ThemeChangerProps) => {
  const switches = SWITCHES[selectedIndex];

  const onClick = (index: number): void => {
    LocalStorageService.setItem('theme', index.toString());
    setSelected(index);
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 40,
        left: 40,
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
      }}
    >
      {switches.map(
        ({ circleColor, textColor, borderColor, label, bgColor }, index) => (
          <Switch
            key={index}
            selected={selectedIndex === index}
            label={label}
            bgColor={bgColor}
            circleColor={circleColor}
            textColor={textColor}
            borderColor={borderColor}
            onClick={() => onClick(index)}
          />
        ),
      )}
    </div>
  );
};

export default ThemeChanger;
