import React from 'react';
import { Modal } from '@material-ui/core';

import { CategoriesPopupDropdown } from './Dropdown';
import { Category } from '../../../../types/category.types';

interface Props {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleCategories: (id: number) => void;
  categories: Category[];
  categoryIds: number[];
}

export const CategoriesPopup = ({ isOpen, setOpen, ...props }: Props) => {
  const closeModal = () => setOpen(false);

  return (
    <Modal open={isOpen}>
      <CategoriesPopupDropdown onCloseModal={closeModal} {...props} />
    </Modal>
  );
};
