import React from 'react';

import { Text } from '../../../../components';
import { COLORS } from '../../../../config/constants';
import { CategoriesList } from './CategoriesList';

import {
  Wrapper,
  Header,
  CategoriesWrapper,
  CategoriesContainer,
} from './CategoriesPreview.styles';
import { useSearchContext } from '../../../../contexts/searchContext';
import { useHistory } from 'react-router-dom';

export const CategoryPreview = () => {
  const { handleCategoryClick } = useSearchContext();
  const history = useHistory();

  const onCategoryClick = (id: number) => {
    handleCategoryClick(id);
    history.push({
      pathname: '/search',
    });
  };

  return (
    <Wrapper>
      <Header>
        <Text
          color={COLORS.COLOR_LIGHT_GRAY_2}
          size={32}
          weight={700}
          lineHeight="119.2%"
        >
          Categories selection
        </Text>
      </Header>
      <CategoriesWrapper>
        <CategoriesContainer>
          <CategoriesList onClick={onCategoryClick} />
        </CategoriesContainer>
      </CategoriesWrapper>
    </Wrapper>
  );
};
