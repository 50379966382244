import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import DummyImage from '../../../../assets/images/DefaultAccountAvatar.svg';
import { Icon, Text } from '../../../../components';
import { COLORS } from '../../../../config/constants';
import { UserService } from '../../../../services/entities/user.service';
import { ActivityWrapper } from '../../shared/AccountInfo/Activity/Activity.style';
import { HEIGHT } from '../../shared/AccountInfo/Activity/Activity.config';
import { ActivityBlock } from '../../shared/AccountInfo/Activity/ActivityBlock';
import { useModal } from '../../../../contexts/modal/modal';
import { Company } from '../../../../types/company.types';
import { BrandModal } from './BrandModal';

export interface HeaderProps {
  image?: string;
  name: string;
  username: string;
  followed: number;
  shared: number;
  modalData?: Pick<
    Company,
    'description' | 'socials' | 'email' | 'contactNumber'
  >;
  onLogo?: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemoveLogo?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 20px 20px 0;
`;

const IconContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  margin-bottom: 15px;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  & input {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  & .cross-avatar {
    position: absolute;
    z-index: 100;
    width: 30px;
    height: 30px;
    background-color: #fff;
    top: 0px;
    left: 20px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NameText = styled(Text)`
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.41px;
`;

const UsernameText = styled(NameText)`
  font-weight: 600;
  font-size: 14px;
  color: ${COLORS.COLOR_ORANGE_1};
  margin-top: 10px;
`;

export const Header = ({
  image,
  name,
  username,
  followed,
  shared,
  modalData,
  onLogo,
  onRemoveLogo,
}: HeaderProps) => {
  const { openModal } = useModal();

  const data = [
    { title: 'Followers', value: followed },
    { title: 'Shared', value: shared },
  ];

  const renderModal = () => {
    if (modalData) {
      return <BrandModal title={name} modalData={modalData} />;
    }
  };

  const displayModal = () => {
    if (modalData) {
      openModal({ render: renderModal });
    }
  };

  return (
    <Container>
      <IconContainer>
        <Icon pointer name="info" onClick={displayModal} />
      </IconContainer>
      <ImageContainer>
        {onRemoveLogo && image && (
          <div className="cross-avatar" onClick={onRemoveLogo}>
            <Icon name="cross" width={12} height={12} color="black" />
          </div>
        )}
        <img src={image || DummyImage} />
        {onLogo && (
          <input
            type="file"
            name="image"
            accept=".jpeg, .png, .jpg"
            onChange={onLogo}
          />
        )}
      </ImageContainer>
      <NameText>{name}</NameText>
      <UsernameText>{UserService.getUsername(username)}</UsernameText>
      <ActivityWrapper height={HEIGHT}>
        {data.map((item, index) => (
          <ActivityBlock title={item.title} key={index} count={item.value} />
        ))}
      </ActivityWrapper>
    </Container>
  );
};
