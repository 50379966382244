import React from 'react';

import './ProductOptions.scss';
import { CreateCampaignFormData } from '../../../../CampaignRegistration';
import { InputRadioBlock } from '../../../Price/components/InputRadioBlock/InputRadioBlock';

interface ProductOptionsProps {
  formData: CreateCampaignFormData;
  updateFormData: (
    key: keyof CreateCampaignFormData,
    value: CreateCampaignFormData[keyof CreateCampaignFormData],
  ) => void;
}

export const ProductOptions = ({
  formData,
  updateFormData,
}: ProductOptionsProps) => {
  const addFields = () => {
    updateFormData('productOptions', [
      ...formData.productOptions,
      { option: '', productSizes: [{ size: '', amount: 0 }] },
    ]);
  };

  const removeFields = (index: number) => {
    const data = [...formData.productOptions];
    data.splice(index, 1);
    updateFormData('productOptions', [...data]);
  };

  const handleFormChange = (index: number, event: any) => {
    const data = [...formData.productOptions];
    data[index].option = event.target.value;
    updateFormData('productOptions', [...data]);
  };

  return (
    <div className="productDetails__productOptions">
      <InputRadioBlock
        title="Product options"
        description={`Please fill in product variation options of styles and/or colors for your product.`}
        leftRadioLabel="on"
        rightRadioLabel="off"
        name={`productOptions`}
        isActive={formData.hasProductOptions}
        setIsActive={(value) => updateFormData('hasProductOptions', value)}
      />

      {formData.hasProductOptions ? (
        <div>
          {formData.productOptions.map((input, index) => {
            return (
              <div
                className="productDetails__productOptions__productOption"
                key={index}
              >
                <fieldset>
                  <legend>Option {index + 1}</legend>
                  <input
                    name="name"
                    placeholder={`Option name`}
                    value={input.option}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </fieldset>
                <span
                  className="productDetails__productOptions__span"
                  onClick={() => removeFields(index)}
                >
                  &times;
                </span>
              </div>
            );
          })}
          <button onClick={addFields}>Add option +</button>
        </div>
      ) : null}
    </div>
  );
};
