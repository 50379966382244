import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../../../config/constants';
import { useModal } from '../../../../../contexts/modal/modal';
import { BrandModal } from '../../shared/BrandModal';
import { Company } from '../../../../../types/company.types';

const Container = styled.div`
  display: flex;
`;

const WhiteButton = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: 10px;
  border: 0;
  border-radius: 4px;
  background-color: ${COLORS.COLOR_LIGHT_GRAY_1};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 157.2%;
  font-family: 'Poppins';
`;

const ColoredButton = styled.button`
  display: flex;
  flex: 1.4;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  border-radius: 4px;
  background: linear-gradient(270deg, #eb4dbf 0%, #f98140 100%);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 157.2%;
  font-family: 'Poppins';
`;

interface ActionBarProps {
  name: string;
  isFollowing: boolean;
  onFollow: () => void;
  onUnfollow: () => void;
  modalData?: Pick<
    Company,
    'description' | 'socials' | 'email' | 'contactNumber'
  >;
}

export const ActionBar = ({
  name,
  onFollow,
  isFollowing,
  onUnfollow,
  modalData,
}: ActionBarProps) => {
  const { openModal } = useModal();

  const renderModal = () => {
    if (modalData) {
      return <BrandModal title={name} modalData={modalData} />;
    }
  };

  const displayModal = () => {
    if (modalData) {
      openModal({ render: renderModal });
    }
  };
  return (
    <Container>
      <WhiteButton onClick={displayModal}>Contact</WhiteButton>
      <WhiteButton>Share</WhiteButton>
      <ColoredButton onClick={isFollowing ? onUnfollow : onFollow}>
        {isFollowing ? 'Unfollow' : 'Follow'}
      </ColoredButton>
    </Container>
  );
};
