import styled from 'styled-components';

import { COLORS } from '../../../../config/constants';

export const Wrapper = styled.div`
  padding-top: 20px;
  padding-left: 19px;
  padding-bottom: 30px;
  background-color: ${COLORS.COLOR_WHITE};
`;

export const Header = styled.div`
  width: 245px;
  margin-bottom: 32px;
`;

export const CategoriesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CategoriesContainer = styled.div`
  width: 387px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
