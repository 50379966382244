import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { Order } from '../../types/order.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

type BuyData = {
  campaignId: number;
  sessionId: string;
  referralId?: string | null;
  productOption?: string;
  productSize?: string;
};

export const useBuyProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: BuyData): Promise<Order> => axios.post('orders/create', data),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.GET_CAMPAIGN]),
    },
  );
};
