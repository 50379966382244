import React from 'react';

import './Category.scss';
import { CategoryTag } from '../../../../../../components/Categories/CategoryTag/CategoryTag';
import { Button } from '../../../../components/Button/Button';
import { Category } from '../../../../../../types/category.types';

interface CategoryBlockProps {
  openModal: () => void;
  categories: Category[];
  onCategoryRemove: (id: number) => void;
}

export const CategoryBlock = ({
  openModal,
  categories,
  onCategoryRemove,
}: CategoryBlockProps) => {
  return (
    <div className="productDetails__category">
      <div className="title">Category</div>
      <div className="buttons">
        <Button
          type="arrowRight"
          title="Choose categories"
          onClick={openModal}
        />
      </div>
      <div className="tags">
        {categories.map(({ name, id }, index) => (
          <CategoryTag
            title={name}
            key={index}
            onRemove={() => onCategoryRemove(id)}
          />
        ))}
      </div>
    </div>
  );
};
