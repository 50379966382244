import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';

type UserData = {
  name: string;
  email: string;
  password: string;
  username: string;
};

type RegisterPayload = {
  accessToken: string;
  user: User;
};

export const useCreateUser = () => {
  return useMutation(
    async (data: UserData): Promise<RegisterPayload> =>
      axios.post('auth/register', data),
  );
};
