import React, { useState } from 'react';

import DummyAvatar from '../../../assets/images/DefaultAccountAvatar.svg';

import {
  DrawerWrapper,
  ItemsWrapper,
  MainItems,
  SecondaryItems,
} from './DrawerContent.styles';
import { AvatarContainer } from './components/AvatarContainer/AvatarContainer';
import { DrawerItem } from './components/DrawerItem/DrawerItem';
import { CompanyFooterBlock } from './components/FooterBlock';
import { companyContent } from './DrawerContent.config';
import { CompanyPopupPicker } from '../../CompanyPopupPicker/CompanyPopupPicker';
import { useHistory } from 'react-router-dom';
import { User } from '../../../types/user.types';
import { CompanyV2 } from '../../../types/company.types';
import { getImage } from '../../../utils/profileImage.util';
import { useOnboardCompanyStripe } from '../../../hooks/query/useCreateOnboardCompanyStripe';
import { useCreateCompanyStripe } from '../../../hooks/query/useCreateCompanyStripe';

interface CompanyDrawerContentProps {
  user: User;
  company: CompanyV2;
}

export const CompanyDrawerContent = ({
  user,
  company,
}: CompanyDrawerContentProps) => {
  const { mainItems, secondaryItems } = companyContent;
  const { mutate: createCompanyStripe } = useCreateCompanyStripe();
  const { mutate: onboardCompanyStripe } = useOnboardCompanyStripe();

  const [isCompanyPickerOpen, setIsCompanyPickerOpen] = useState(false);
  const history = useHistory();

  const closePicker = () => setIsCompanyPickerOpen(false);

  const onCreateCampaign = () => history.push('/campaign-registration');

  const onImage = () => history.push('/account/brand');

  const onConnectStripe = async () => {
    await createCompanyStripe(
      { email: company?.email as string, companyId: company.id },
      {
        onSuccess: (data) => {
          onboardCompanyStripe({
            accountId: data.id,
            url: window.location.href,
          });
        },
      },
    );
  };

  return (
    <DrawerWrapper>
      <CompanyPopupPicker
        open={isCompanyPickerOpen}
        onClose={closePicker}
        forceClose={closePicker}
        user={user}
      />
      <AvatarContainer
        image={getImage(company.profileImage, DummyAvatar)}
        name={company.name}
        onArrow={() => setIsCompanyPickerOpen(true)}
        onImage={onImage}
      />
      <ItemsWrapper>
        <MainItems>
          {mainItems.map((item) => (
            <DrawerItem key={item.title} {...item} borderBottom />
          ))}
        </MainItems>
        {secondaryItems && (
          <SecondaryItems>
            {secondaryItems.map((item) => (
              <DrawerItem
                key={item.title}
                {...item}
                type="secondary"
                borderTop
              />
            ))}
          </SecondaryItems>
        )}
      </ItemsWrapper>
      <CompanyFooterBlock
        onCreateCampaign={onCreateCampaign}
        onConnectStripe={onConnectStripe}
        isConnectedWithStripe={!!company.stripeCompanyOnboardCompleted}
      />
    </DrawerWrapper>
  );
};
