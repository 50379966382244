import React from 'react';

import { Loader } from '../../../../components/Loader';
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper';
import { Header } from '../shared/Header';
import { ActionBar } from './components/ActionBar';
import { DisplayGrid } from '../../../../components/DisplaySettings/displays/Displays';
import { useAuthContext } from '../../../../contexts/authContext';
import {
  useCompany,
  useDeleteCompanyProfileImage,
  useUpdateCompanyProfileImage,
} from '../../../../hooks/query';
import { getImage } from '../../../../utils/profileImage.util';
import { isCompanyLoggedIn } from '../../../../utils/react-query-config.util';

export const MyBrandAccount = () => {
  const { loggedInAccount } = useAuthContext();
  const id = loggedInAccount?.id as number;
  const { data: company, isSuccess } = useCompany(
    { id },
    { enabled: isCompanyLoggedIn(loggedInAccount) },
  );
  const { mutate: updateCompanyProfileImage } = useUpdateCompanyProfileImage();
  const { mutate: deleteCompanyProfileImage } = useDeleteCompanyProfileImage();

  const onLogo = async (e: any) => {
    const image = e.target.files?.item(0);
    updateCompanyProfileImage({ id, image });
  };

  const onDeleteLogo = async () => {
    deleteCompanyProfileImage(id);
  };
  return (
    <PageWrapper>
      {isSuccess ? (
        <>
          <Header
            name={company.name}
            username={company.username}
            shared={company.shares}
            followed={company.followers.length}
            modalData={{
              description: company.notes,
              email: company.email,
              contactNumber: company.contactNumber,
            }}
            image={getImage(company.profileImage)}
            onLogo={onLogo}
            onRemoveLogo={onDeleteLogo}
          />
          <ActionBar />
          {<DisplayGrid products={company.campaigns} />}
        </>
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  );
};
