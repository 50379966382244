import React from 'react';
import { observer } from 'mobx-react-lite';
import { AccountSlider } from '../components/AccountSlider/AccountSlider';
import { PageWrapper } from '../containers/PageWrapper/PageWrapper';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../contexts/authContext';
import { useUserNotifications } from '../hooks/query/useUserNotifications';
import { Loader } from '../components/Loader';
import { Notification } from '../components/Notification/Notification';

export const MyNotifications = observer(() => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const id = loggedInAccount?.id as number;
  const { data: notifications = [], isSuccess: isUserNotificationsSuccess } =
    useUserNotifications({ userId: id }, { enabled: !!loggedInAccount });

  return (
    <PageWrapper>
      <AccountSlider
        icon="bell"
        title="My notifications"
        onLeftArrow={() => history.push('/account/messages')}
      />

      {isUserNotificationsSuccess ? (
        notifications.map((notification) => (
          <Notification key={notification.id} {...notification} />
        ))
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  );
});
