import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '11'}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5569 1.0696C11.8074 1.342 11.8074 1.7609 11.5569 2.03331L3.77143 10.5L0.443269 6.88064C0.192677 6.60812 0.192676 6.18905 0.443268 5.91653C0.725177 5.60996 1.20886 5.60966 1.49114 5.91589L3.77143 8.38963L10.5091 1.06935C10.7912 0.762878 11.275 0.762992 11.5569 1.0696Z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
};

export const AcceptThinIcon = React.memo(SVGComponent);
