import React, { useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { Bars } from 'react-loader-spinner';
import { observer } from 'mobx-react-lite';

import DummyAvatar from '../../assets/images/DefaultAccountAvatar.svg';
import { COLORS, WINDOW } from '../../config/constants';

import { Item } from './components/Item/Item';
import { ItemAddCompany } from './components/Item/ItemAddCompany';
import { useHistory } from 'react-router-dom';
import { User } from '../../types/user.types';
import { useUserCompanies } from '../../hooks/query';
import { getImage } from '../../utils/profileImage.util';
import { SelectedAccount, useAuthContext } from '../../contexts/authContext';
import { useWindowSize } from 'usehooks-ts';
import { isDesktop } from '../../utils/ifDesktop.util';

interface CompanyPopupPickerProps extends DialogProps {
  forceClose: () => void;
  user: User;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    zIndex: 12000,
    maxWidth: ({ isDesktop }: any) =>
      isDesktop ? WINDOW.MAX_WIDTH_DESKTOP : WINDOW.MAX_WIDTH,
  },
});

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 20px;
`;

export const CompanyPopupPicker = observer(
  ({ forceClose, user, ...props }: CompanyPopupPickerProps) => {
    const { width } = useWindowSize();

    const classes = useStyles({ isDesktop: isDesktop(width) });
    const { setLoggedInAccount, loggedInAccount } = useAuthContext();
    const { data: companies = [], isSuccess } = useUserCompanies(
      {
        id: loggedInAccount?.id as number,
      },
      { enabled: !!loggedInAccount && loggedInAccount.type === 'user' },
    );
    const popupRef = useRef();
    const history = useHistory();

    const handleSelectAccount = (account: SelectedAccount) => {
      setLoggedInAccount(account);
      if (account.type === 'company') {
        history.push('/');
      }
      forceClose();
    };

    return (
      <>
        <div>
          <Dialog {...props} ref={popupRef} classes={{ paper: classes.dialog }}>
            <Item
              avatar={getImage(user.profileImage)}
              name={user.name}
              isPicked={loggedInAccount?.id === user.id}
              onClick={() =>
                handleSelectAccount({
                  id: user.id,
                  type: 'user',
                  username: user.username,
                })
              }
            />
            {isSuccess ? (
              companies.map((company) => (
                <Item
                  key={company.username}
                  avatar={getImage(company.profileImage, DummyAvatar)}
                  name={company.name}
                  isPicked={loggedInAccount?.id === company.id}
                  onClick={() =>
                    handleSelectAccount({
                      id: company.id,
                      type: 'company',
                      userId: user.id,
                    })
                  }
                />
              ))
            ) : (
              <LoaderContainer>
                <Bars color={COLORS.COLOR_ORANGE_1} height={50} width={50} />
              </LoaderContainer>
            )}
            <ItemAddCompany />
          </Dialog>
        </div>
      </>
    );
  },
);
