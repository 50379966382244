import React from 'react';

import {
  ProgressBarStateProps,
  PROGRESS_BAR_HEIGHTS,
} from './ProgressBar.types';
import './ProgressBar.scss';

export const ProgressBar = ({
  pcsMax,
  pcs,
  height = PROGRESS_BAR_HEIGHTS.SMALL,
}: ProgressBarStateProps) => {
  const filledProgressBarPercents =
    (pcs * 100) / pcsMax > 100 ? 100 : (pcs * 100) / pcsMax;

  const filledProgressBarStyle = {
    width: `${filledProgressBarPercents}%`,
    background:
      pcsMax / pcs === 1
        ? 'linear-gradient(270deg, #EB4DBF 0%, #FF6D0A 100%)'
        : '#FF6D0A',
  };

  return (
    <div className="progress-bar" style={{ height, width: '100%' }}>
      <div style={filledProgressBarStyle} className="progress-bar__filled" />
    </div>
  );
};
