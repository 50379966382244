import React from 'react';
import styled from 'styled-components';

import { Text } from '../../../components/Text';
import { COLORS } from '../../../config/constants';

interface OrderRowProps {
  title: string;
  date: Date;
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLORS.COLOR_LIGHT_GRAY_2};
`;

const Title = styled(Text)`
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  font-weight: 600;
  letter-spacing: -0.41px;
  line-height: 22px;
`;

const DateDiv = styled(Title)`
  font-weight: 500;
`;

export const OrderRow = ({ title, date }: OrderRowProps) => {
  const jsDate = new Date(date);
  return (
    <Wrapper>
      <Title>{title}</Title>
      <DateDiv>{date && jsDate.toLocaleDateString()}</DateDiv>
    </Wrapper>
  );
};
