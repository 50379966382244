import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

export const useUserCommissionProducts = () => {
  return useQuery(
    [QUERY_KEYS.GET_USER_COMMISSION_PRODUCTS],
    async (): Promise<number> => {
      return axios.get(`users/commission/user-commission-products`);
    },
  );
};
