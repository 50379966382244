import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={25}
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.578 24.425h-10.6A1.325 1.325 0 018.653 23.1V12.593L6.93 13.984a1.344 1.344 0 01-1.868 0l-3.75-3.75a1.32 1.32 0 010-1.868L9.103.576h2.2c0 1.457 1.775 2.65 3.975 2.65s3.975-1.193 3.975-2.65h2.2l7.79 7.79a1.32 1.32 0 010 1.868l-3.75 3.75a1.344 1.344 0 01-1.868 0l-1.722-1.391V23.1a1.325 1.325 0 01-1.325 1.325z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const TShirtIcon = React.memo(SvgComponent);
