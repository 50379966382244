import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11.5a5 5 0 005-5v-1a5 5 0 00-5-5H5a5 5 0 00-5 5v10l3.707-3.707a1 1 0 01.707-.293H10zm10-4.097C20 5.8 18.7 4.5 17.097 4.5a.097.097 0 00-.097.097V8.5a5 5 0 01-5 5H4.094a.094.094 0 00-.094.094A2.906 2.906 0 006.906 16.5h8.936c.101 0 .198.04.27.112L20 20.5V7.403z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
}

export const ChatIcon = React.memo(SvgComponent);
