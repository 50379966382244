import React, { useState, createContext, useContext } from 'react';
import Modal from 'react-modal';
import { Icon } from '../../components';
import { IconProps } from '../../components/Icon/Icon';
import {
  Button,
  Description,
  Title,
  Container,
  ButtonContainer,
  IconContainer,
  modalStyles,
} from './modal.styles';

interface ModalData {
  title?: string;
  text?: string;
  icon?: IconProps;
  button?: {
    text: string;
    onPress?: () => void;
  };
  render?: (handleClose: () => void) => React.ReactNode;
}

interface ModalContextMethods {
  openModal: (data: ModalData) => void;
}

const ModalContext = createContext<ModalContextMethods>(
  {} as ModalContextMethods,
);

export const useModal = () => useContext(ModalContext);

Modal.setAppElement('#root');

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<ModalData>({
    title: '',
  });

  const openModal = (data: ModalData) => {
    setData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const onButtonPress = () => {
    data.button?.onPress?.();
    handleClose();
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      <Modal isOpen={open} onRequestClose={handleClose} style={modalStyles}>
        {data.render ? (
          data.render(handleClose)
        ) : (
          <Container>
            {data.icon && (
              <IconContainer>
                <Icon {...data.icon} />
              </IconContainer>
            )}
            <Title>{data.title}</Title>
            {data.text && <Description>{data.text}</Description>}
            {data.button?.text && (
              <ButtonContainer>
                <Button onClick={onButtonPress}>{data.button.text}</Button>
              </ButtonContainer>
            )}
          </Container>
        )}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
