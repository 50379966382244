import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import styled from 'styled-components';

import { COLORS } from '../config/constants';

interface CenterContainerProps {
  absoluteCenter?: boolean;
}

type LoaderComponentProps = CenterContainerProps;

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  height: ${(p: CenterContainerProps) => (p.absoluteCenter ? '100vh' : '100%')};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Loader = ({
  absoluteCenter,
  ...loaderProps
}: LoaderComponentProps) => {
  const Component = (
    <BallTriangle
      color={COLORS.COLOR_ORANGE_1}
      height={50}
      width={50}
      {...loaderProps}
    />
  );

  return (
    <CenterContainer absoluteCenter={absoluteCenter}>
      {Component}
    </CenterContainer>
  );
};
