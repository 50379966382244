import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '15'}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 13.1C8.48521 13.1 9.90959 12.51 10.9598 11.4598C12.01 10.4096 12.6 8.98521 12.6 7.5C12.6 6.01479 12.01 4.59041 10.9598 3.5402C9.90959 2.49 8.48521 1.9 7 1.9C5.51479 1.9 4.09041 2.49 3.0402 3.5402C1.99 4.59041 1.4 6.01479 1.4 7.5C1.4 8.98521 1.99 10.4096 3.0402 11.4598C4.09041 12.51 5.51479 13.1 7 13.1ZM7 0.5C7.91925 0.5 8.8295 0.68106 9.67878 1.03284C10.5281 1.38463 11.2997 1.90024 11.9497 2.55025C12.5998 3.20026 13.1154 3.97194 13.4672 4.82122C13.8189 5.67049 14 6.58075 14 7.5C14 9.35652 13.2625 11.137 11.9497 12.4497C10.637 13.7625 8.85652 14.5 7 14.5C3.129 14.5 0 11.35 0 7.5C0 5.64348 0.737498 3.86301 2.05025 2.55025C3.36301 1.2375 5.14348 0.5 7 0.5ZM6.825 4C7.11495 4 7.35 4.23505 7.35 4.525V7.675L10.0622 9.28424C10.3031 9.42719 10.3808 9.73944 10.235 9.97863C10.0911 10.2146 9.78378 10.2903 9.54679 10.1481L6.3 8.2V4.525C6.3 4.23505 6.53505 4 6.825 4Z"
        fill={props.color || '#6B717A'}
      />
    </svg>
  );
};

export const ClocksIcon = React.memo(SVGComponent);
