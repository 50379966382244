import React from 'react';

import './Commission.scss';
import { Rate } from './components/Rate/Rate';
import { CreateCampaignFormData } from '../../CampaignRegistration';

interface Props<T> {
  formData: T;
  updateFormData: (key: keyof T, value: T[keyof T]) => void;
}

export const Commission = ({
  formData,
  updateFormData,
}: Props<CreateCampaignFormData>) => {
  return (
    <div className="campaignRegistration__commission">
      <div className="container">
        <Rate
          onChange={(event) =>
            updateFormData('commissionRate', event.target.value)
          }
          value={String(formData.commissionRate)}
        />
      </div>
    </div>
  );
};
