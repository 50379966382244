import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './App.scss';
import { AuthProvider } from '../contexts/authContext';
import { Navigation } from '../navigation';
import { ModalProvider } from '../contexts/modal/modal';
import { SearchProvider } from '../contexts/searchContext';
import { useWindowSize } from 'usehooks-ts';
import { isDesktop } from '../utils/ifDesktop.util';
import DesktopWrapper from '../components/DesktopWrapper/DesktopWrapper';
import { WebsocketProvider } from '../contexts/websocketContext';
import { GOOGLE_CLIENT_ID } from '../config/constants';

const googleClientId = GOOGLE_CLIENT_ID;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const { width } = useWindowSize();
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <WebsocketProvider>
            <ModalProvider>
              <SearchProvider>
                {isDesktop(width) ? <DesktopWrapper /> : <Navigation />}
              </SearchProvider>
            </ModalProvider>
          </WebsocketProvider>
        </AuthProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};
