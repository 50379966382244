import React from 'react';
import { observer } from 'mobx-react-lite';

import HomeTrendingImage from '../../assets/images/home_trending.png';
import HomeLocalImage from '../../assets/images/home_local.png';
import HomeEnvironmentImage from '../../assets/images/home_environment.png';
import HomePromoteImage from '../../assets/images/home_promote.png';

import { COLORS } from '../../config/constants';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { FooterPreview } from '../../components/Footer/Footer';

import { MainLayout } from './layouts/MainLayout/MainLayout';
import { ProductPreview } from './layouts/ProductsPreview/ProductsPreview';
import { CategoryPreview } from './layouts/CategoriesPreview/CategoriesPreview';
import { PreviewPagePart } from './components/PreviewPagePart/PreviewPagePart';
import { ProductDetailsPreview } from './layouts/ProductDetailsPreview/ProductDetailsPreview';
import { CommissionPreview } from './layouts/CommissionPreview/CommissionPreview';
import { Loader } from '../../components/Loader';
import {
  useCampaigns,
  useLocalCampaigns,
  useProfile,
  useTrendingCampaigns,
} from '../../hooks/query';
import { useAuthContext } from '../../contexts/authContext';
import { useHistory } from 'react-router-dom';

export const Home = observer(() => {
  const history = useHistory();
  const { data: campaigns = [], isSuccess: isCampaignsSuccess } =
    useCampaigns();
  const { data: trendingCampaigns = [] } = useTrendingCampaigns();
  const { loggedInAccount } = useAuthContext();
  const { data: user, isSuccess: isUserSuccess } = useProfile({
    enabled: !!loggedInAccount,
  });
  const { data: localCampaigns = [] } = useLocalCampaigns(
    { countryIds: user?.deliveryLocations.map(({ id }) => id) },
    { enabled: isUserSuccess && user?.deliveryLocations.length > 0 },
  );

  const { data: localCampaignsLoggedOff = [] } = useLocalCampaigns(
    {
      countryIds: process.env.REACT_APP_NODE_ENV === 'production' ? [170] : [1],
    },
    { enabled: !user },
  );

  return (
    <>
      {isCampaignsSuccess ? (
        <PageWrapper
          style={{ backgroundColor: COLORS.BACKGROUND_COLOR }}
          noFooter
        >
          <MainLayout />
          <ProductPreview products={campaigns} />
          <CategoryPreview />
          <PreviewPagePart
            title="Trending campaigns"
            image={HomeTrendingImage}
            withSlider
            products={trendingCampaigns}
            hasArrow
            arrowStyle={{
              right: 0,
              top: 4,
            }}
            hasShadow={true}
          />
          <PreviewPagePart
            title="Local choice"
            image={HomeLocalImage}
            withSlider
            products={!user ? localCampaignsLoggedOff : localCampaigns}
            hasArrow
            arrowStyle={{
              right: 0,
              top: 4,
            }}
            hasShadow={true}
          />
          <ProductDetailsPreview />
          <PreviewPagePart
            title="Co-buy and help to save the environment"
            description="By co-buying you help to reduce waste of energy, manpower and materials!"
            image={HomeEnvironmentImage}
            styles={{ height: 620, paddingTop: 163, paddingLeft: 19 }}
            products={campaigns}
          />
          <CommissionPreview />
          <div
            onClick={() =>
              !!loggedInAccount ? history.push('/') : history.push('/login')
            }
          >
            <PreviewPagePart
              title="Do you have a product that you want to promote?"
              description="Register as a  company and create your campaigns"
              image={HomePromoteImage}
              styles={{ height: 532, paddingTop: 163, paddingLeft: 19 }}
              products={campaigns}
              hasArrow
              arrowStyle={{
                right: 0,
                top: 200,
              }}
            />
          </div>
          <FooterPreview />
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  );
});
