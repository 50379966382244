import styled from 'styled-components';
import { COLORS } from '../../config/constants';

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 19px;
  background-color: ${COLORS.COLOR_WHITE};
  border-radius: 30px;
  border: 1px solid ${COLORS.COLOR_LIGHT_GRAY_1};
  cursor: pointer;
  box-shadow: -2px 2px 12px 0px rgba(0, 0, 0, 0.13);
`;

export const NotificationTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  max-width: 350px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.COLOR_BLACK};
`;

export const NotificationDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-bottom: 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.COLOR_DARK_GRAY};
`;
