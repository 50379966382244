import React from 'react';

import './ProductSizes.scss';
import { CreateCampaignFormData } from '../../../../CampaignRegistration';
import { InputRadioBlock } from '../../../Price/components/InputRadioBlock/InputRadioBlock';

interface ProductSizesProps {
  formData: CreateCampaignFormData;
  updateFormData: (
    key: keyof CreateCampaignFormData,
    value: CreateCampaignFormData[keyof CreateCampaignFormData],
  ) => void;
}

export const ProductSizes = ({
  formData,
  updateFormData,
}: ProductSizesProps) => {
  const addFields = (option: any) => {
    const data: any = formData.productOptions.map((productOption) => {
      if (option[1].option === productOption.option) {
        productOption.productSizes.push({ size: '', amount: 0 });
      }
      return productOption;
    });
    updateFormData('productOptions', data);
  };

  const removeFields = (index: number, optionsIndex: number) => {
    const data = [...formData.productOptions];
    data[optionsIndex].productSizes.splice(index, 1);
    updateFormData('productOptions', [...data]);
  };

  const handleSizeChange = (
    index: number,
    event: any,
    optionsIndex: number,
  ) => {
    const data = [...formData.productOptions];
    data[optionsIndex].productSizes[index].size = event.target.value;
    updateFormData('productOptions', [...data]);
  };

  const handleAmountChange = (
    index: number,
    event: any,
    optionsIndex: number,
  ) => {
    const data = [...formData.productOptions];
    (data[optionsIndex].productSizes[index].amount = event.target.value),
      updateFormData('productOptions', [...data]);
  };

  return (
    <div className="productDetails__productSizes">
      <InputRadioBlock
        title="Product sizes"
        description={`Please specify product sizes and the respective quantities available for each size.`}
        leftRadioLabel="on"
        rightRadioLabel="off"
        name={`productSizes`}
        isActive={formData.hasProductSizes}
        setIsActive={(value) => updateFormData('hasProductSizes', value)}
      />

      {Object.entries(formData.productOptions).map((productOption) => {
        return (
          <div>
            {formData.hasProductSizes && productOption[1].option ? (
              <div>
                <div className="productDetails__productSizes__productOption">
                  {productOption[1].option}
                </div>
                <div className="productDetails__productSizes__productSizes">
                  {productOption[1].productSizes.map((input, index) => {
                    return (
                      <div key={index}>
                        <div className="productDetails__productSizes__productSize">
                          <fieldset>
                            <legend>Size {index + 1}</legend>
                            <input
                              name={`name ${productOption[1].option}`}
                              placeholder={'Size name'}
                              value={input.size}
                              onChange={(event) =>
                                handleSizeChange(
                                  index,
                                  event,
                                  parseInt(productOption[0]),
                                )
                              }
                            />
                          </fieldset>
                          <span
                            className="productDetails__productSizes__span"
                            onClick={() =>
                              removeFields(index, parseInt(productOption[0]))
                            }
                          >
                            &times;
                          </span>
                        </div>
                        <div className="productDetails__productSizes__productAmount">
                          <fieldset>
                            <legend>Amount</legend>
                            <input
                              name="amount"
                              placeholder={`Amount ${index + 1}`}
                              value={input.amount}
                              onChange={(event) =>
                                handleAmountChange(
                                  index,
                                  event,
                                  parseInt(productOption[0]),
                                )
                              }
                            />
                          </fieldset>
                          <span>pieces</span>
                        </div>
                      </div>
                    );
                  })}
                  <button onClick={() => addFields(productOption)}>
                    Add size +
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
