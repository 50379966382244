import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { GroupedMessages } from '../../types/messages.types';

export const useUnreadMessages = (config: Config = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_UNREAD_MESSAGES],
    async (): Promise<GroupedMessages> => {
      return axios.get(`messages/unread`);
    },
    config,
  );
};
