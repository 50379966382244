import React from 'react';
import { Icon } from '../../components';
import { COLORS } from '../../config/constants';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import {
  Wrapper,
  Header,
  HeaderTitle,
  TextTitle,
  TextDescription,
  FollowersContainer,
} from './Community.style';
import { CommunityCard } from './components/CommunityCard/CommunityCard';
import { Follower } from '../Following/shared/components/Follower';
import {
  useFollowUser,
  useProfile,
  useUnfollowUser,
  useUsers,
} from '../../hooks/query';
import { useAuthContext } from '../../contexts/authContext';

export const Community = () => {
  const { data: users = [] } = useUsers();
  const { data: currentUser, isSuccess: isCurrentUserSuccess } = useProfile();
  const { mutate: followUser } = useFollowUser();
  const { mutate: unfollowUser } = useUnfollowUser();
  const { loggedInAccount } = useAuthContext();

  const onFollow = async (id: number, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser({ userId: id });
    } else {
      await unfollowUser({ userId: id });
    }
  };

  return (
    <PageWrapper>
      <Wrapper>
        <Header>
          <Icon
            name="account-group"
            width={29}
            height={17}
            color={COLORS.COLOR_ORANGE_1}
          />
          <HeaderTitle>Community</HeaderTitle>
        </Header>
        <TextTitle>Invite your friends in social media</TextTitle>
        <TextDescription>
          Share this invitation link to wecobi in social media
        </TextDescription>
        <CommunityCard />
      </Wrapper>
      {isCurrentUserSuccess && (
        <FollowersContainer>
          {users
            .filter(({ id }) => id !== loggedInAccount?.id)
            .map(({ profileImage, username, name, id }) => {
              const isFollowed = !!currentUser.following.find(
                ({ id: userId }) => userId === id,
              );
              return (
                <Follower
                  avatar={profileImage}
                  username={username}
                  name={name}
                  isFollowed={isFollowed}
                  key={id}
                  onFollow={() =>
                    onFollow(id, isFollowed ? 'unfollow' : 'follow')
                  }
                />
              );
            })}
        </FollowersContainer>
      )}
    </PageWrapper>
  );
};
