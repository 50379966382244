import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.height || '13'}
      height={props.height || '13'}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.486346 7.65935C0.186177 7.36451 0 6.954 0 6.5C0 5.60254 0.727538 4.875 1.625 4.875H4.875V1.625C4.875 0.727537 5.60254 0 6.5 0C7.39746 0 8.125 0.727537 8.125 1.625V4.875H8.12502V4.87502H11.375C11.6932 4.87502 11.99 4.96644 12.2406 5.12445C12.4318 5.24503 12.5961 5.40437 12.7225 5.59145C12.8977 5.8508 13 6.16345 13 6.5C13 7.39746 12.2725 8.125 11.375 8.125H8.125V11.375C8.125 12.2725 7.39746 13 6.5 13C6.22568 13 5.96723 12.932 5.74059 12.812C5.5038 12.6866 5.30173 12.5044 5.15252 12.2836C4.97732 12.0242 4.87502 11.7116 4.87502 11.375V8.12502H4.875V8.125H1.625C1.18153 8.125 0.779555 7.94736 0.486346 7.65935Z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
};

export const PlusIcon = React.memo(SVGComponent);
