import React from 'react';

import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { GuiderHeader } from '../Guide/components/GuideHeader';
import { GuideRow, GuideStandaloneText } from '../Guide/components/GuideRow';

const DESC_DATA = [
  {
    title: 'Step 1: Create Your Wecobi Account',
    text: `Sign up for a free account. Provide your details, including your name, email address, and password. It's quick and easy.`,
  },
  {
    title: 'Step 2: Browse and Shop',
    text: 'Explore the wide range of products available on Wecobi. Discover unique items and great deals from various brands.',
  },
  {
    title: 'Step 3: Find Commission-Eligible Products',
    text: `Look for products marked with a "Commission" tag. These are the items that qualify for earning a commission when you share them on social media.`,
  },
  {
    title: 'Step 4: Make Your Purchase',
    text: `Select the products you want to buy and add them to your cart. Proceed to the checkout page to complete your purchase securely.`,
  },
  {
    title: 'Step 5: Share on Social Media',
    text: `Use the share buttons to post about your new finds on platforms like Instagram, Facebook, Twitter, and more.`,
  },
  {
    title: 'Step 6: Earn Your Commission',
    text: `When your friends, followers, or connections see your posts and click on the links to buy the products you shared, you earn a commission on their purchases. It's that simple!`,
  },
  {
    title: 'Step 7: Track Your Earnings',
    text: `Keep an eye on your Wecobi account to monitor your commission earnings. You can see how much you've earned and track the impact of your social media posts.`,
  },
  {
    title: 'Step 8: Redeem Your Earnings',
    text: `Once you've accumulated commission earnings, you can redeem them in accordance with Wecobi's payout policies. Buying with Wecobi is not just about getting great deals; it's also an opportunity to share your favorite products with your network and earn commissions while doing so. Join the Wecobi community today and start shopping with added benefits`,
  },
];
const TEXT_PREVIEW = `Are you ready to unlock the benefits of buying on Wecobi while also earning a commission? Here's a simple guide to get you started:`;
export const EarnCommissionDescription = () => (
  <PageWrapper>
    <GuiderHeader>How to Buy with Wecobi and Earn a Commission</GuiderHeader>
    <GuideStandaloneText>{TEXT_PREVIEW}</GuideStandaloneText>
    {DESC_DATA.map(({ title, text }, index) => (
      <GuideRow key={title} index={index + 1} title={title} data={[{ text }]} />
    ))}
  </PageWrapper>
);
