import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { observer } from 'mobx-react-lite';

import { useWindowSize } from 'usehooks-ts';
import { WINDOW } from '../../../../../config/constants';
import { isDesktop } from '../../../../../utils/ifDesktop.util';
import { Item } from './components/Item/Item';
import { ProductOption } from '../../../../../types/company.types';

interface ProductOptionsPopupPickerProps extends DialogProps {
  productOptions: ProductOption[];
  setSelectedProductOption: (value: ProductOption) => void;
  selectedProductOption: ProductOption | undefined;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    zIndex: 12000,
    maxWidth: ({ isDesktop }: any) =>
      isDesktop ? WINDOW.MAX_WIDTH_DESKTOP : WINDOW.MAX_WIDTH,
  },
});

export const ProductOptionsPopupPicker = observer(
  ({
    productOptions,
    setSelectedProductOption,
    selectedProductOption,
    ...props
  }: ProductOptionsPopupPickerProps) => {
    const { width } = useWindowSize();

    const classes = useStyles({ isDesktop: isDesktop(width) });
    const popupRef = useRef();

    const handleSelectOption = (option: ProductOption) => {
      setSelectedProductOption(option);
    };

    return (
      <>
        <div>
          <Dialog {...props} ref={popupRef} classes={{ paper: classes.dialog }}>
            {productOptions.map((productOption: any) => (
              <Item
                key={productOption.option}
                name={productOption.option}
                isPicked={
                  productOption.option === selectedProductOption?.option
                }
                onClick={() => handleSelectOption(productOption)}
              />
            ))}
          </Dialog>
        </div>
      </>
    );
  },
);
