import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '23'}
      height={props.height || '20'}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width="3.36336"
        height="10.5528"
        transform="matrix(0.71751 -0.696549 0.732396 0.680879 2.85718 8.40488)"
        fill={props.color || 'white'}
      />
      <rect
        width="3.58726"
        height="14.442"
        transform="matrix(0.724999 0.688749 -0.724999 0.688749 19.4939 2.10147)"
        fill={props.color || 'white'}
      />
    </svg>
  );
};

export const AcceptedIcon = React.memo(SVGComponent);
