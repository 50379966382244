import React from 'react';

import { ButtonWithIconProps } from './Button.interfaces';
import { ButtonWrapper, ButtonWithIconWrapper } from './Button.styles';

export const Button = ButtonWrapper;

export const ButtonWithIcon = ({
  onClick,
  style,
  children,
  renderIcon,
}: ButtonWithIconProps) => {
  return (
    <ButtonWithIconWrapper style={style} onClick={onClick}>
      <div className="icon">{renderIcon()}</div>
      <div className="text">{children}</div>
    </ButtonWithIconWrapper>
  );
};
