import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import {
  DrawerWrapper,
  ItemsWrapper,
  MainItems,
  SecondaryItems,
} from './DrawerContent.styles';
import { AvatarContainer } from './components/AvatarContainer/AvatarContainer';
import { DrawerItem } from './components/DrawerItem/DrawerItem';
import { UserFooterBlock } from './components/FooterBlock';
import { ContentProps, userContent } from './DrawerContent.config';
import { useAuthContext } from '../../../contexts/authContext';
import { UserStore } from '../../../store/user.store';
import { CompanyPopupPicker } from '../../CompanyPopupPicker/CompanyPopupPicker';
import { getImage } from '../../../utils/profileImage.util';
import { User } from '../../../types/user.types';
import { useUnreadMessages } from '../../../hooks/query';
import { useUnseenNotifications } from '../../../hooks/query/useUnseenNotifications';

interface UserDrawerContentProps {
  user: User;
}

export const UserDrawerContent = ({ user }: UserDrawerContentProps) => {
  const history = useHistory();
  const { secondaryItems } = userContent;
  const { logout } = useAuthContext();
  const { userData } = useContext(UserStore);
  const [isCompanyPickerOpen, setIsCompanyPickerOpen] = useState(false);
  const { data: unreadMessages = {} } = useUnreadMessages();
  const { data: unseenNotifications = 0 } = useUnseenNotifications(user.id);

  const onLogout = async () => {
    await logout();
    history.push('/login');
  };

  const onCreateCompany = () => history.push('/signup-company');
  const closePicker = () => setIsCompanyPickerOpen(false);

  const onImage = () => history.push('/account');

  const mainItems: ContentProps['mainItems'] = [
    {
      iconName: 'like',
      title: 'Wish list',
      navigatePath: '/account/wishlist',
      accessoryText: userData?.liked?.length || '',
    },
    {
      iconName: 'shop',
      title: 'Co-orders',
      navigatePath: '/account/orders',
      accessoryText: userData?.orders?.length || '',
    },
    {
      iconName: 'account-group',
      title: 'Community',
      navigatePath: '/community',
      accessoryText: userData?.following?.length || '',
    },
    {
      iconName: 'chat',
      title: 'Messages',
      navigatePath: '/account/messages',
      accessoryText: Object.keys(unreadMessages).length || '',
    },
    {
      iconName: 'commission',
      title: 'Commissions',
      navigatePath: '/account/commissions',
    },
    {
      iconName: 'bell',
      title: 'Notifications',
      navigatePath: '/account/notifications',
      accessoryText: unseenNotifications ? unseenNotifications.toString() : '',
    },
  ];

  return (
    <DrawerWrapper>
      <CompanyPopupPicker
        open={isCompanyPickerOpen}
        onClose={closePicker}
        forceClose={closePicker}
        user={user}
      />
      <AvatarContainer
        image={getImage(user.profileImage)}
        name={user.name}
        nickname={user.username}
        onArrow={() => setIsCompanyPickerOpen(true)}
        onImage={onImage}
      />
      <ItemsWrapper>
        <MainItems>
          {mainItems.map((item) => (
            <DrawerItem key={item.title} {...item} borderBottom />
          ))}
        </MainItems>
        {secondaryItems && (
          <SecondaryItems>
            {secondaryItems.map((item) => (
              <DrawerItem
                key={item.title}
                {...item}
                type="secondary"
                borderTop
              />
            ))}
          </SecondaryItems>
        )}
      </ItemsWrapper>
      <UserFooterBlock onCreateCompany={onCreateCompany} onLogout={onLogout} />
    </DrawerWrapper>
  );
};
