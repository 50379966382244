import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 267px;
  height: 44px;
  z-index: 1;
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  border-radius: 59px;
  outline: none;
  padding-left: 15px;
  padding-right: 45px;
  font-family: 'Poppins';
  font-size: 16px;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 44px;
  background: #333333;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::placeholder {
    font-family: 'Poppins';
  }
`;
