import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CampaignV2 } from '../../types/company.types';

interface CampaignParams {
  id: string;
}

interface CampaignConfig {
  enabled: boolean;
}

export const useCampaign = (
  { id }: CampaignParams,
  config?: CampaignConfig,
) => {
  return useQuery(
    [QUERY_KEYS.GET_CAMPAIGN, id],
    async (): Promise<CampaignV2> => {
      return axios.get(`campaigns/${id}`);
    },
    {
      ...config,
    },
  );
};
