import styled from 'styled-components';

interface ImageProps {
  zIndex: number;
  left: number;
  width: number;
}

interface ParticipantsListWrapperProps {
  width: number;
}

export const ParticipantsListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${(p: ParticipantsListWrapperProps) => p.width};
`;

export const Image = styled.img`
  position: absolute;
  z-index: ${(p: ImageProps) => p.zIndex};
  left: ${(p: ImageProps) => p.left}px;
  width: ${(p: ImageProps) => p.width}px;
`;
