import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '19'}
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9.05C5.43168 9.05 4.88663 8.81295 4.48477 8.39099C4.08291 7.96903 3.85714 7.39674 3.85714 6.8C3.85714 6.20326 4.08291 5.63097 4.48477 5.20901C4.88663 4.78705 5.43168 4.55 6 4.55C6.56832 4.55 7.11337 4.78705 7.51523 5.20901C7.91709 5.63097 8.14286 6.20326 8.14286 6.8C8.14286 7.09547 8.08743 7.38806 7.97974 7.66104C7.87205 7.93402 7.71421 8.18206 7.51523 8.39099C7.31625 8.59992 7.08002 8.76566 6.82004 8.87873C6.56005 8.9918 6.2814 9.05 6 9.05ZM6 0.5C4.4087 0.5 2.88258 1.16375 1.75736 2.34523C0.632141 3.52671 0 5.12914 0 6.8C0 11.525 6 18.5 6 18.5C6 18.5 12 11.525 12 6.8C12 5.12914 11.3679 3.52671 10.2426 2.34523C9.11742 1.16375 7.5913 0.5 6 0.5Z"
        fill={props.color || '#777777'}
      />
    </svg>
  );
};

export const LocationIcon = React.memo(SVGComponent);
