import styled from 'styled-components';

import { COLORS } from '../../../../../config/constants';

import { Text } from '../../../../Text';

interface WrapperProps {
  borderTop?: boolean;
  borderBottom?: boolean;
}

interface ItemTextProps {
  color: string;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(p: WrapperProps) =>
    p.borderBottom ? '1px solid #eeeeee' : 0};
  border-top: ${(p: WrapperProps) => (p.borderTop ? '1px solid #eeeeee' : 0)};
  cursor: pointer;
`;

export const ItemText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 157.2%;
  color: ${(p: ItemTextProps) => p.color};
  margin-left: 14px;
`;

export const AccessoryText = styled(Text)`
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  font-weight: normal;
`;

export const LeftPartWrapper = styled.div`
  display: flex;
`;
