import React from 'react';
import { OrderActionButton } from '../styles';

import { OrderRow } from '../../components/OrderRow';
import {
  ButtonsContainer,
  FooterContainer,
  LeftButton,
  RightButton,
} from './OrderExpandedActive.styles';
import { CampaignExpandedActiveProps } from '../types';
import { useHistory } from 'react-router-dom';
import { useCancelOrder } from '../../../../hooks/query/useCancelOrder';
import { RWebShare } from 'react-web-share';
import { useAuthContext } from '../../../../contexts/authContext';
import { useShareCampaign } from '../../../../hooks/query';

export const OrderExpandedActive = ({
  orderMoreThanOne,
  startDate,
  id,
  title,
  description,
  orderId,
}: any) => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const { mutate: cancelOrder } = useCancelOrder();
  const { mutate: shareCampaign } = useShareCampaign();

  const shareData = {
    title: title,
    text: description,
    url: `${window.location.origin}/campaign/${id}?referral=${loggedInAccount?.id}`,
  };

  const handleShare = () => {
    if (loggedInAccount) {
      shareCampaign(id);
    }
  };

  const orderMore = () => {
    history.push(`/campaign/${id}`);
  };

  const onCancel = async () => {
    cancelOrder(orderId);
  };

  return (
    <>
      <OrderRow title="Campaign started:" date={startDate as Date} />
      <ButtonsContainer>
        {orderMoreThanOne && (
          <LeftButton onClick={orderMore}>Buy more pieces</LeftButton>
        )}
        <RWebShare data={shareData} onClick={handleShare}>
          <RightButton>Invite friends to co-buy</RightButton>
        </RWebShare>
      </ButtonsContainer>
      <FooterContainer>
        <OrderActionButton style={{ flex: 1 }} onClick={onCancel}>
          Cancel
        </OrderActionButton>
      </FooterContainer>
    </>
  );
};

export const CampaignExpandedActive = ({
  startDate,
  onEdit,
  onCancel,
}: CampaignExpandedActiveProps) => (
  <>
    <OrderRow title="Campaign started:" date={startDate as Date} />
    <FooterContainer>
      <OrderActionButton style={{ flex: 3, marginRight: 8 }} onClick={onCancel}>
        Cancel the campaign
      </OrderActionButton>
      <OrderActionButton style={{ flex: 1 }} onClick={onEdit}>
        Edit
      </OrderActionButton>
    </FooterContainer>
  </>
);
