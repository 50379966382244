import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={26}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 20.427l8.189 4.85-2.174-9.14 7.235-6.15-9.527-.805L14 .575l-3.723 8.607-9.527.806 7.221 6.149-2.16 9.14L14 20.426z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const StarIcon = React.memo(SvgComponent);
