import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';

import { AboutCampaign } from './AboutCampaign/AboutCampaign';
// import { OrderCollapsed } from '../../components/Order/OrderCollapsed/OrderCollapsed'
import { DescriptionWrapper } from './MyCommissions.style';
import { Text } from '../../components/Text';
import { COLORS } from '../../config/constants';
import { Switcher } from './Switcher/Switcher';
import { AccountSlider } from '../../components/AccountSlider/AccountSlider';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../contexts/authContext';
import { useCreateStripeAccount } from '../../hooks/query/useCreateStripeAccount';
import { useOnboardStripeAccount } from '../../hooks/query/useCreateOnboardStripeAccount';
import { useUserCommissionCampaigns } from '../../hooks/query/useUserCommissionCampaigns';
import { useUserCommissionProducts } from '../../hooks/query/useUserCommissionProducts';
import { useUserTotalCommissions } from '../../hooks/query/useUserTotalCommissions';
import { User } from '../../types/user.types';
import { useProfile } from '../../hooks/query';

export const MyCommissions = () => {
  const history = useHistory();
  const [active, setActive] = useState<number>(0);
  const [isSwitcherHidden, toggleSwitcherHidden] = useState<boolean>(true);
  const { loggedInAccount } = useAuthContext();

  const { data: user } = useProfile({
    enabled: !!loggedInAccount,
  });

  const { data: commissionCampaigns } = useUserCommissionCampaigns();
  const { data: commissionProducts } = useUserCommissionProducts();
  const { data: totalCommissions } = useUserTotalCommissions();

  const { mutate: createStripeAccount } = useCreateStripeAccount();
  const { mutate: onboardStripeAccount } = useOnboardStripeAccount();

  const toggleSwitcher = () => toggleSwitcherHidden(!isSwitcherHidden);

  const titles = ['Finished campaigns', 'Campaigns in progress'];

  const onConnectStripe = async () => {
    await createStripeAccount(
      { email: user?.email as string },
      {
        onSuccess: (data) => {
          onboardStripeAccount({
            accountId: data.id,
            url: window.location.href,
          });
        },
      },
    );
  };

  const onClick = () => history.push('/earn-commission-description');
  return (
    <PageWrapper>
      <AccountSlider
        icon="commission"
        title="My commissions"
        onLeftArrow={() => history.push('/account/orders')}
        onRightArrow={() => history.push('/account/messages')}
      />
      <AboutCampaign
        toggleSwitcherHidden={toggleSwitcher}
        type={active === 0 ? 'finished' : 'progress'}
        campaignInfo={{
          sharingOrdered: commissionCampaigns || 0,
          totallyOrdered: commissionProducts || 0,
          earned: `${totalCommissions?.toFixed(2) || 0}$`,
        }}
        onConnectStripe={onConnectStripe}
        user={user as User}
      />
      {/* {orders.length ? (
        orders.map((order, index) => <OrderCollapsed {...order} key={index} />)
      ) : (
        <DescriptionWrapper>
          <Text size={19} color={COLORS.COLOR_WHITE} align="center" lineHeight="119.2%" weight={700}>
            How to earn commissions?
          </Text>
          <Text size={14} color={COLORS.COLOR_WHITE} align="center" lineHeight="157.2%" weight={300}>
            Read a short description
          </Text>
        </DescriptionWrapper>
      )} */}
      <DescriptionWrapper onClick={onClick}>
        <Text
          size={19}
          color={COLORS.COLOR_WHITE}
          align="center"
          lineHeight="119.2%"
          weight={700}
        >
          How to earn commissions?
        </Text>
        <Text
          size={14}
          color={COLORS.COLOR_WHITE}
          align="center"
          lineHeight="157.2%"
          weight={300}
        >
          Read a short description
        </Text>
      </DescriptionWrapper>
      <Modal open={!isSwitcherHidden} onClose={toggleSwitcher}>
        <Switcher
          isHidden={isSwitcherHidden}
          titles={titles}
          active={active}
          setActive={setActive}
          name="type"
        />
      </Modal>
    </PageWrapper>
  );
};
