import React, { useState } from 'react';
import styles from './select.module.scss';
import ReactSelect from 'react-select';

interface Option {
  value: number;
  label: string;
}

interface Props {
  options: Array<Option>;
  isLoading: boolean;
  fullWidth?: boolean;
  [x: string]: any;
}

export const Select = ({
  options,
  isLoading,
  handleSelect,
  ...rest
}: Props) => {
  const [selected] = useState(null);
  const onChange = (value: any) => {
    handleSelect(value);
  };
  return (
    <ReactSelect
      isLoading={isLoading}
      components={{
        DropdownIndicator: null,
      }}
      classNames={{
        container: () => styles.container,
        control: () => styles.control,
        valueContainer: () => styles.valueContainer,
        input: () => styles.input,
        option: () => styles.option,
        singleValue: () => styles.singleValue,
        noOptionsMessage: () => styles.noOption,
        placeholder: () => styles.placeholder,
        ...rest?.customClassNames,
      }}
      value={selected}
      onChange={onChange}
      options={options}
      {...rest}
    />
  );
};

export default Select;
