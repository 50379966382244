import React, { Fragment } from 'react';
import Truncate from 'react-truncate';

import './MainInfo.scss';
import { ProgressBar } from '../../../../components/reusable';
import { PROGRESS_BAR_HEIGHTS } from '../../../../components/reusable';
import { Campaign, UploadedImage } from '../../../../types/company.types';
import { getImage } from '../../../../utils/profileImage.util';
import { OrderStatus } from '../../../../components/Order/OrderButton/OrderButton.interface';
import OrderButton from '../OrderButton/OrderButton';

export interface CampaignDetailsMainProps
  extends Pick<
    Campaign,
    'title' | 'description' | 'price' | 'retailPrice' | 'goal' | 'commission'
  > {
  logo?: string;
  daysLeft?: number | string | null;
  ordered: number;
  onBuy: () => void;
  isCoBuyButtonDisabled: boolean;
  shareData?: ShareData | null;
  avatars: UploadedImage[];
  onAvatarsClick: () => void;
  onLogoClick: () => void;
  handleShare: () => void;
  status: OrderStatus;
  isBought: boolean;
  moreThanOne: boolean;
}

const AVATAR_WIDTH = 26;

export const MainInfo = ({
  title,
  description,
  logo,
  price,
  retailPrice,
  ordered,
  goal,
  daysLeft,
  commission,
  onBuy,
  isCoBuyButtonDisabled,
  shareData,
  avatars,
  onAvatarsClick,
  onLogoClick,
  moreThanOne,
  isBought,
  status,
  handleShare,
}: CampaignDetailsMainProps) => {
  return (
    <Fragment>
      <div className="title">
        <div className="company-text">
          <div className="title" title={title}>
            <Truncate width={350} ellipsis="...">
              {title}
            </Truncate>
          </div>
          <div className="subtitle" title={description}>
            <Truncate width={350} ellipsis="...">
              {description}
            </Truncate>
          </div>
        </div>
        <div className="company-logo" onClick={onLogoClick}>
          <img src={logo} alt="company-logo" />
        </div>
      </div>
      <div className="price">
        <div className="company-price">${price}</div>
        <div className="company-retail-price">
          retail price:&nbsp;<span>${retailPrice}</span>
        </div>
      </div>
      <div className="progress">
        <ProgressBar
          pcs={ordered}
          pcsMax={goal}
          height={PROGRESS_BAR_HEIGHTS.LARGE}
        />
        <div className="contributors__wrapper">
          <div className="company-contributors">
            <div className="pcs">
              {ordered}
              <span>/{goal}</span>
            </div>
            <div className="avatars" onClick={onAvatarsClick}>
              {avatars.map((avatar, index) => (
                <div
                  key={index}
                  className="centered position-absolute"
                  style={{
                    left: `${(index * AVATAR_WIDTH) / 2}px`,
                    zIndex: index,
                  }}
                >
                  <img src={getImage(avatar)} alt="avatar" />
                </div>
              ))}
            </div>
          </div>
          {daysLeft && (
            <div className="company-days-left">{`${daysLeft} days left`}</div>
          )}
        </div>
      </div>
      <OrderButton
        status={status}
        moreThanOne={moreThanOne}
        isBought={isBought}
        onClick={onBuy}
        isCoBuyButtonDisabled={isCoBuyButtonDisabled}
        shareData={shareData}
        handleShare={handleShare}
      />
      <div className="company-commission">
        <div className="text">
          Advice your friends and get a commission for sharing
        </div>
        <div className="commission">
          commission <span>{commission}%</span>
        </div>
      </div>
    </Fragment>
  );
};
