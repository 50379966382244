import React, { useMemo, useState } from 'react';
import {
  Wrapper,
  Location,
  Country,
  Display,
  Filter,
} from './DisplaySettings.style';
import { Icon } from '../Icon/Icon';
import { COLORS } from '../../config/constants';
import {
  COLOR_ACTIVE_DISPLAY,
  COLOR_INACTIVE_DISPLAY,
  DISPLAY_ICON_NAMES,
  DISPLAYS,
} from './DisplaySettings.config';
import { computeActiveDisplay } from './DisplaySettings.service';
import { DisplayIconType, DisplayType } from './DisplaySettings.types';
import FilterModal from '../../pages/Search/components/Filter/FilterModal';
import CountryModal from '../../pages/Search/components/Country/CountryModal';
interface DisplaySettingsProps {
  country: string;
  display: DisplayType;
  setDisplay: (displayType: DisplayType) => void;
  displayIcons?: DisplayIconType[];
  disableCountry?: boolean;
  showFilter?: boolean;
}

export const DisplaySettings = ({
  country,
  display,
  setDisplay,
  disableCountry,
  displayIcons = DISPLAY_ICON_NAMES,
  showFilter = false,
}: DisplaySettingsProps) => {
  const isActive = useMemo(() => computeActiveDisplay(display), [display]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);

  return (
    <Wrapper disableCountry={disableCountry}>
      {!disableCountry && (
        <Location>
          <Icon name="location" color={COLORS.COLOR_DARK_GRAY} />
          <Country onClick={() => setIsCountryModalOpen(true)} title={country}>
            {country}
          </Country>
        </Location>
      )}
      {showFilter && (
        <Filter>
          <Icon name="sort" pointer onClick={() => setIsModalOpen(true)} />
        </Filter>
      )}
      <Display>
        {displayIcons.map((disp, index) => {
          return (
            <Icon
              key={disp}
              name={disp}
              color={
                isActive[index] ? COLOR_ACTIVE_DISPLAY : COLOR_INACTIVE_DISPLAY
              }
              onClick={() => setDisplay(DISPLAYS[index])}
              pointer
            />
          );
        })}
      </Display>
      <FilterModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <CountryModal
        isOpen={isCountryModalOpen}
        setIsOpen={setIsCountryModalOpen}
      />
    </Wrapper>
  );
};
