export const COLORS = {
  COLOR_DARK_GRAY: '#434343',
  COLOR_MIDDLE_GRAY: '#6B717A',
  COLOR_BLACK: '#000',
  COLOR_WHITE: '#fff',
  COLOR_ORANGE_1: '#ff6d0a',
  COLOR_ORANGE_2: '#e4560a',
  COLOR_ORANGE_GRADIENT_1: 'linear-gradient(270deg, #FCBA69 0%, #F98140 100%);',
  COLOR_GRAY_1: '#333333',
  COLOR_LIGHT_GRAY_1: '#e0e0e0',
  COLOR_LIGHT_GRAY_2: '#D4D4D4',
  COLOR_LIGHT_GRAY_3: '#d3d3d3',
  COLOR_GREEN_2: '#27AE60',
  BACKGROUND_COLOR: '#F7F7FA',
};

export const WINDOW = {
  MAX_WIDTH: 480,
  MAX_WIDTH_DESKTOP: 375 + 75,
};

export const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
  'pk_test_51JJfC9LEy2PBmozEuuqWKGFkK3gFxMPtgzEyMySxHfXb78omHbFRbbZpvEKNZQHEpjdRKLLZcyRNqjTmlm3JpGlk00BYIwSF7d';
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '407765765790-tr4q3qdbi6kvi4l0ggivl22jet5eq81r.apps.googleusercontent.com';
