import {
  addDoc,
  collection,
  setDoc,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { uniqBy } from 'lodash';

import { ICategoryNames } from '../../components/Categories/CategoryItem/CategoryItem.types';
import { db } from '../../firebase';
import { CompanyData } from '../../pages/Authentication/SignUpCompany/components/form';
import { Campaign, Company } from '../../types/company.types';
import { User } from '../../types/user.types';

export enum DBCollections {
  Users = 'users',
  Companies = 'companies',
  Campaigns = 'campaigns',
}

class FirestoreManager {
  public addCompany = async (fields: CompanyData) => {
    try {
      await addDoc(collection(db, DBCollections.Companies), fields);
      alert('Company was added!');
    } catch (error) {
      alert('Failed to create company');
    }
  };

  public addUser = (uid: string, data: Omit<User, 'uid'>) => {
    const docRef = doc(db, DBCollections.Users, uid);
    return setDoc(docRef, data);
  };

  public getUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, DBCollections.Users));
    const users = await usersSnapshot.docs.map(
      (doc) => ({ ...doc.data(), uid: doc.id } as User),
    );
    return users;
  };

  public getUserByUsername = async (username: string) => {
    const q = query(
      collection(db, DBCollections.Users),
      where('username', '==', username),
    );
    const userSnapshot = await getDocs(q);
    const userRef = await userSnapshot.docs.map((doc) => doc.ref)[0];
    let userData = undefined;
    if (userRef) {
      userData = (await (await getDoc(userRef)).data()) as User | undefined;
    }
    return { userData, userRef };
  };

  public getUserById = async (id: string) => {
    const userRef = doc(db, DBCollections.Users, id);
    const userDoc = await getDoc(userRef);
    const userData = (await userDoc.data()) as User;
    return { userData, userRef };
  };

  public updateUser = async (uid: string, data: Omit<Partial<User>, 'uid'>) => {
    const { userRef } = await this.getUserById(uid);
    await updateDoc(userRef, data);
  };

  public getCompanyById = async (id: string) => {
    const companyRef = doc(db, DBCollections.Companies, id);
    const companyDoc = await getDoc(companyRef);
    const companyData = (await companyDoc.data()) as Company;
    return { companyData, companyRef };
  };

  public getCompaniesByUserId = async (uid: string) => {
    const q = query(
      collection(db, DBCollections.Companies),
      where('userId', '==', uid),
    );
    const companiesSnapshot = await getDocs(q);
    const companiesRef = companiesSnapshot.docs.map((doc) => doc.ref);
    const companies = await Promise.all(
      companiesRef.map(
        async (ref) =>
          await {
            id: ref.id,
            ...(await getDoc(ref)).data(),
          },
      ),
    );
    return companies as Company[];
  };

  public addCampaign = async (data: Omit<Campaign, 'images' | 'id'>) => {
    const campaignResult = await addDoc(
      collection(db, DBCollections.Campaigns),
      data,
    );
    return campaignResult.id;
  };

  public getCampaignsByCompanyId = async (companyId: string) => {
    const q = query(
      collection(db, DBCollections.Campaigns),
      where('companyId', '==', companyId),
    );
    const campaignsSnapshot = await getDocs(q);
    return await campaignsSnapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as Campaign),
    );
  };

  public getCampaignById = async (campaignId: string) => {
    const campaignRef = doc(
      collection(db, DBCollections.Campaigns),
      campaignId,
    );
    const campaignDoc = await getDoc(campaignRef);
    const campaignData = (await campaignDoc.data()) as Campaign;
    campaignData.id = campaignRef.id;
    return { campaignRef, campaignData };
  };

  public updateCampaign = async (
    id: string,
    data: Omit<Partial<Campaign>, 'id'>,
  ) => {
    const { campaignRef } = await this.getCampaignById(id);
    await updateDoc(campaignRef, data);
  };

  public updateCompany = async (
    id: string,
    data: Omit<Partial<Company>, 'id'>,
  ) => {
    const { companyRef } = await this.getCompanyById(id);
    await updateDoc(companyRef, data);
  };

  public fetchCampaigns = async () => {
    const campaignsSnapshot = await getDocs(
      collection(db, DBCollections.Campaigns),
    );
    return await campaignsSnapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as Campaign),
    );
  };

  public fetchCampaignsWithFilter = async (
    value?: string,
    categories?: ICategoryNames[],
  ) => {
    let returnValue: Campaign[] = [];
    if (value) {
      const filteredByNameRef = await getDocs(
        query(
          collection(db, DBCollections.Campaigns),
          where('companyName', '>=', value),
          where('companyName', '<=', `${value}z`),
        ),
      );
      const filteredByTitleRef = await getDocs(
        query(
          collection(db, DBCollections.Campaigns),
          where('title', '>=', value),
          where('title', '<=', `${value}z`),
        ),
      );
      const filteredByNameData = (await filteredByNameRef.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))) as Campaign[];
      const filteredByTitleData = (await filteredByTitleRef.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))) as Campaign[];
      const filteredData = uniqBy(
        filteredByNameData.concat(filteredByTitleData),
        'id',
      );
      returnValue = [...returnValue, ...filteredData];
    }
    if (categories && categories?.length > 0) {
      const filteredByCategoriesRef = await getDocs(
        query(
          collection(db, DBCollections.Campaigns),
          where('categories', 'array-contains-any', [...categories]),
        ),
      );
      const filteredByCategoriesData = (await filteredByCategoriesRef.docs.map(
        (doc) => ({
          ...doc.data(),
          id: doc.id,
        }),
      )) as Campaign[];
      returnValue = [
        ...uniqBy([...returnValue, ...filteredByCategoriesData], 'id'),
      ];
    }
    return returnValue;
  };

  public addCampaignShared = async (
    campaignId: string,
    userFrom: string,
    userTo: string,
  ) => {
    const { campaignRef, campaignData } = await this.getCampaignById(
      campaignId,
    );
    const { userRef, userData } = await this.getUserById(userFrom);
    if (campaignRef.id && userRef.id) {
      const ifCampaignDataNotExist =
        campaignData.shared.findIndex(
          (campaign) =>
            campaign.userTo === userTo && campaign.userFrom === userFrom,
        ) === -1;
      const ifUserDataNotExist =
        userData.shared.findIndex(
          (user) => user.userTo === userTo && user.campaignId === campaignId,
        ) === -1;
      if (ifCampaignDataNotExist) {
        await updateDoc(campaignRef, {
          shared: arrayUnion({ userFrom, userTo }),
        });
      }
      if (ifUserDataNotExist) {
        await updateDoc(userRef, {
          shared: arrayUnion({ campaignId, userTo }),
        });
      }
    }
  };

  public orderItem = async (
    userId: string,
    campaignId: string,
    paymentIntent: string,
  ) => {
    const { userRef } = await this.getUserById(userId);
    const { campaignRef } = await this.getCampaignById(campaignId);

    if (userRef.id && campaignRef.id) {
      await updateDoc(userRef, {
        orders: arrayUnion({ campaignId, paymentIntent }),
      });
      await updateDoc(campaignRef, { buyers: arrayUnion(userId) });
    }
  };
}

export const FirestoreService = new FirestoreManager();
