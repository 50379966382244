import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';

interface BillAllOrdersArgs {
  campaignId: number;
  validIntents: number;
}

export const useBillAllOrders = () => {
  return useMutation(
    async ({ campaignId, validIntents }: BillAllOrdersArgs): Promise<any> =>
      axios.post('orders/bill-all-orders', { campaignId, validIntents }),
  );
};
