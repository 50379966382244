import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CampaignV2 } from '../../types/company.types';

export const useSetSeen = () => {
  return useMutation(
    async (id: number): Promise<CampaignV2> =>
      axios.patch(`notifications/set-seen/${id}`),
  );
};
