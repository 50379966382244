import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { Message } from '../../types/messages.types';

interface Params {
  username: string;
}

export const useMessagesWithUser = (
  { username }: Params,
  config: Config = {},
) => {
  return useQuery(
    [QUERY_KEYS.GET_MESSAGES_WITH_USER],
    async (): Promise<Message[]> => {
      return axios.get(`users/messages-with-user/${username}`);
    },
    config,
  );
};
