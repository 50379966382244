import styled from 'styled-components';

import { COLORS } from '../../../../../config/constants';

interface ActionsWrapperProps {
  height: number;
}

const buttonDefaultStyle = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '157.2%',
  color: COLORS.COLOR_BLACK,
  borderRadius: '4px',
  marginRight: '4px',
};

export const BUTTON_GRAY_STYLE = {
  width: '25%',
  backgroundColor: COLORS.COLOR_LIGHT_GRAY_1,
  ...buttonDefaultStyle,
};

export const BUTTON_GRADIENT_STYLE = {
  width: '50%',
  background: 'linear-gradient(270deg, #EB4DBF 0%, #F98140 100%)',
  ...buttonDefaultStyle,
};

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
  width: 100%;
  padding: 0 8px 0 13px;
  height: ${(p: ActionsWrapperProps) => p.height}px;
`;
