import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={27}
      height={24}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.85 23.425v-7.95h5.3v7.95h6.625v-10.6h3.975L13.5.9.25 12.825h3.975v10.6h6.625z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const HomeIcon = React.memo(SvgComponent);
