import {
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
  getDocs,
  query,
  collection,
  where,
  documentId,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { ActivityProps } from '../../pages/Account/shared/AccountInfo/Activity/Activity.interface';
import { SharedId } from '../../pages/CampaignDetails/campaignDetail.helpers';
import { User, UserOld } from '../../types/user.types';
import { DBCollections, FirestoreService } from '../core/firestore.service';
import { LocalStorageService } from '../core/localStorage.service';
import { StorageService } from '../core/storage.service';

class UserManager {
  public getUsername = (username: string) => `@${username}`;

  public getPureUsername = (username: string) => username.split('@')[1];

  public getAvatar = async (uid: string) =>
    StorageService.getData(`avatar/${uid}`) as Promise<string>;

  private getUsernames = async () => {
    const users = await FirestoreService.getUsers();
    return users.map(({ username }) => username);
  };

  public updateName = async (name: string, uid: string) => {
    await FirestoreService.updateUser(uid, { name });
    return name;
  };

  public updateUsername = async (username: string, uid: string) => {
    const usernames = await this.getUsernames();
    if (!usernames.includes(username)) {
      await FirestoreService.updateUser(uid, { username });
      return username;
    }
  };

  public updateAvatar = async (value: File, uid: string) => {
    await StorageService.addAvatar(uid, value);
    const avatar = await this.getAvatar(uid);
    await FirestoreService.updateUser(uid, { avatar });
    return avatar;
  };

  public removeAvatar = async (uid: string) => {
    await StorageService.removeAvatar(uid);
    await FirestoreService.updateUser(uid, { avatar: '' });
  };

  public addDeliveryLocations = async (
    deliveryLocations: string[],
    // uid: string,
  ) => {
    // await FirestoreService.updateUser(uid, { deliveryLocations });
    return deliveryLocations;
  };

  public followUser = async (uid: string, followingId: string) => {
    const user = await FirestoreService.getUserById(uid);
    const followingUser = await FirestoreService.getUserById(followingId);
    if (
      user.userData &&
      user.userRef &&
      followingUser.userData &&
      followingUser.userRef
    ) {
      await updateDoc(user.userRef, {
        following: arrayUnion(followingId),
      });
      await updateDoc(followingUser.userRef, {
        followers: arrayUnion(uid),
      });
    }
  };

  public unfollowUser = async (uid: string, followingId: string) => {
    const user = await FirestoreService.getUserById(uid);
    const followingUser = await FirestoreService.getUserById(followingId);
    if (
      user.userData &&
      user.userRef &&
      followingUser.userData &&
      followingUser.userRef
    ) {
      await updateDoc(user.userRef, {
        following: arrayRemove(followingId),
      });
      await updateDoc(followingUser.userRef, {
        followers: arrayRemove(uid),
      });
    }
  };

  public setCurrentCompany = async (uid: string, companyId: string | null) =>
    updateDoc(doc(db, DBCollections.Users, uid), { currentCompany: companyId });

  public getLikedCampaigns = async (uid: string) => {
    const { userData } = await FirestoreService.getUserById(uid);
    const campaigns = await Promise.all(
      userData.liked.map(async (id) => {
        const { campaignData } = await FirestoreService.getCampaignById(id);
        return campaignData;
      }),
    );
    return campaigns;
  };

  public getUserActivity = (userData: User): ActivityProps => ({
    username: userData.username,
    followers: userData?.followers?.length || 0,
    following:
      (userData.following?.length || 0) + userData.followingCompanies.length ||
      0,
    shares: 0,
  });

  public getFollowings = async (userIds: string[]) => {
    const followersSnapshot = await getDocs(
      query(
        collection(db, DBCollections.Users),
        where(documentId(), 'in', userIds),
      ),
    );
    return (await (
      await followersSnapshot
    ).docs.map((doc) => ({ ...doc.data(), uid: doc.id }))) as UserOld[];
  };

  public getFollowingCompanies = async (companyIds: string[]) => {
    const companies = await Promise.all(
      companyIds.map(async (companyId) =>
        FirestoreService.getCompanyById(companyId),
      ),
    );
    return companies.map(({ companyData, companyRef }) => ({
      ...companyData,
      id: companyRef.id,
    }));
  };

  public saveSharedIds = async (userToId: string) => {
    const sharedIds = LocalStorageService.getItem<SharedId[]>('shareIds');
    if (sharedIds && sharedIds.length > 0) {
      Promise.all(
        sharedIds.map(
          async ({ campaignId, userId }) =>
            await FirestoreService.addCampaignShared(
              campaignId,
              userId,
              userToId,
            ),
        ),
      );
      LocalStorageService.removeItem('shareIds');
    }
  };

  // public getOrders = async (uid: string) => {
  //   const { userData } = await FirestoreService.getUserById(uid);
  //   if (userData.orders?.length > 0) {
  //     return Promise.all(
  //       userData.orders.map(async ({ campaignId }) => {
  //         const { campaignData } = await FirestoreService.getCampaignById(
  //           campaignId,
  //         );
  //         return campaignData;
  //       }),
  //     );
  //   }
  // };

  // public cancelOrder = async (userId: string, campaignId: string) => {
  //   const { userData } = await FirestoreService.getUserById(userId);
  //   const { campaignData } = await FirestoreService.getCampaignById(campaignId);
  //   if (campaignData.buyers.includes(userData.uid)) {
  //     await FirestoreService.updateCampaign(campaignId, {
  //       buyers: campaignData.buyers.filter((value) => value !== userId),
  //     });
  //   }
  //   if (userData.orders.find((order) => order.campaignId === campaignId)) {
  //     await FirestoreService.updateUser(userId, {
  //       orders: userData.orders.filter(
  //         (value) => value.campaignId !== campaignId,
  //       ),
  //     });
  //   }
  // };
}

export const UserService = new UserManager();
