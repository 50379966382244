export const QUERY_KEYS = {
  CREATE_USER: 'create-user',
  GET_USERS: 'get-users',
  GET_USER: 'get-user',
  PROFILE: 'profile',
  GET_CATEGORIES: 'get-categories',
  GET_COUNTRIES: 'get-countries',
  GET_FOLLOWERS: 'get-followers',
  GET_USER_FOLLOWING: 'get-user-following',
  GET_COMPANY_FOLLOWING: 'get-company-following',
  GET_ORDERS: 'get-orders',
  GET_USER_COMPANIES: 'get-user-companies',
  GET_COMPANY: 'get-company',
  GET_CAMPAIGNS: 'get-campaigns',
  GET_TRENDING_CAMPAIGNS: 'get-trending-campaigns',
  GET_SIMILAR_CAMPAIGNS: 'get-similar-campaigns',
  GET_LOCAL_CAMPAIGNS: 'get-local-campaigns',
  GET_CAMPAIGN: 'get-campaign',
  GET_WISHLIST: 'get-wishlist',
  GET_USER_WISHLIST: 'get-user-wishlist',
  GET_USER_NOTIFICATIONS: 'get-user-notifications',
  GET_USER_ORDERED_CAMPAIGNS: 'get-user-ordered-campaigns',
  GET_USER_COMMISSION_CAMPAIGNS: 'get-user-commission-campaign',
  GET_USER_COMMISSION_PRODUCTS: 'get-user-commission-products',
  GET_USER_TOTAL_COMMISSIONS: 'get-user-total-commissions',
  GET_COMPANIES: 'get-companies',
  GET_MESSAGED_USERS: 'get-messaged-users',
  GET_MESSAGES_WITH_USER: 'get-messages-with-user',
  GET_UNREAD_MESSAGES: 'get-unread-messages',
  GET_UNSEEN_NOTIFICATIONS: 'get-unseen-notifications',
};
