import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Category } from '../../types/category.types';

export const useCategories = () => {
  return useQuery(
    [QUERY_KEYS.GET_CATEGORIES],
    async (): Promise<Category[]> => {
      return axios.get('categories');
    },
  );
};
