import React, { useState } from 'react';
import styles from './dropdownInput.module.scss';
import Select from 'react-select';

interface Option {
  value: number;
  label: string;
}

interface Props {
  label: string;
  error?: any;
  options: Array<Option>;
  isLoading: boolean;
}

export const DropdownInput = ({
  label,
  error,
  options,
  isLoading,
  ...rest
}: Props) => {
  const [selected, setSelected] = useState(null);
  const onChange = (value: any) => {
    setSelected(value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label}
        <p className={styles.errorText}>{error}</p>
      </div>
      <Select
        isLoading={isLoading}
        placeholder=""
        components={{
          DropdownIndicator: null,
        }}
        classNames={{
          container: () => styles.selectContainer,
          control: () => styles.control,
          valueContainer: () => styles.valueContainer,
          input: () => styles.input,
          option: () => styles.option,
          singleValue: () => styles.singleValue,
          noOptionsMessage: () => styles.noOption,
        }}
        value={selected}
        onChange={onChange}
        options={options}
        {...rest}
      />
    </div>
  );
};

export default DropdownInput;
