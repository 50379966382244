import styled from 'styled-components';

interface BlockProps {
  width: number;
  onClick?: () => void;
}

interface ActivityProps {
  height: number;
}

export const ActivityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 70%;
  height: ${(p: ActivityProps) => p.height};
`;

//BLOCK
export const ActivityBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: ${(p: BlockProps) => p.width};
  cursor: ${(p: BlockProps) => (p.onClick ? 'pointer' : 'default')};

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%;
  }

  .title {
    height: 35%;
  }
`;
