import styled from 'styled-components';

import { Text } from '../../../../components/Text';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 42px;
`;
export const Header = styled(Text)`
  width: 178px;
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;
  text-align: center;
  margin-bottom: 10px;
`;
export const ImageWrapper = styled.div`
  width: 100%;
  height: 379px;
  display: flex;
  justify-content: center;

  & img {
    width: 375px;
    height: 100%;
  }
`;

export const DescriptionHeader = styled(Text)`
  font-size: 16px;
  line-height: 126.2%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 11px;
  width: 214px;
`;

export const Description = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 157.2%;
  text-align: center;
  width: 296px;
  overflow-wrap: break-word;
`;

export const SwiperSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 370px;
  margin-top: 52px;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 39px;
`;
