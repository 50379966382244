import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Campaign } from '../../types/company.types';

export const useRenewCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number): Promise<Campaign> =>
      axios.patch(`campaigns/renew/${id}`),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QUERY_KEYS.GET_CAMPAIGNS]),
    },
  );
};
