import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0  17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8278 0.458365C16.1694 0.829829 16.1694 1.40105 15.8278 1.77252L5.21122 13.318L0.672816 8.38251C0.3311 8.01089 0.3311 7.43943 0.672816 7.06782C1.05724 6.64976 1.7168 6.64936 2.10173 7.06695L5.21122 10.4402L14.399 0.458029C14.7836 0.0401112 15.4433 0.0402656 15.8278 0.458365Z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const CorrectTickIcon = React.memo(SvgComponent);
