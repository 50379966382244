import React from 'react';

import './InputTitle.scss';
import { InputNumber } from '../../../../components/Input/Input';
import { InputTitleStateProps } from './inputTitle.interface';

export const InputTitle = ({
  title,
  measure,
  type,
  onChange,
  value,
}: InputTitleStateProps) => {
  return (
    <div className="price__inputTitle">
      <div className="inputTitle__title">{title}</div>
      <InputNumber
        type={type}
        measure={measure}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
