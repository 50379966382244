import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User, UserFormData } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

interface UpdateUserParams {
  id: number;
}
interface UpdateUserConfig {
  enabled?: boolean;
}

export const useUpdateUser = (
  { id }: UpdateUserParams,
  config: UpdateUserConfig = {},
) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: UserFormData): Promise<User> =>
      axios.patch(`users/${id}`, data),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.PROFILE]),
      ...config,
    },
  );
};
