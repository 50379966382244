import React, { Dispatch, useMemo } from 'react';
import styles from './countryModal.module.scss';
import Modal from 'react-modal';
import { WINDOW } from '../../../../config/constants';
import { ListItem } from '../../../../components/reusable/ListItem/ListItem';
import Select from '../../../../components/Select/Select';
import { useCountries } from '../../../../hooks/query';
import { useSearchContext } from '../../../../contexts/searchContext';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<any>;
}

const CountryModal = ({ isOpen, setIsOpen }: Props) => {
  const { handleDeliveryLocationIdSelect, deliveryLocationIds } =
    useSearchContext();
  const { data: countries = [], isLoading: isCountriesLoading } =
    useCountries();
  const handleClose = () => {
    setIsOpen(false);
  };

  const { countriesOptions } = useMemo(() => {
    const countriesOptions = countries
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .filter(({ value }) => !deliveryLocationIds.includes(value));
    return { countriesOptions };
  }, [countries, deliveryLocationIds]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        content: {
          paddingTop: 45,
          paddingBottom: 45,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          maxWidth: WINDOW.MAX_WIDTH - 100,
          display: 'flex',
          minHeight: 300,
        },
        overlay: {
          zIndex: 100,
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.title}>Delivery location of campaign</div>
        <div className={styles.subtitle}>
          Some of campaigns are available for delivery in limited amount of
          countries
        </div>
        <div className={styles.inputContainer}>
          <Select
            placeholder="Add Country"
            fullWidth
            options={countriesOptions}
            isLoading={isCountriesLoading}
            menuPlacement="top"
            handleSelect={(o: any) => handleDeliveryLocationIdSelect(o.value)}
            customClassNames={{
              menuList: () => styles.menuList,
            }}
          />
          {countries
            .filter(({ id }) => deliveryLocationIds.includes(id))
            .map(({ id, name }) => (
              <ListItem
                key={id}
                onDelete={() => handleDeliveryLocationIdSelect(id)}
                mb={8}
                value={name}
              />
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default CountryModal;
