import React from 'react';

const SVGConfig = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '29'}
      height={props.height || '34'}
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1375 13.2222L28.8237 13.2222L15.3727 -6.11959e-07L1.92177 13.2222L9.8002 13.2222C9.8002 22.6667 6.72569 29.2778 0.000196936 34C9.60804 32.1111 19.2159 26.4444 21.1375 13.2222Z"
        fill={props.color || '#D5D5D5'}
      />
    </svg>
  );
};

export const ShareArrowIcon = React.memo(SVGConfig);
