import React from 'react';

import './ImageBlock.scss';
import { CrossIcon } from '../../../../../../../assets/icons';
import { ImageBlockStateProps } from './imageBlock.interface';

export const ImageBlock = ({ image, onImageRemove }: ImageBlockStateProps) => {
  return (
    <div className="productImage__imageBlock">
      <div className="close pointer" onClick={onImageRemove}>
        <CrossIcon width={10} height={10} />
      </div>
      <div className="image">
        <img className="image" src={image} alt="uploaded" />
      </div>
    </div>
  );
};
