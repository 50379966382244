import React from 'react';

import './SignUpCompany.scss';
import { LogoIcon } from '../../../assets/icons';
import { Title } from '../components';
import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { Form } from './components/form';

export const SignUpCompany = () => {
  return (
    <PageWrapper className="signup" noFooter>
      <div className="logo">
        <LogoIcon width={97} height={66} />
      </div>
      <Title text="Sell your product with" type="signup-product-title" />
      <div className="form__wrapper">
        <Form />
      </div>
    </PageWrapper>
  );
};
