import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';

type LoginData = {
  email: string;
  password: string;
};

export type LoginPayload = {
  user: User;
  accessToken: string;
};

export const useLogin = () => {
  return useMutation(
    async (data: LoginData): Promise<LoginPayload> =>
      axios.post('auth/login', data),
  );
};
