import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { LoginPayload } from './useLogin';

interface Params {
  code: string;
}

export const useGoogleLogin = () => {
  return useMutation(async ({ code }: Params): Promise<LoginPayload> => {
    return axios.post('auth/login/google', {
      code,
    });
  });
};
