import styled from 'styled-components';

export const ProductsSlider = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  top: -25px;
  height: 350px;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ProductWrapper = styled.div`
  min-width: 200px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
