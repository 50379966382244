import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

interface Data {
  userId: number;
}

export const useUnfollowUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ userId }: Data): Promise<User> =>
      axios.delete('users/follow', {
        data: { userId },
      }),
    { onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.PROFILE]) },
  );
};
