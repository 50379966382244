import React from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';

import { OrderCollapsed } from '../OrderCollapsed/OrderCollapsed';
import {
  OrderExpandedActive,
  CampaignExpandedActive,
} from './OrderExpandedActive/OrderExpandedActive';
import {
  OrderExpandedFinished,
  CampaignExpandedFinished,
} from './OrderExpandedFinished/OrderExpandedFinished';
import {
  OrderExpandedCancelled,
  CampaignExpandedCancelled,
} from './OrderExpandedCancelled/OrderExpandedCancelled';
import { CampaignV2 } from '../../../types/company.types';
import { useHistory } from 'react-router-dom';
import { useBillAllOrders } from '../../../hooks/query/useBillAllOrders';
import { useCancelCampaign } from '../../../hooks/query/useCancelCampaign';
import { useRenewCampaign } from '../../../hooks/query/useRenewCamapign';

const ExpandedWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 23px;
`;

export const OrderExpandable = ({ status, orderId, ...item }: any) => {
  const renderExpandedContent = () => {
    switch (status) {
      case 'active':
        return <OrderExpandedActive {...item} orderId={orderId} />;
      case 'successful':
        return <OrderExpandedFinished {...item} />;
      case 'unsuccessful':
        return <OrderExpandedCancelled {...item} />;
    }
  };

  return (
    <Collapsible trigger={<OrderCollapsed {...item} status={status} />}>
      <ExpandedWrapper>{renderExpandedContent()}</ExpandedWrapper>
    </Collapsible>
  );
};

export const CampaignExpandable = ({ status, ...item }: CampaignV2) => {
  const { mutate: billAllOrders } = useBillAllOrders();
  const { mutate: cancelCampaign } = useCancelCampaign();
  const { mutate: renewCampaign } = useRenewCampaign();

  const history = useHistory();

  const onEdit = () => {
    history.push(`/edit/${item.id}`);
  };

  const onCancel = async () => {
    cancelCampaign(item.id);
  };

  const onRenew = async () => {
    renewCampaign(item.id);
  };

  const onFinishCampaign = async () => {
    billAllOrders({ campaignId: item.id, validIntents: item.goal });
  };

  const renderExpandedContent = () => {
    switch (status) {
      case 'active':
        return (
          <CampaignExpandedActive
            {...item}
            onEdit={onEdit}
            onCancel={onCancel}
          />
        );
      case 'successful':
        return (
          <CampaignExpandedFinished
            {...item}
            onFinishCampaign={onFinishCampaign}
            onCancelCampaign={onCancel}
          />
        );
      case 'unsuccessful':
        return <CampaignExpandedCancelled {...item} onRenew={onRenew} />;
    }
  };

  return (
    <Collapsible trigger={<OrderCollapsed {...item} status={status} />}>
      <ExpandedWrapper>{renderExpandedContent()}</ExpandedWrapper>
    </Collapsible>
  );
};
