import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.456 2.47a2 2 0 012.286 1.664l.139.88a2 2 0 001.067 1.469l.826.421a2 2 0 01.877 2.685l-.413.816a2 2 0 00.002 1.81l.411.808a2 2 0 01-.88 2.692l-.838.423a2 2 0 00-1.073 1.473l-.138.876a2 2 0 01-2.286 1.664l-.913-.144a2 2 0 00-1.722.56l-.632.629a2 2 0 01-2.83-.008l-.641-.645a2 2 0 00-1.747-.564l-.856.142a2 2 0 01-2.302-1.659l-.143-.897a2 2 0 00-1.066-1.467l-.804-.41a2 2 0 01-.874-2.689l.412-.811a2 2 0 000-1.815l-.403-.79a2 2 0 01.865-2.686l.84-.433A2 2 0 003.68 5l.134-.844a2 2 0 012.301-1.659l.864.143a2 2 0 001.749-.566l.638-.646a2 2 0 012.836-.007l.628.628a2 2 0 001.725.562l.9-.142zM8.28 6.31a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm5 7a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-6.795 2.295c.39.39 1.02.39 1.41 0l7.18-7.18a.997.997 0 10-1.41-1.41l-7.18 7.18a.997.997 0 000 1.41z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
}

export const CommissionIcon = React.memo(SvgComponent);
