import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '21'}
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5424 2.59483C12.1301 2.02145 12.1526 1.06876 11.5927 0.466919C11.0328 -0.134919 10.1025 -0.157995 9.51477 0.415377C9.51477 0.415377 0.44563 9.40063 0.420893 9.42671C-0.158688 10.0476 -0.137011 11.0321 0.46931 11.6256L9.51468 20.5829C10.1014 21.1573 11.0318 21.1358 11.5927 20.5349C12.1536 19.934 12.1326 18.9813 11.5458 18.4069L4.04732 10.8531C3.85382 10.6582 3.85378 10.3436 4.04724 10.1487L11.5424 2.59483Z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
};

export const ArrowLeftIcon = React.memo(SVGComponent);
