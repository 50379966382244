import styled, { CSSObject } from 'styled-components';

import { COLORS } from '../../config/constants';

interface ButtonProps {
  style?: CSSObject;
}

export const ButtonWrapper = styled.button`
  border-radius: 41px;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 157.2%;
  color: ${COLORS.COLOR_WHITE};
  ${(p: ButtonProps) => p.style};
`;

export const ButtonWithIconWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 188px;
  height: 57px;
  border: 2px solid #434343;
  border-radius: 48px;
  cursor: pointer;
  background-color: #fff;
  outline: none;
  ${(p: ButtonProps) => p.style}

  .icon {
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 29px;
    margin-left: 55px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 157.2%;
    color: ${COLORS.COLOR_DARK_GRAY};
  }
`;
