import React from 'react';

import './Shipping.scss';
import { InputTextarea } from '../../../../components/Input/Input';
import { CreateCampaignFormData } from '../../../../CampaignRegistration';
import { CustomRadio } from '../../../Price/components/InputRadioBlock/CustomRadio/CustomRadio';
import { SHIPPING_OPTIONS } from '../../../../campaignRegistration.helpers';
import { InputTitle } from '../../../Price/components/InputTitle/InputTitle';

interface ShippingProps {
  formData: CreateCampaignFormData;
  updateFormData: (
    key: keyof CreateCampaignFormData,
    value: CreateCampaignFormData[keyof CreateCampaignFormData],
  ) => void;
}

export const ShippingBlock = ({ formData, updateFormData }: ShippingProps) => {
  return (
    <div className="productDetails__shipping">
      <div className="price__inputRadioBlock">
        <div className="inputRadioBlock__title">Shipping</div>
        <div className={'inputRadioBlock__radioBlockColumn'}>
          <CustomRadio
            isDefault={formData.shippingOption === SHIPPING_OPTIONS.FREE}
            name="shippingOptions"
            label="free shipping"
            onClick={() => {
              updateFormData('shippingOption', SHIPPING_OPTIONS.FREE);
            }}
          />
          <CustomRadio
            isDefault={formData.shippingOption === SHIPPING_OPTIONS.PAID}
            name="shippingOptions"
            label="paid shipping"
            onClick={() => {
              updateFormData('shippingOption', SHIPPING_OPTIONS.PAID);
            }}
          />
        </div>
      </div>

      {formData.shippingOption === SHIPPING_OPTIONS.PAID ? (
        <div className="shipping-info">
          <InputTextarea
            title=""
            value={formData.shippingInfo}
            onChange={(e) => updateFormData('shippingInfo', e.target.value)}
          />
          <InputTitle
            type="price"
            measure="$"
            title="Price of shipping"
            value={String(formData.priceOfShipping)}
            onChange={(event) =>
              updateFormData('priceOfShipping', event.target.value)
            }
          />
        </div>
      ) : null}
    </div>
  );
};
