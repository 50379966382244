import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { User } from '../../types/user.types';

export const useDeleteProfileImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: number): Promise<User> =>
      axios.delete(`users/${id}/profile-image`),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.PROFILE]),
    },
  );
};
