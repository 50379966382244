import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper';
import { Header } from '../shared/Header';
import { DisplayGrid } from '../../../../components/DisplaySettings/displays/Displays';
import { Loader } from '../../../../components/Loader';
import { ActionBar } from './components/ActionBar';
import {
  useCampaigns,
  useCompany,
  useFollowCompany,
  useProfile,
  useUnfollowCompany,
} from '../../../../hooks/query';
import { getImage } from '../../../../utils/profileImage.util';

interface QueryParams {
  id: string;
}

export const BrandAccount = observer(() => {
  const { id } = useParams<QueryParams>();

  const { data: company, isSuccess: isCompanySuccess } = useCompany({
    id: parseInt(id),
  });
  const { data: user, isSuccess: isUserSuccess } = useProfile();
  const { data: campaigns = [], isSuccess: isCampaignsSuccess } = useCampaigns(
    {
      companyId: company?.id as number,
    },
    {
      enabled: isCompanySuccess,
    },
  );
  const { mutate: followCompany } = useFollowCompany();
  const { mutate: unfollowCompany } = useUnfollowCompany();

  const { isFollowing } = useMemo(() => {
    let isFollowing = false;
    if (user && company) {
      isFollowing = !!user.followingCompanies.find(
        ({ id }) => id === company.id,
      );
    }
    return { isFollowing };
  }, [user, company]);

  const handleFollow = () => {
    followCompany({
      companyId: company?.id as number,
    });
  };

  const handleUnfollow = () => {
    unfollowCompany({
      companyId: company?.id as number,
    });
  };

  return (
    <PageWrapper>
      {isCompanySuccess && isUserSuccess ? (
        <>
          <Header
            name={company.name}
            username={company.username}
            shared={company.shares}
            followed={
              company.followers.length + company.followersCompanies.length
            }
            modalData={{
              description: company.notes,
              email: company.email,
              contactNumber: company.contactNumber,
            }}
            image={getImage(company?.profileImage)}
          />
          <ActionBar
            name={company.name}
            isFollowing={isFollowing}
            onFollow={handleFollow}
            onUnfollow={handleUnfollow}
            modalData={{
              description: company.notes,
              email: company.email,
              contactNumber: company.contactNumber,
            }}
          />
          {isCampaignsSuccess && <DisplayGrid products={campaigns} />}
        </>
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  );
});
