import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { observer } from 'mobx-react-lite';

import { useWindowSize } from 'usehooks-ts';
import { WINDOW } from '../../../../../config/constants';
import { isDesktop } from '../../../../../utils/ifDesktop.util';
import { ProductOption, ProductSize } from '../../../../../types/company.types';
import { Item } from '../ProductOptionsPopupPicker/components/Item/Item';

interface ProductSizesPopupPickerProps extends DialogProps {
  selectedProductOption: ProductOption | undefined;
  setSelectedSizesOption: (value: ProductSize) => void;
  selectedSizeOption: ProductSize | undefined;
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    zIndex: 12000,
    maxWidth: ({ isDesktop }: any) =>
      isDesktop ? WINDOW.MAX_WIDTH_DESKTOP : WINDOW.MAX_WIDTH,
  },
});

export const ProductSizesPopupPicker = observer(
  ({
    selectedProductOption,
    setSelectedSizesOption,
    selectedSizeOption,
    ...props
  }: ProductSizesPopupPickerProps) => {
    const { width } = useWindowSize();

    const classes = useStyles({ isDesktop: isDesktop(width) });
    const popupRef = useRef();

    const handleSelectOption = (option: ProductSize) => {
      setSelectedSizesOption(option);
    };

    return (
      <>
        <div>
          <Dialog {...props} ref={popupRef} classes={{ paper: classes.dialog }}>
            {selectedProductOption?.productSizes?.map((productSize: any) =>
              productSize.amount > 0 ? (
                <Item
                  key={productSize.size}
                  name={productSize.size}
                  isPicked={productSize.size === selectedSizeOption?.size}
                  onClick={() => handleSelectOption(productSize)}
                />
              ) : null,
            )}
          </Dialog>
        </div>
      </>
    );
  },
);
