import React from 'react';
import { Loader } from '../components/Loader';
import { OrderExpandable } from '../components/Order/OrderExpandedable/OrderExpandable';
import { AccountSlider } from '../components/AccountSlider/AccountSlider';
import { PageWrapper } from '../containers/PageWrapper/PageWrapper';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../contexts/authContext';
import { useOrders } from '../hooks/query';

export const MyOrders = () => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const { data: campaigns = [], isSuccess: isUserOrderedCampaignsSuccess } =
    useOrders({ enabled: !!loggedInAccount });

  return (
    <PageWrapper>
      <AccountSlider
        icon="shop"
        title="My orders"
        onLeftArrow={() => history.push('/account/wishlist')}
        onRightArrow={() => history.push('/account/commissions')}
      />
      {isUserOrderedCampaignsSuccess ? (
        campaigns.map((order) => (
          <OrderExpandable
            key={order.id}
            orderId={order.id}
            {...order.campaign}
          />
        ))
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  );
};
