import React, { useState } from 'react';

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { AccountInfo } from '../shared/AccountInfo/AccountInfo';
import { SLIDER_HEIGHT } from './Account.config';
import { EmptyProfileTextWrapper } from './Account.style';
import { Text } from '../../../components';
import { COLORS } from '../../../config/constants';
import { AccountProducts } from '../shared/AccountProducts/AccountProducts';
import { Loader } from '../../../components/Loader';
import { useUser } from '../../../hooks/query/useUser';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAddToMessageList,
  useFollowUser,
  useProfile,
  useUnfollowUser,
  useUsersWishlist,
} from '../../../hooks/query';
import { getImage } from '../../../utils/profileImage.util';
import { isUserFollowing } from '../../../utils/following.util';
import { useUserOrderedCampaigns } from '../../../hooks/query/useUserOrderedCampagins';
import { DisplayType } from '../../../components/DisplaySettings/DisplaySettings.types';

interface QueryParams {
  username: string;
}

export const Account = () => {
  const history = useHistory();
  const { username } = useParams<QueryParams>();
  const { data: user, isSuccess: isUserSuccess } = useUser({ username });
  const [sliderIndex, setSliderIndex] = useState(0);
  const { data: currentUser, isSuccess: isCurrentUserSuccess } = useProfile();
  const { mutate: followUser } = useFollowUser();
  const { mutate: unfollowUser } = useUnfollowUser();
  const { mutate: addToMessageList } = useAddToMessageList();
  const [display, setDisplay] = useState<DisplayType>('vertical');

  const { data: campaigns = [] } = useUserOrderedCampaigns(
    { userId: user?.id as number },
    { enabled: isUserSuccess },
  );
  const { data: likes = [] } = useUsersWishlist(
    { id: user?.id as number },
    { enabled: isUserSuccess },
  );

  const onFollow = async () => {
    followUser({ userId: user?.id as number });
  };

  const onUnfollow = async () => {
    unfollowUser({ userId: user?.id as number });
  };

  const onMessage = async () => {
    addToMessageList(
      {
        userId: user?.id as number,
      },
      {
        onSuccess: () => {
          history.push('/account/messages');
        },
      },
    );
  };

  return (
    <>
      {isUserSuccess && isCurrentUserSuccess ? (
        <PageWrapper>
          {
            <AccountInfo
              title={user.name}
              username={user.username}
              avatar={getImage(user.profileImage)}
              activity={{
                followers:
                  user.followers.length + user.followersCompanies.length,
                following:
                  user.following.length + user.followingCompanies.length,
                shares: user.shares.length,
              }}
              isFollowed={isUserFollowing(currentUser, user.id)}
              onFollow={onFollow}
              onUnfollow={onUnfollow}
              onMessage={onMessage}
            />
          }
          {campaigns.length > 0 || likes.length > 0 ? (
            <AccountProducts
              country="Czech Republic"
              index={sliderIndex}
              setIndex={setSliderIndex}
              display={display}
              setDisplay={setDisplay}
              height={SLIDER_HEIGHT}
              wishlist={likes}
              orders={campaigns}
            />
          ) : (
            <EmptyProfileTextWrapper>
              <Text
                size={14}
                letterSpacing={-0.08}
                lineHeight="18px"
                color={COLORS.COLOR_MIDDLE_GRAY}
                weight={400}
              >
                {`${
                  user?.name || 'User'
                } did not join any campaign yet, or hided them from showing`}
              </Text>
            </EmptyProfileTextWrapper>
          )}
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  );
};
