import React from 'react';

import './Counter.scss';
import { CounterStateProps } from './counter.interface';

export const Counter = ({
  children,
  count,
  fontCssClass,
}: CounterStateProps) => {
  return (
    <div className="counter__wrapper">
      <div className="icon">{children}</div>
      <div className={`${fontCssClass} counter`}>{count}</div>
    </div>
  );
};
