import React from 'react';

import { CustomRadioStateProps } from './customRadio.interface';
import './CustomRadio.scss';

export const CustomRadio = ({
  label,
  name,
  isDefault,
  onClick,
}: CustomRadioStateProps) => {
  return (
    <label className="custom-radio" onClick={onClick}>
      <input defaultChecked={isDefault || false} name={name} type="radio" />
      <span className="custom-radio"></span>
      <div className="label">{label}</div>
    </label>
  );
};
