import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import BlankImage from '../../assets/images/blank_product.webp';
import { ProductComponentProps, ProductProps } from './Product.interfaces';
import { colorIcon, colorFilledIcon } from './Product.config';
import { ProgressBar } from '../reusable';
import { Icon } from '../Icon/Icon';

import { ProductContainer } from './Product.styles';
import { PROGRESS_BAR_HEIGHTS } from '../reusable';
import { getImage } from '../../utils/profileImage.util';
import {
  useLikeCampaign,
  useUnlikeCampaign,
  useWishlist,
} from '../../hooks/query';
import { useAuthContext } from '../../contexts/authContext';

export const ProductComponent = ({
  id,
  productImages,
  buyers,
  goal,
  price,
  retailPrice,
  title,
  company,
  commissionRate,
  progressBarHeight = PROGRESS_BAR_HEIGHTS.SMALL,
  type,
  size = 'small',
  style,
}: ProductComponentProps) => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const { mutate: likeCampaign } = useLikeCampaign();
  const { mutate: unlikeCampaign } = useUnlikeCampaign();
  const { data: likes = [] } = useWishlist(
    {
      id: loggedInAccount?.id as number,
    },
    {
      enabled: !!loggedInAccount && loggedInAccount.type === 'user',
    },
  );

  const isLiked = useMemo(() => {
    const isLiked = !!likes.find((likes) => likes.id === id);

    return isLiked;
  }, [likes]);

  const likeColor = isLiked ? colorFilledIcon : colorIcon;

  const handleLike = async (e: any) => {
    e.stopPropagation();
    if (!loggedInAccount) {
      history.push('/login');
    }
    if (isLiked) {
      await unlikeCampaign(id);
    } else {
      await likeCampaign(id);
    }
  };

  return (
    <ProductContainer
      type={type}
      progressBarHeight={progressBarHeight}
      style={style}
      size={size}
    >
      <div
        className="product_image_wrapper"
        onClick={() => history.push(`/campaign/${id}`)}
      >
        <div className="like" onClick={handleLike}>
          <Icon name="like" width={20} height={19} color={likeColor} pointer />
        </div>
        <div className="product_image_container">
          <img src={getImage(productImages?.[0], BlankImage)} alt="image" />
        </div>
        <div className="product_status_bar">
          <ProgressBar
            pcs={buyers.length}
            pcsMax={goal}
            height={progressBarHeight}
          />
        </div>
      </div>
      <div onClick={() => history.push(`/campaign/${id}`)}>
        <div className="details">
          <div>
            <div className="price">${price}</div>
            {retailPrice && <div className="price_old">${retailPrice}</div>}
          </div>
          <div>
            <div className="buyers">{buyers.length}</div>
            <div className="goal">/{goal}</div>
          </div>
        </div>
        <div className="description">
          <div className="title">{title}</div>
          <div className="description_details">
            <div>{company.name}</div>
            <div>commission: {commissionRate * 100}%</div>
          </div>
        </div>
      </div>
    </ProductContainer>
  );
};

export const Product = (props: ProductProps) => (
  <ProductComponent {...props} type="filled" />
);

export const ProductOutlined = (props: ProductProps) => (
  <ProductComponent {...props} type="outlined" />
);
