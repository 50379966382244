import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CompanyV2 } from '../../types/company.types';

interface MutationVariables {
  id: number;
  image: File;
}

export const useUpdateCompanyProfileImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, image }: MutationVariables): Promise<CompanyV2> =>
      axios.put(
        `companies/${id}/profile-image`,
        { image },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.GET_COMPANY]),
    },
  );
};
