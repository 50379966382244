import React from 'react';
import styled from 'styled-components';

import { Text, Icon } from '../../../../components';
import { IconProps } from '../../../../components/Icon/Icon';
import {
  accountRightConfig,
  arrowLeftConfig,
  arrowRightConfig,
  markIconConfig,
} from '../config';

interface HeaderProps {
  icon: IconProps;
  title: string;
  accessoryLeft?: IconProps;
  accessoryRight?: IconProps;
}

const Wrapper = styled.div`
  width: 100%;
  height: 122px;
  display: flex;
  padding: 22px 46px;
  justify-content: space-between;
  align-items: center;
`;

const Accessory = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;
  margin-left: 16px;
`;

export const Header = ({
  accessoryLeft,
  icon,
  accessoryRight,
  title,
}: HeaderProps) => {
  const renderIcon = (icon: IconProps) => <Icon {...icon} />;

  return (
    <Wrapper>
      <Accessory>{accessoryLeft && renderIcon(accessoryLeft)}</Accessory>
      <MainContainer>
        {renderIcon(icon)}
        <Title>{title}</Title>
      </MainContainer>
      <Accessory>{accessoryRight && renderIcon(accessoryRight)}</Accessory>
    </Wrapper>
  );
};

interface FollowersHeaderProps {
  title?: string;
  onAccessoryClick: () => void;
}

export const FollowersHeader = ({
  onAccessoryClick,
  title = 'Followers',
}: FollowersHeaderProps) => (
  <Header
    accessoryRight={{ ...arrowRightConfig, onClick: onAccessoryClick }}
    icon={accountRightConfig}
    title={title}
  />
);

export const FollowingHeader = ({
  onAccessoryClick,
  title = 'Followings',
}: FollowersHeaderProps) => (
  <Header
    accessoryLeft={{ ...arrowLeftConfig, onClick: onAccessoryClick }}
    icon={markIconConfig}
    title={title}
  />
);
