import React from 'react';

import './CampaignDuration.scss';
import { InputRadioBlock } from '../InputRadioBlock/InputRadioBlock';
import { InputNumber } from '../../../../components/Input/Input';
import { CampaignDurationStateProps } from './campaignDuration.interface';
import DatePicker from '../DatePicker/DatePicker';
import { getDaysBetweenDates } from '../../../../../../utils/campaignDuration.util';
import { CreateCampaignFormData } from '../../../../CampaignRegistration';

export const CampaignDuration = ({
  isActive,
  isFirst,
  index,
  setIsActive,
  onDurationChange,
  startDate,
  endDate,
  updateFormData,
}: CampaignDurationStateProps<CreateCampaignFormData>) => {
  const handleStartDateChange = (date: Date) => {
    updateFormData('startDate', date);
  };
  const handleEndDateChange = (date: Date) => {
    updateFormData('endDate', date);
  };
  return (
    <div className={`price__campaignDuration ${isFirst ? 'first' : ''}`}>
      <InputRadioBlock
        title="Campaign duration"
        description={`Enter the campaign duration and consider factors such as product availability and market demand when setting the duration.`}
        leftRadioLabel="on"
        rightRadioLabel="off"
        name={`campaignDuration-${index}`}
        isActive={isActive}
        setIsActive={setIsActive}
      />
      <div className="input-number">
        <InputNumber
          isActive={isActive}
          type="duration"
          measure="days"
          onChange={onDurationChange}
          value={
            startDate && endDate
              ? getDaysBetweenDates(startDate, endDate).toString()
              : ''
          }
        />
      </div>
      <div className="description">
        Or set starting and ending date for this campaign:
      </div>
      <div>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          placeholder="Start of campaign"
          disabled={!isActive}
        />
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          placeholder="End of campaign"
          disabled={!isActive}
        />
      </div>
    </div>
  );
};
