import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { COLORS } from '../../config/constants';
import { Icon } from '../Icon/Icon';

import {
  FooterWrapper,
  FooterPlug,
  FooterCTAWrapper,
  FooterCTABackground,
  CreateButton,
  LoginButton,
  FooterPreviewWrapper,
  FooterPreviewLinksWrapper,
  FooterPreviewLink,
} from './Footer.styles';
import { CompanyStore } from '../../store/company.store';
import { useAuthContext } from '../../contexts/authContext';
import { useWindowSize } from 'usehooks-ts';
import { ifDesktop, isDesktop } from '../../utils/ifDesktop.util';

export const FOOTER_HEIGHT = 62;
const ICONS_COLOR = COLORS.COLOR_MIDDLE_GRAY;

const UserFooter = () => {
  const { width } = useWindowSize();
  const { loggedInAccount } = useAuthContext();

  return (
    <>
      <FooterWrapper
        height={FOOTER_HEIGHT}
        maxWidth={ifDesktop(width)}
        bottom={isDesktop(width) ? 'calc((100vh - 790px)/2)' : 0}
      >
        {loggedInAccount?.type === 'user' ? (
          <Link to="/account">
            <Icon
              name="user-account"
              width={23}
              height={23}
              color={ICONS_COLOR}
              pointer
            />
          </Link>
        ) : (
          <Link to="/account/brand">
            <Icon
              name="star"
              width={23}
              height={23}
              color={ICONS_COLOR}
              pointer
            />
          </Link>
        )}
        {/* <Link
          to={loggedInAccount?.type === 'user' ? '/account' : '/account/brand'}
        >
          <Icon
            name="star"
            width={23}
            height={23}
            color={ICONS_COLOR}
            pointer
          />
        </Link> */}
        <Link to="/">
          <Icon
            name="logo"
            width={47}
            height={32}
            color={ICONS_COLOR}
            pointer
          />
        </Link>
        <Link to="/community">
          <Icon
            name="account-group"
            width={32}
            height={18}
            color={ICONS_COLOR}
            pointer
          />
        </Link>
      </FooterWrapper>
      <FooterPlug height={FOOTER_HEIGHT} />
    </>
  );
};

const CompanyFooter = () => {
  const { width } = useWindowSize();
  return (
    <>
      <FooterWrapper
        height={FOOTER_HEIGHT}
        maxWidth={ifDesktop(width)}
        bottom={isDesktop(width) ? 'calc(7.5vh + 10px)' : 0}
      >
        <Link to="/account/brand">
          <Icon
            name="star"
            width={23}
            height={23}
            color={ICONS_COLOR}
            pointer
          />
        </Link>
        <Link to="/">
          <Icon
            name="logo"
            width={47}
            height={32}
            color={ICONS_COLOR}
            pointer
          />
        </Link>
        <Link to="/community">
          <Icon
            name="account-group"
            width={32}
            height={18}
            color={ICONS_COLOR}
            pointer
          />
        </Link>
      </FooterWrapper>
      <FooterPlug height={FOOTER_HEIGHT} />
    </>
  );
};

export const Footer = observer(() => {
  const { companyData } = useContext(CompanyStore);

  return companyData ? <CompanyFooter /> : <UserFooter />;
});

export const FooterCTA = () => {
  const { width } = useWindowSize();
  return (
    <FooterCTAWrapper bottom={isDesktop(width) ? 'calc((100vh - 790px)/2)' : 0}>
      <FooterCTABackground maxWidth={ifDesktop(width)} />
      <CreateButton to="signup-company">Create a campaign</CreateButton>
      <LoginButton to="login">Login</LoginButton>
    </FooterCTAWrapper>
  );
};

const LINKS = [
  {
    title: 'How It works',
    link: '/about',
  },
  {
    title: 'About Company',
    link: '/privacy-policy',
  },
  {
    title: 'Terms and conditions',
    link: '/terms',
  },
  {
    title: 'Privacy policy',
    link: '/policy',
  },
];

export const FooterPreview = () => {
  const { loggedInAccount } = useAuthContext();
  return (
    <FooterPreviewWrapper>
      <Icon name="logo" width={132} height={91} color={COLORS.COLOR_BLACK} />
      <FooterPreviewLinksWrapper>
        {LINKS.map(({ link, title }) => (
          <FooterPreviewLink key={title}>
            <Link to={link}>{title}</Link>
          </FooterPreviewLink>
        ))}
      </FooterPreviewLinksWrapper>
      {!!loggedInAccount ? <Footer /> : <FooterCTA />}
    </FooterPreviewWrapper>
  );
};
