import React from 'react';

import {
  DropdownBody,
  DropdownHeader,
  DropdownWrapper,
  IconWrapper,
} from './index.style';
import { Text } from '../../../../components';
import { COLORS } from '../../../../config/constants';
import { Icon } from '../../../../components';
import { CategoryIcons } from '../../../../components/Icon/Icon.types';
import { CategoryDropdownItem } from '../../../../components/Categories/CategoryDropdownItem';
import { categorySizes } from './index.config';
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types';
import { Category } from '../../../../types/category.types';

interface Props {
  onCloseModal: () => void;
  categoryIds: number[];
  categories: Category[];
  toggleCategories: (id: number) => void;
}

export const CategoriesPopupDropdown = ({
  categoryIds,
  categories,
  onCloseModal,
  toggleCategories,
}: Props) => (
  <DropdownWrapper>
    <DropdownHeader>
      <Text
        color={COLORS.COLOR_MIDDLE_GRAY}
        weight={600}
        size={16}
        lineHeight="126.2%"
      >
        Choose categories
      </Text>
      <IconWrapper onClick={onCloseModal}>
        <Icon
          name="cross"
          width={15}
          height={15}
          color={COLORS.COLOR_MIDDLE_GRAY}
        />
      </IconWrapper>
    </DropdownHeader>
    <DropdownBody>
      {categories.map(({ name, icon, id }) => {
        return (
          <CategoryDropdownItem
            key={id}
            id={id}
            // iconName={icon as CategoryIconNames}
            iconName={CategoryIcons[icon as ICategoryNames]}
            size={categorySizes[icon as ICategoryNames]}
            title={name}
            active={categoryIds.includes(id)}
            toggleCategories={toggleCategories}
          />
        );
      })}
    </DropdownBody>
  </DropdownWrapper>
);
