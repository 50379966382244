import React from 'react';
import { observer } from 'mobx-react-lite';
import { AccountSlider } from '../../components/AccountSlider/AccountSlider';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { useHistory } from 'react-router-dom';
import { useMessagedUsers, useUnreadMessages } from '../../hooks/query';
import styles from './myMessages.module.scss';
import UserItem from './UserItem';

export const MyMessages = observer(() => {
  const history = useHistory();
  const { data: messagedUsers = [] } = useMessagedUsers();
  const { data: unreadMessages, isSuccess: isUnreadMessagesSuccess } =
    useUnreadMessages();

  const handleClick = (username: string) => {
    history.push(`/account/messages/${username}`);
  };

  return (
    <PageWrapper>
      <AccountSlider
        icon="chat"
        title="My messages"
        onLeftArrow={() => history.push('/account/commissions')}
        onRightArrow={() => history.push('/account/notifications')}
      />
      <div className={styles.userItemsContainer}>
        {isUnreadMessagesSuccess &&
          messagedUsers.map(({ name, id, profileImage, username }) => (
            <UserItem
              key={id}
              profileImage={profileImage}
              username={username}
              name={name}
              onClick={handleClick}
              unreadCount={unreadMessages[id]?.length}
            />
          ))}
      </div>
    </PageWrapper>
  );
});
