import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Text, Icon } from '../../../../components';
import { COLORS } from '../../../../config/constants';

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  cursor: pointer;
`;

export const ItemAddCompany = () => {
  const history = useHistory();

  const onClick = () => history.push('/signup-company');

  return (
    <ItemContainer onClick={onClick}>
      <Text
        size={17}
        lineHeight="22px"
        letterSpacing={-0.41}
        color={COLORS.COLOR_ORANGE_1}
        style={{ marginRight: 10 }}
      >
        Add company
      </Text>
      <Icon name="plus" />
    </ItemContainer>
  );
};
