import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentVm } from './Payment.vm';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { CardElementWrapper, PayButton } from './styles';

const cardOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const Payment = () => {
  const history = useHistory();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const campaignId = location.pathname.split('/')[2];

  const [vm] = useState(new PaymentVm(campaignId));

  const onBuy = async () => {
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const intentSecret = await vm.createPaymentIntent();
        if (intentSecret) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            intentSecret,
            {
              payment_method: {
                card: cardElement,
              },
            },
          );
          if (error) {
            alert(error.message);
            history.push('/');
          } else if (paymentIntent?.status === 'succeeded') {
            alert('Success!');
            history.push(`/campaign/${campaignId}`);
          }
        }
      }
    }
  };

  return (
    <PageWrapper>
      <CardElementWrapper>
        <CardElement options={cardOptions} />
      </CardElementWrapper>
      <PayButton onClick={onBuy}>Pay</PayButton>
    </PageWrapper>
  );
};
