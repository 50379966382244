import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={29}
      height={29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.437 13.5H23.45V8.2a2.65 2.65 0 00-2.65-2.65h-5.3V3.563a3.313 3.313 0 00-6.625 0V5.55h-5.3A2.65 2.65 0 00.925 8.2v5.035h1.987a3.563 3.563 0 013.578 3.578 3.563 3.563 0 01-3.578 3.577H.925v5.035a2.65 2.65 0 002.65 2.65H8.61v-1.988a3.563 3.563 0 013.577-3.577 3.563 3.563 0 013.578 3.577v1.988H20.8a2.65 2.65 0 002.65-2.65v-5.3h1.987a3.313 3.313 0 000-6.625z"
        fill={props.color || '#FF6D0A'}
      />
    </svg>
  );
}

export const PuzzleIcon = React.memo(SvgComponent);
