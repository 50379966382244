import React from 'react';
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types';
import { categoriesInRow } from './CategoryPreview.config';
import { CategoryItem } from '../../../../components';
import { useCategories } from '../../../../hooks/query';

interface CategoriesListProps {
  onClick: (categoryId: number) => void;
  selectedCategoryIds?: Array<number>;
}

// TODO Reimplement this in proper way once category icons are fetched from server
export const CategoriesList = ({
  onClick,
  selectedCategoryIds = [],
}: CategoriesListProps) => {
  const { data: categories = [] } = useCategories();

  return (
    <>
      {categories.map((value, index) => {
        const firstRowStyles =
          index < categoriesInRow ? { marginBottom: 15 } : {};
        const rowStyles =
          (index + 1) % categoriesInRow !== 0 ? { marginRight: 8.5 } : {};
        const columnStyles =
          index - categoriesInRow < categories.length
            ? { marginBottom: 31 }
            : {};

        return (
          <CategoryItem
            key={value.icon}
            name={value.icon as ICategoryNames}
            size="medium"
            style={{
              ...rowStyles,
              ...columnStyles,
              ...firstRowStyles,
              padding: 0,
            }}
            isActive={selectedCategoryIds.includes(value.id)}
            onClick={() => onClick(value.id)}
          />
        );
      })}
    </>
  );
};
