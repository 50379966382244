import styled from 'styled-components';
import { COLORS } from '../../../../config/constants';

const centrifyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AccountInfoWrapper = styled.div`
  display: flex;
  padding-bottom: 28px;
  flex-direction: column;
  background-color: ${COLORS.COLOR_WHITE};
`;

export const AccountAvatar = styled(centrifyContent)`
  height: 76px;
  margin-top: 26px;

  & div {
    position: relative;
    height: 76px;
    width: 76px;

    & .cross-avatar {
      position: absolute;
      z-index: 100;
      width: 25px;
      height: 25px;
      background-color: #fff;
      top: 0px;
      left: 0px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & img {
      width: 100%;
      height: 100%;
      border-radius: 99px;
    }

    & input {
      position: absolute;
      width: 76px;
      height: 76px;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
`;

export const AccountName = styled(centrifyContent)`
  height: 22px;
  margin-top: 13px;
`;

export const AccountId = styled(centrifyContent)`
  height: 22px;
  margin-top: 5px;
`;
