import React from 'react';

interface SwitchProps {
  selected: boolean;
  bgColor: string;
  textColor: string;
  borderColor: string;
  label: string;
  circleColor: string;
  onClick: (event: any) => void;
}

export const Switch = ({
  selected,
  bgColor,
  onClick,
  circleColor,
  textColor,
  borderColor,
  label,
}: SwitchProps) => {
  return (
    <div
      style={{
        width: 103,
        height: 30,
        backgroundColor: bgColor,
        borderRadius: 20,
        display: 'flex',
        flexDirection: !selected ? 'row' : 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 4,
        paddingRight: 4,
        cursor: 'pointer',
        border: '1px solid',
        borderColor,
      }}
      onClick={onClick}
    >
      <div
        style={{
          borderRadius: '50%',
          height: 23,
          width: 23,
          backgroundColor: circleColor,
        }}
      ></div>
      <div
        style={{
          fontFamily: 'Poppins',
          fontSize: 14,
          fontWeight: 600,
          paddingRight: 4,
          paddingLeft: 4,
          color: textColor,
        }}
      >
        {label}
      </div>
    </div>
  );
};
