import React, { useState } from 'react';

import { EmptyContainer, EmptyText, EmptyTitle, Header } from './Search.styles';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { SearchInput } from './components/SearchInput/SearchInput';
import { DisplaySettings } from '../../components/DisplaySettings/DisplaySettings';
import { SearchFilter } from './components/SearchFilter/SearchFilter';
import { ProductList } from '../../components/DisplaySettings/ProductList';
import { Loader } from '../../components/Loader';
import { useCampaigns, useCompanies } from '../../hooks/query';
import { DisplayType } from '../../components/DisplaySettings/DisplaySettings.types';
import { CompanyV2 } from '../../types/company.types';
import { useSearchContext } from '../../contexts/searchContext';

export const Search = () => {
  const {
    debouncedInput,
    queryParamFilter,
    selectedCategoryIds,
    deliveryLocationIds,
    isCategoriesSuccess,
    getCountryNameFromId,
  } = useSearchContext();
  const [display, setDisplay] = useState<DisplayType>('grid');
  const { data: companies = [], isSuccess: isCompaniesSuccess } =
    useCompanies();
  const {
    data: campaigns = [],
    isSuccess,
    isFetching,
  } = useCampaigns({
    sort: queryParamFilter,
    filter: {
      text: debouncedInput.length ? debouncedInput : undefined,
      categoryIds: selectedCategoryIds,
      deliveryLocationIds,
    },
  });

  return (
    <PageWrapper style={{ background: '#F7F7FA' }}>
      <Header>
        <SearchInput />
        <DisplaySettings
          display={display}
          setDisplay={setDisplay}
          country={
            deliveryLocationIds.length
              ? getCountryNameFromId(deliveryLocationIds[0])
              : 'All countries'
          }
          showFilter
        />
      </Header>
      {isCategoriesSuccess && <SearchFilter />}
      {isFetching && <Loader />}
      {isSuccess && campaigns.length === 0 && (
        <EmptyContainer>
          <EmptyTitle>No search results</EmptyTitle>
          <EmptyText>
            Type something else or change your filter settings
          </EmptyText>
        </EmptyContainer>
      )}
      {isCompaniesSuccess && isSuccess && campaigns.length > 0 && (
        <ProductList
          display={display}
          products={campaigns}
          companies={companies as CompanyV2[]}
        />
      )}
    </PageWrapper>
  );
};
