import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CampaignV2 } from '../../types/company.types';

interface DeleteProductImageArgs {
  id: number;
  imageId: number;
}

export const useDeleteProductImage = () => {
  return useMutation(
    async ({ id, imageId }: DeleteProductImageArgs): Promise<CampaignV2> =>
      axios.delete(`campaigns/${id}/product-image/${imageId}`),
  );
};
