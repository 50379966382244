import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

export const useUnlikeCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (campaignId: number): Promise<User> =>
      axios.delete('users/like/remove', { data: { campaignId } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.GET_CAMPAIGN]);
        queryClient.invalidateQueries([QUERY_KEYS.GET_WISHLIST]);
      },
    },
  );
};
