import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CampaignV2 } from '../../types/company.types';

interface CampaignsParams {
  countryIds?: number[];
}
interface ConfigParams {
  enabled?: boolean;
}

export const useLocalCampaigns = (
  { countryIds }: CampaignsParams,
  config: ConfigParams,
) => {
  return useQuery(
    [QUERY_KEYS.GET_LOCAL_CAMPAIGNS],
    async (): Promise<CampaignV2[]> => {
      return axios.get('campaigns/local', {
        params: { countryIds },
      });
    },
    {
      ...config,
    },
  );
};
