import styled, { CSSObject } from 'styled-components';

import { COLORS } from '../../../../config/constants';
import { Text } from '../../../../components/Text';

interface ImageWrapperProps {
  image: any;
  styles?: CSSObject;
  hasShadow?: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ImageWrapper = styled.div`
  height: 440px;
  background-image: url(${(p: ImageWrapperProps) => p.image});
  background-size: cover;
  background-position: center;
  ${(p: ImageWrapperProps) => p.styles};
  &::before {
    content: '';
    position: absolute;
    background: ${(p: ImageWrapperProps) =>
      p.hasShadow
        ? 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
        : null};
    height: 25%;
    width: ${(p: ImageWrapperProps) => (p.hasShadow ? '100%' : null)};
  }
`;

export const Header = styled(Text)`
  width: 278px;
  font-size: 32px;
  line-height: 119.2%;
  color: ${COLORS.COLOR_WHITE};
  position: sticky;
  padding-left: 19px;
  padding-top: 19px;
`;

export const Description = styled(Text)`
  width: 288px;
  font-weight: normal;
  font-size: 16px;
  line-height: 157.2%;
  margin-top: 12px;
  padding-left: 19px;
  color: ${COLORS.COLOR_WHITE};
`;

export const SliderWrapper = styled.div`
  position: relative;
  height: 350px;
  background-color: ${COLORS.COLOR_WHITE};
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;
