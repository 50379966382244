import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { FollowersHeader } from '../shared/components/Header';
import { Follower } from '../shared/components/Follower';
import {
  useFollowUser,
  useProfile,
  useUnfollowUser,
} from '../../../hooks/query';
import { isUserFollowing } from '../../../utils/following.util';

export const Followers = () => {
  const history = useHistory();
  const { mutate: followUser } = useFollowUser();
  const { mutate: unfollowUser } = useUnfollowUser();
  const { data: user, isSuccess } = useProfile();

  const onAccessoryClick = () => history.push('/account/following');

  const onFollow = async (id: number, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser({ userId: id });
    } else {
      await unfollowUser({ userId: id });
    }
  };

  return (
    <PageWrapper noFooter>
      <FollowersHeader
        onAccessoryClick={onAccessoryClick}
        title="My followers"
      />
      {isSuccess &&
        user.followers.map(({ id, profileImage, name, username }) => {
          const isFollowing = isUserFollowing(user, id);
          return (
            <Follower
              avatar={profileImage}
              name={name}
              username={username}
              isFollowed={isFollowing}
              onFollow={() => onFollow(id, isFollowing ? 'unfollow' : 'follow')}
              key={id}
            />
          );
        })}
    </PageWrapper>
  );
};
