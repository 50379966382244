import React from 'react';
import { useHistory } from 'react-router';

import { ActivityWrapper } from './Activity.style';
import { HEIGHT } from './Activity.config';
import { ActivityBlock } from './ActivityBlock';
import { ActivityProps } from './Activity.interface';
import { useAuthContext } from '../../../../../contexts/authContext';

export const Activity = ({
  followers,
  following,
  shares,
  username,
}: ActivityProps) => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const getLink =
    loggedInAccount?.username === username
      ? '/account/'
      : `/users/${username}/`;

  const data = [
    {
      title: 'Followers',
      value: followers,
      onClick: () => history.push(`${getLink}followers`),
    },
    {
      title: 'Following',
      value: following,
      onClick: () => history.push(`${getLink}following`),
    },
    { title: 'Shares', value: shares },
  ];

  return (
    <ActivityWrapper height={HEIGHT}>
      {data.map((item, index) => (
        <ActivityBlock
          title={item.title}
          key={index}
          count={item.value}
          onClick={item.onClick}
        />
      ))}
    </ActivityWrapper>
  );
};
