import { ProductDetails } from './stages/ProductDetails/ProductDetails';
import { Price } from './stages/Price/Price';
import { Commission } from './stages/Commission/Commission';
import { CampaignFormData } from './campaignRegistration.interface';
import { DateTime } from 'luxon';

export const chooseComponent = (stage: number) => {
  if (stage === 0) return ProductDetails;
  if (stage === 1) return Price;
  if (stage === 2) return Commission;
  return;
};

export const splitUrl = (uri: string) => {
  const uriElements = uri.split('/');
  const isEditing = uriElements[1] === 'edit';
  const campaignId = isEditing ? uriElements[2] : null;

  return { isEditing, campaignId };
};

export const defaultCampaignRegistrationForm: CampaignFormData = {
  companyId: '',
  companyName: '',
  title: '',
  description: '',
  fullDescription: '',
  images: [],
  categories: [],
  location: {
    country: '',
    city: '',
  },
  price: 0,
  retailPrice: 0,
  goal: 0,
  orderMoreThanOne: false,
  isDurationActive: false,
  commission: 0,
  status: 'active',
  liked: [],
  shared: [],
  buyers: [],
  startDate: DateTime.now().toISODate(),
};

export enum SHIPPING_OPTIONS {
  FREE = 'free-shipping',
  PAID = 'paid-shipping',
}
