import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { LocalStorageService } from '../services/core/localStorage.service';
import { useAuthContext } from '../contexts/authContext';

interface PropsPublicRoute extends RouteProps {
  restricted?: boolean;
}

export const PrivateRoute = ({ component, ...rest }: RouteProps) => {
  const { loggedInAccount } = useAuthContext();
  const currentAccount = LocalStorageService.getItem('currentAccount');

  return !!loggedInAccount || !!currentAccount ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

export const PublicRoute = ({
  component,
  restricted = false,
  ...rest
}: PropsPublicRoute) => {
  const user = LocalStorageService.getItem('user');

  return user && restricted ? (
    <Redirect to="/" />
  ) : (
    <Route {...rest} component={component} />
  );
};
