import React from 'react';
import { useHistory } from 'react-router-dom';

import { Icon, Text } from '../../../../components';
import { Wrapper, TextContainer } from './MainLayout.styles';
import { Search } from '../../components/Search/Search';
import { useSearchContext } from '../../../../contexts/searchContext';

export const MainLayout = () => {
  const { setInput, input } = useSearchContext();
  const history = useHistory();

  const onClick = () => {
    history.push({ pathname: '/search' });
  };

  return (
    <Wrapper>
      <TextContainer>
        <Icon name="logo" width={114} height={77} />
        <Text
          size={33}
          lineHeight="119.2%"
          align="center"
          style={{ marginTop: 53, color: 'white' }}
        >
          Co-buy <br /> and pay less
        </Text>
      </TextContainer>
      <Search
        placeholder="Search products"
        value={input}
        onChange={setInput}
        onClick={onClick}
      />
    </Wrapper>
  );
};
