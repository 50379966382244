import React, { useEffect, useRef, useState } from 'react';
import styles from './chat.module.scss';
import { Icon } from '../../components';
import { IconWrapper } from '../../components/AccountSlider/AccountSlider.style';
import { COLORS } from '../../config/constants';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { useMessagesWithUser, useProfile } from '../../hooks/query';
import { useUser } from '../../hooks/query/useUser';
import { Loader } from '../../components/Loader';
import avatar from '../../assets/images/DefaultAccountAvatar.svg';
import { getImage } from '../../utils/profileImage.util';
import { useAuthContext } from '../../contexts/authContext';
import ReceivedMessage from './ReceivedMessage';
import SentMessage from './SentMessage';
import InputMessage from './InputMessage';
import { useWebsocketContext } from '../../contexts/websocketContext';
import { Message } from '../../types/messages.types';
import { DateTime } from 'luxon';
import { useWindowSize } from 'usehooks-ts';
import { isDesktop } from '../../utils/ifDesktop.util';

interface QueryParams {
  username: string;
}

const Chat = () => {
  const { width } = useWindowSize();
  const { username } = useParams<QueryParams>();
  const { loggedInAccount } = useAuthContext();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { data: profile, isSuccess: isProfileSuccess } = useProfile();
  const history = useHistory();
  const { data: receiver, isSuccess: isUserSuccess } = useUser({ username });
  const { data: messages = [] } = useMessagesWithUser({
    username,
  });
  const { socket } = useWebsocketContext();
  const [latestMessages, setLatestMessages] = useState<Message[]>([]);

  const [isConnected, setIsConnected] = useState(
    socket ? socket.connected : false,
  );

  useEffect(() => {
    if (!socket) {
      return;
    }
    const onConnect = () => {
      setIsConnected(true);
      console.log(isConnected);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    const onReceiveMessage = (newMessage: Message) => {
      if (
        profile &&
        (receiver?.id === newMessage.receiver ||
          receiver?.id === newMessage.sender) &&
        (profile.id === newMessage.sender ||
          profile?.id === newMessage.receiver)
      ) {
        // Mark message as read only for receiver
        // Backend will assign reflectMessage = true when sending same message to sender
        if (!newMessage.reflectMessage) {
          socket?.emit('mark-message-as-read', {
            id: newMessage.id,
          });
        }
        setLatestMessages([...latestMessages, newMessage]);
        if (!messagesContainerRef.current) {
          return;
        }
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('receive-message', onReceiveMessage);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('receive-message', onReceiveMessage);
    };
  }, [socket, profile, receiver, latestMessages]);

  useEffect(() => {
    setTimeout(() => {
      if (!messagesContainerRef.current) {
        return;
      }
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }, 100);
  }, [messagesContainerRef.current]);

  const handleBackArrow = () => {
    history.push('/account/messages');
  };

  const currentUserId: number = loggedInAccount?.id as number;

  const handleSend = (message: string) => {
    if (!isUserSuccess || !message) {
      return;
    }
    socket?.emit('new-message', {
      message,
      receiverId: receiver.id,
    });
  };

  const dates: string[] = [];

  return (
    <PageWrapper>
      {isUserSuccess && isProfileSuccess ? (
        <div
          className={styles.container}
          style={{
            height: isDesktop(width) ? 640 : 'calc(100vh - 125px)',
          }}
        >
          <div className={styles.header}>
            <div className={styles.arrowContainer}>
              <IconWrapper onClick={handleBackArrow}>
                <Icon
                  color={COLORS.COLOR_BLACK}
                  name="arrow-left"
                  width={12}
                  height={21}
                />
              </IconWrapper>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.profileImageContainer}>
                <img src={getImage(receiver.profileImage, avatar)} alt="..." />
              </div>
              <div className={styles.nameContainer}>{receiver.name}</div>
            </div>
          </div>
          <div
            className={styles.lowerContainer}
            style={{
              height: isDesktop(width) ? 580 : '90%',
            }}
          >
            <div
              ref={messagesContainerRef}
              className={styles.messagesContainer}
              style={{
                height: isDesktop(width) ? '55vh' : '100%',
              }}
            >
              {messages
                .concat(latestMessages)
                .map(
                  (
                    { receiver: receiverId, message, id, createdAt },
                    index,
                    array,
                  ) => {
                    const date: string = DateTime.fromJSDate(
                      new Date(createdAt),
                    ).toFormat('dd.LL.yy');
                    let renderDate = false;

                    if (!dates.includes(date)) {
                      renderDate = true;
                      dates.push(date);
                    }

                    if (receiverId === currentUserId) {
                      return (
                        <div
                          key={id}
                          className={styles.receivedMessageContainer}
                        >
                          {renderDate && (
                            <div className={styles.dateContainer}>
                              <div className={styles.date}>{date}</div>
                            </div>
                          )}
                          <ReceivedMessage
                            receiverId={receiverId}
                            message={message}
                            profileImage={receiver.profileImage}
                            previousMessage={array[index - 1]}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={id} className={styles.sentMessageContainer}>
                          {renderDate && (
                            <div className={styles.dateContainer}>
                              <div className={styles.date}>{date}</div>
                            </div>
                          )}
                          <SentMessage
                            message={message}
                            profileImage={profile.profileImage}
                            senderId={currentUserId}
                            previousMessage={array[index - 1]}
                          />
                        </div>
                      );
                    }
                  },
                )}
            </div>
            <div className={styles.inputContainer}>
              <InputMessage onSend={handleSend} />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default Chat;
