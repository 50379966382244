import React, { useState } from 'react';

import {
  Wrapper,
  Header,
  CompanyInfo,
  ProductsSlider,
  ProductWrapper,
  StyledText,
  LogoContainer,
} from './CompanyProducts.style';
import { CompanyProductsStateProps } from './СompanyProducts.interface';
import { ProductOutlined } from '../../../../components';
import { ButtonFollow } from '../ButtonFollow/ButtonFollow';
import { COLORS } from '../../../../config/constants';
import { useHistory } from 'react-router-dom';

export const CompanyProducts = ({
  logo,
  title,
  products,
  companyId,
}: CompanyProductsStateProps) => {
  const history = useHistory();
  const [isActive, setActive] = useState<boolean>(false);

  const toggleActive = () => setActive(!isActive);

  return (
    <Wrapper>
      <Header>
        <CompanyInfo>
          <LogoContainer>
            <img
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '50%',
              }}
              src={logo}
              alt="logo"
            />
          </LogoContainer>
          <StyledText
            onClick={() => history.push(`/brand/${companyId}`)}
            size={19}
            color={COLORS.COLOR_DARK_GRAY}
          >
            {title}
          </StyledText>
        </CompanyInfo>
        <ButtonFollow isActive={isActive} onClick={toggleActive} pointer />
      </Header>
      <ProductsSlider>
        {products.length > 0 &&
          products.map((product, index) => (
            <ProductWrapper key={index}>
              <ProductOutlined {...product} />
            </ProductWrapper>
          ))}
      </ProductsSlider>
    </Wrapper>
  );
};
