import React, { useEffect } from 'react';
import { FirestoreService } from '../../services/core/firestore.service';
import { LocalStorageService } from '../../services/core/localStorage.service';
import { StripeService } from '../../services/core/stripe.service';
import { CheckoutSession } from '../../types/stripe.types';

export const StripeSuccess = () => {
  const checkoutSession =
    LocalStorageService.getItem<CheckoutSession>('checkoutSession');

  useEffect(() => {
    if (checkoutSession) {
      const createPaymentIntent = async () => {
        const paymentIntent =
          await StripeService.createPaymentIntentFromCheckoutSession({
            sessionId: checkoutSession.sessionId,
            customerId: checkoutSession.customerId,
          });
        if (paymentIntent) {
          await FirestoreService.orderItem(
            checkoutSession.userId,
            checkoutSession.campaignId,
            paymentIntent,
          );
        }
        LocalStorageService.removeItem('checkoutSession');
      };
      createPaymentIntent();
    }
  }, [checkoutSession]);

  return <div>Success</div>;
};
