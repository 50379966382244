import styled from 'styled-components';

import { COLORS } from '../../../../config/constants';
import { Text } from '../../../../components/Text';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 43px;
  padding-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Text)`
  width: 282px;
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;
  text-align: center;
  color: ${COLORS.COLOR_BLACK};
`;

export const Description = styled(Text)`
  width: 282px;
  font-weight: 300;
  font-size: 14px;
  line-height: 157.2%;
  text-align: center;
  color: ${COLORS.COLOR_BLACK};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const ItemsWrapper = styled.div`
  margin-top: 40px;
`;

export const ItemWrapper = styled.div`
  width: 135px;
  margin-top: 32px;
  margin-left: 20px;
`;

export const ItemTitle = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 125.7%;
  color: ${COLORS.COLOR_ORANGE_1};
  margin-bottom: 3px;
`;

export const ItemDescription = styled(Text)`
  font-weight: 600;
  font-size: 14px;
  line-height: 121.2%;
  color: ${COLORS.COLOR_BLACK};
`;
