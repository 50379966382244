import React, { Dispatch, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Input } from '../../components';
import { useAuthContext } from '../../../../contexts/authContext';
import { Footer } from '../../components/Footer';

interface IFormInput {
  name: string;
  username: string;
  email: string;
  password: string;
  password_repeat: string;
}

interface Props {
  setIsLoading: Dispatch<boolean>;
}

export const Form = ({ setIsLoading }: Props) => {
  const [signupError, setSignupError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { signup } = useAuthContext();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>();
  const password = useRef({});

  password.current = watch('password', '');

  const onSubmit = async (data: IFormInput) => {
    try {
      setLoading(true);
      setSignupError('');
      await signup({
        name: data.name,
        username: data.username,
        email: data.email,
        password: data.password,
      });
      history.push('/');
    } catch (e: any) {
      setSignupError(e.message);
    }
    setLoading(false);
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs">
        <Input
          type="text"
          label="Name"
          register={register('name', { required: true, maxLength: 20 })}
          error={
            errors.name?.type === 'required' ? 'Name is required' : undefined
          }
        />
        <Input
          type="text"
          label="User Name"
          register={register('username', { required: true, maxLength: 20 })}
          error={
            errors.username?.type === 'required'
              ? 'User Name is required'
              : undefined
          }
        />
        <Input
          type="email"
          label="Email"
          register={register('email', {
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
          error={
            errors.email?.type === 'required' ? 'Email is required' : undefined
          }
        />
        <Input
          type="password"
          label="Password"
          register={register('password', {
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
          error={errors.password && errors.password.message}
        />
        <Input
          type="password"
          label="Repeat Password"
          register={register('password_repeat', {
            validate: (value) =>
              value === password.current || 'The passwords do not match',
          })}
          error={errors.password_repeat && errors.password_repeat.message}
        />
      </div>
      <div className="buttons">
        <Button type="submit" className="white" disabled={loading}>
          Sign up
        </Button>
        {signupError && <p>{signupError}</p>}
      </div>
      <div className="auth_footer">
        Already have an account? <Link to="/login">Log in</Link>
      </div>
      <Footer setIsLoading={setIsLoading} />
    </form>
  );
};
