import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { User } from '../../types/user.types';

interface Params {
  username: string;
}

export const useUser = ({ username }: Params, config: Config = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_USER],
    async (): Promise<User> => {
      return axios.get(`users/username/${username}`);
    },
    config,
  );
};
