import React, { Fragment, useState } from 'react';

import './Description.scss';
import '../../../../config/headers.scss';
import '../../../../config/texts.scss';
import { ArrowDownBoldIcon, LocationIcon } from '../../../../assets/icons';
import { CategoryTag } from '../../../../components/Categories/CategoryTag/CategoryTag';
import { Category } from '../../../../types/category.types';
import Collapsible from 'react-collapsible';
import { ProductOptionsPopupPicker } from './ProductOptionsPopupPicker/ProductOptionsPopupPicker';
import { ProductOption, ProductSize } from '../../../../types/company.types';
import { ProductSizesPopupPicker } from './ProductSizesPopupPicker/ProductSizesPropupPicker';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/authContext';

interface DescriptionProps {
  shipping: boolean;
  setSelectedProductOption: (value: ProductOption) => void;
  selectedProductOption: ProductOption | undefined;
  setSelectedSizeOption: (value: ProductSize) => void;
  selectedSizeOption: ProductSize | undefined;
  productOptions: ProductOption[];
  fullDescription: string;
  shippingInfo: string;
  returnPolicy: string;
  id: number;
  categories: Category[];
}

export const Description = ({
  shipping,
  setSelectedProductOption,
  selectedProductOption,
  setSelectedSizeOption,
  selectedSizeOption,
  productOptions,
  fullDescription,
  shippingInfo,
  returnPolicy,
  id,
  categories,
}: DescriptionProps) => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();
  const [isProductOptionsPickerOpen, setIsProductOptionsPickerOpen] =
    useState(false);
  const [isSizeOptionsPickerOpen, setIsSizeOptionsPickerOpen] = useState(false);
  const closeProductOptionsPicker = () => setIsProductOptionsPickerOpen(false);
  const closeSizeOptionsPicker = () => setIsSizeOptionsPickerOpen(false);

  const onClick = () => history.push('/earn-commission-description');

  return (
    <Fragment>
      <div className="description-tip" onClick={onClick}>
        <div className="title">
          How to buy with wecobi and earn a commission?
        </div>
        <div className="subtitle">Read a short description</div>
      </div>
      {!!loggedInAccount ? (
        <div className="shipping">
          <div className="icon">
            <LocationIcon />
          </div>
          <div className="text">
            {shipping
              ? 'We are shipping to your country'
              : 'Shipping to your country not available'}
          </div>
        </div>
      ) : null}
      <div className="company-description">
        <div className="product-options">
          <div
            onClick={() => setIsProductOptionsPickerOpen(true)}
            className="product-options-title header-3"
          >
            Product options <ArrowDownBoldIcon />
          </div>
        </div>
        <ProductOptionsPopupPicker
          open={isProductOptionsPickerOpen}
          onClose={closeProductOptionsPicker}
          productOptions={productOptions}
          selectedProductOption={selectedProductOption}
          setSelectedProductOption={setSelectedProductOption}
        />

        <div className="product-sizes">
          <div
            onClick={() => setIsSizeOptionsPickerOpen(true)}
            className="product-sizes-title header-3"
          >
            Product sizes <ArrowDownBoldIcon />
          </div>
        </div>
        <ProductSizesPopupPicker
          open={isSizeOptionsPickerOpen}
          onClose={closeSizeOptionsPicker}
          selectedProductOption={selectedProductOption}
          setSelectedSizesOption={setSelectedSizeOption}
          selectedSizeOption={selectedSizeOption}
        />

        <div className="product-description">
          <Collapsible
            trigger={
              <div className="product-title header-3">
                Product description <ArrowDownBoldIcon />
              </div>
            }
          >
            <div className="text-1">{fullDescription}</div>
            <div className="serial-number text-1">
              Campaign ID: <span className="text-3">{id}</span>
            </div>
            {categories.length > 0 && (
              <div className="tags">
                {categories.map((category) => (
                  <CategoryTag title={category.name} key={category.id} />
                ))}
              </div>
            )}
          </Collapsible>
        </div>

        <div className="shipping-info">
          <Collapsible
            trigger={
              <div className="shipping-title header-3">
                Shipping <ArrowDownBoldIcon />
              </div>
            }
          >
            <div className="text-1">{shippingInfo}</div>
          </Collapsible>
        </div>
        <div className="return-policy">
          <Collapsible
            trigger={
              <div className="return-policy-title header-3">
                Return policy <ArrowDownBoldIcon />
              </div>
            }
          >
            <div className="text-1">{returnPolicy}</div>
          </Collapsible>
        </div>
      </div>
    </Fragment>
  );
};
