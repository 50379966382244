import React from 'react';

import './CategoryTag.scss';
import { CrossIcon } from '../../../assets/icons';
import { CategoryTagStateProps } from './categoryTag.interface';

export const CategoryTag = ({ title, onRemove }: CategoryTagStateProps) => {
  return (
    <div className={`categoryTag ${!!onRemove && 'cross'}`}>
      <div className="title">{title}</div>
      {!!onRemove && (
        <div className="close pointer" onClick={onRemove}>
          <CrossIcon />
        </div>
      )}
    </div>
  );
};
