import React from 'react';
import { CSSObject } from 'styled-components';

import { WindowWrapper, Wrapper } from './PageWrapper.styles';
import { Navigation } from '../../components/Navigation/Navigation';
import { Footer } from '../../components/Footer/Footer';
import { useWindowSize } from 'usehooks-ts';
import { WINDOW } from '../../config/constants';
import { isDesktop } from '../../utils/ifDesktop.util';

interface PageWrapperStateProps {
  children: React.ReactNode;
  style?: CSSObject;
  className?: string;
  noNavigation?: boolean;
  navigationAbsolute?: boolean;
  noFooter?: boolean;
}

export const PageWrapper = ({
  children,
  style,
  className,
  noNavigation = false,
  navigationAbsolute,
  noFooter = false,
}: PageWrapperStateProps) => {
  const { width } = useWindowSize();

  if (isDesktop(width)) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WindowWrapper>
          <div
            style={{
              overflow: 'auto',
              width: isDesktop(width)
                ? WINDOW.MAX_WIDTH_DESKTOP
                : WINDOW.MAX_WIDTH,
            }}
          >
            <Wrapper
              className={className}
              styles={{
                maxWidth: isDesktop(width)
                  ? WINDOW.MAX_WIDTH_DESKTOP
                  : WINDOW.MAX_WIDTH,
                ...style,
              }}
            >
              {!noNavigation && <Navigation absolute={navigationAbsolute} />}
              {children}
              {!noFooter && <Footer />}
            </Wrapper>
          </div>
        </WindowWrapper>
      </div>
    );
  }

  return (
    <WindowWrapper>
      <Wrapper className={className} styles={style}>
        {!noNavigation && <Navigation absolute={navigationAbsolute} />}
        {children}
        {!noFooter && <Footer />}
      </Wrapper>
    </WindowWrapper>
  );
};
