import React from 'react';

import './Arrow.scss';
import { ArrowLeftIcon, ArrowRightBoldIcon } from '../../../../../assets/icons';
import { ArrowBackStateProps, ArrowNextStateProps } from './arrow.interface';

export const ArrowBack = ({ onClick, title }: ArrowBackStateProps) => {
  return (
    <div onClick={onClick} className="footer__arrow-back pointer">
      <div className="icon">
        <ArrowLeftIcon />
      </div>
      <div className="text">{title}</div>
    </div>
  );
};

export const ArrowNext = ({ onClick, title }: ArrowNextStateProps) => {
  return (
    <div onClick={onClick} className="footer__arrow-next pointer">
      <div className="text">{title}</div>
      <div className="icon">
        <ArrowRightBoldIcon width={12} height={21} />
      </div>
    </div>
  );
};
