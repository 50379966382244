import React from 'react';

import {
  Wrapper,
  ImageWrapper,
  Header,
  SliderWrapper,
  Description,
  ArrowWrapper,
} from './PreviewPagePart.styles';
import { ProductSlider } from '../ProductsSlider/ProductsSlider';
import { CSSObject } from 'styled-components';
import { ProductProps } from '../../../../components/Product/Product.interfaces';
import arrow from '../../../../assets/images/landingArrowRight.png';
import { useHistory } from 'react-router-dom';

interface PreviewImageProps {
  products: ProductProps[];
  title: string;
  description?: string;
  image: any;
  withSlider?: boolean;
  styles?: CSSObject;
  hasArrow?: boolean;
  arrowStyle?: CSSObject;
  hasShadow?: boolean;
}

export const PreviewPagePart = ({
  title,
  image,
  withSlider,
  styles,
  description,
  products,
  hasArrow,
  arrowStyle,
  hasShadow,
}: PreviewImageProps) => {
  const history = useHistory();

  return (
    <Wrapper>
      <ImageWrapper image={image} styles={styles} hasShadow={hasShadow}>
        <div>
          <Header>{title}</Header>
        </div>
        {hasArrow && (
          <ArrowWrapper
            style={arrowStyle}
            onClick={() => history.push('/search')}
          >
            <img src={arrow} alt="..." />
          </ArrowWrapper>
        )}
        {description && <Description>{description}</Description>}
      </ImageWrapper>
      {withSlider && products.length > 0 && (
        <SliderWrapper>
          <ProductSlider products={products} />
        </SliderWrapper>
      )}
    </Wrapper>
  );
};
