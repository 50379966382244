import React from 'react';
import { FilterWrapper } from './SearchFilter.style';
import { CategoryItem } from '../../../../components';
import { ICategoryNames } from '../../../../components/Categories/CategoryItem/CategoryItem.types';
import { useSearchContext } from '../../../../contexts/searchContext';

export const SearchFilter = () => {
  const { selectedCategoryIds, handleCategoryClick, categories } =
    useSearchContext();
  return (
    <FilterWrapper>
      {categories.map(({ id, icon }) => {
        return (
          <CategoryItem
            key={icon}
            name={icon as ICategoryNames}
            size="small"
            style={{ marginLeft: '8px' }}
            isActive={selectedCategoryIds.includes(id)}
            onClick={() => handleCategoryClick(id)}
          />
        );
      })}
    </FilterWrapper>
  );
};
