import React from 'react';

import './Stage.scss';
import {
  AcceptedIcon,
  CircleEmptyIcon,
  CircleFilledIcon,
} from '../../../../../assets/icons/statuses';
import { StageStateProps } from './stage.interface';

export const Stage = ({ status, title }: StageStateProps) => {
  let renderedStatus;

  if (status === 'accepted') renderedStatus = <AcceptedIcon />;
  if (status === 'inactive') renderedStatus = <CircleEmptyIcon />;
  if (status === 'active') renderedStatus = <CircleFilledIcon />;

  return (
    <div className={`stages__stage ${status}`}>
      <div className="status">{renderedStatus}</div>
      <div className="title">{title}</div>
    </div>
  );
};
