import { OrderStatus } from './OrderButton.interface';
import { COLORS } from '../../../config/constants';
import { IconNames } from '../../Icon/Icon.types';

interface Info {
  icon: IconNames;
  color: string;
  borderColor: string;
  width: number;
  iconColor: string;
}

export const GetInfoFromStatus = (status: OrderStatus): Info => {
  switch (status) {
    case 'active':
      return {
        icon: 'clocks',
        color: COLORS.COLOR_DARK_GRAY,
        iconColor: COLORS.COLOR_GREEN_2,
        borderColor: COLORS.COLOR_GREEN_2,
        width: 68,
      };
    case 'successful':
      return {
        icon: 'accept-thin',
        color: COLORS.COLOR_DARK_GRAY,
        iconColor: COLORS.COLOR_ORANGE_1,
        borderColor: COLORS.COLOR_ORANGE_1,
        width: 90,
      };
    case 'unsuccessful':
      return {
        icon: 'cross',
        color: COLORS.COLOR_DARK_GRAY,
        iconColor: COLORS.COLOR_MIDDLE_GRAY,
        borderColor: COLORS.COLOR_MIDDLE_GRAY,
        width: 103,
      };
    default:
      return {
        icon: 'clocks',
        color: COLORS.COLOR_MIDDLE_GRAY,
        iconColor: COLORS.COLOR_MIDDLE_GRAY,
        borderColor: COLORS.COLOR_LIGHT_GRAY_2,
        width: 68,
      };
  }
};
