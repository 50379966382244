import styled from 'styled-components';
import { COLORS } from '../../../../config/constants';

export const FooterContainer = styled.div`
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`;

export const BottomLink = styled.div`
  margin-top: 8px;
`;

export const WarningText = styled.div`
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.41px;
  line-height: 22px;
  margin: 5px;
`;
