import React, { ChangeEvent } from 'react';
import './ImageBlock.scss';

import { ImageBlockUploadStateProps } from './imageBlock.interface';

interface ImageBlockUploadProps extends ImageBlockUploadStateProps {
  onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ImageBlockUpload = ({
  Image,
  onUpload,
}: ImageBlockUploadProps) => {
  return (
    <div className="productImage__imageBlock pointer">
      <div className="image">
        <Image />
        <input
          type="file"
          name="image"
          accept=".jpeg, .png, .jpg"
          onChange={onUpload}
        />
      </div>
    </div>
  );
};
