import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

export const useProfile = ({ enabled = true } = {}) => {
  return useQuery(
    [QUERY_KEYS.PROFILE],
    async (): Promise<User> => {
      return axios.get('auth/profile');
    },
    { enabled },
  );
};
