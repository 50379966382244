import React from 'react';
import { useHistory } from 'react-router-dom';

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';

import { FollowingHeader } from '../shared/components/Header';
import { Follower } from '../shared/components/Follower';
import { useProfile, useUnfollowUser } from '../../../hooks/query';

export const Followings = () => {
  const history = useHistory();
  const { data: user, isSuccess } = useProfile();
  const { mutate: unfollowUser } = useUnfollowUser();

  const onAccessoryClick = () => history.push('/account/followers');

  const onUnfollow = async (id: number) => {
    await unfollowUser({ userId: id });
  };

  return (
    <PageWrapper>
      <FollowingHeader
        onAccessoryClick={onAccessoryClick}
        title="I am following"
      />
      {isSuccess &&
        user.following.map(({ id, profileImage, name, username }) => (
          <Follower
            avatar={profileImage}
            name={name}
            username={username}
            isFollowed
            onFollow={() => onUnfollow(id)}
            key={id}
          />
        ))}
      {isSuccess &&
        user.followingCompanies.map(({ id, name, username, profileImage }) => (
          <Follower
            key={id}
            name={name}
            username={username}
            avatar={profileImage}
            onClick={() => history.push(`/brand/${id}`)}
          />
        ))}
    </PageWrapper>
  );
};
