import React, { ChangeEvent } from 'react';

import './Rate.scss';
import { InputNumber } from '../../../../components/Input/Input';

interface RateProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const Rate = ({ onChange, value }: RateProps) => {
  return (
    <div className="commission__rate">
      <div className="title">Set a commission rate</div>
      <div className="description">
        Please enter the desired commission rate for your products, higher rate
        will lead to more shares and engagements
      </div>
      <div className="input-number">
        <InputNumber
          type="commission"
          measure="%"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};
