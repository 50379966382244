import React from 'react';
import {
  ArrowWrapper,
  ButtonDescription,
  ButtonWrapper,
  ContentWrapper,
  Description,
  Header,
} from './SuccessPopup.styles';
import { ArrowRightBoldIcon, LogoIcon } from '../../assets/icons';
import { COLORS } from '../../config/constants';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

interface SuccessPopupProps {
  isOpen: boolean;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ isOpen }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/account/orders');
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          borderRadius: '20px',
          gap: '20px',
          border: '1px solid #EEEEEE',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '-2px 2px 12px 0px #00000021',
          padding: '40px 30px 30px 30px',
        },
        overlay: {
          zIndex: 100,
        },
      }}
    >
      <ContentWrapper>
        <LogoIcon width={59} height={40} />
        <Header>Well done!</Header>
        <Description>
          Now you can monitor your campaign in the list of orders that you will
          find in the main user menu.
        </Description>
      </ContentWrapper>

      <ButtonWrapper onClick={handleClick}>
        <ButtonDescription>Go to co-orders</ButtonDescription>
        <ArrowWrapper>
          <ArrowRightBoldIcon
            width={6}
            height={12}
            color={COLORS.COLOR_MIDDLE_GRAY}
          ></ArrowRightBoldIcon>
        </ArrowWrapper>
      </ButtonWrapper>
    </Modal>
  );
};

export default SuccessPopup;
