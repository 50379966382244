import React from 'react';

import {
  ButtonFollowWrapper,
  IconWrapper,
  StyledText,
} from './ButtonFollow.style';
import { Icon } from '../../../../components/Icon/Icon';
import { COLORS } from '../../../../config/constants';
import { ButtonFollowStateProps } from './ButtonFollow.interface';

export const ButtonFollow = ({
  isActive,
  onClick,
  pointer,
}: ButtonFollowStateProps) => {
  return (
    <ButtonFollowWrapper
      pointer={pointer}
      isActive={isActive}
      onClick={onClick}
    >
      <IconWrapper isActive={isActive}>
        <Icon name={!isActive ? 'plus' : 'accept'} />
      </IconWrapper>
      <StyledText
        color={!isActive ? COLORS.COLOR_ORANGE_1 : COLORS.COLOR_DARK_GRAY}
      >
        Follow
      </StyledText>
    </ButtonFollowWrapper>
  );
};
