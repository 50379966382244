import { io, Socket } from 'socket.io-client';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './authContext';
import { LocalStorageService } from '../services/core/localStorage.service';

interface IWebsocketContext {
  socket: Socket | null;
}

interface Props {
  children: React.ReactNode;
}

// "undefined" means the URL will be computed from the `window.location` object
let URL = '';

switch (process.env.REACT_APP_NODE_ENV) {
  case 'production':
    URL = 'wss://api.wecobi.com';
    break;
  case 'test':
    URL = 'wss://api.stage-wecobi.com';
    break;
  default:
    URL = 'ws://localhost:3000';
    break;
}

export const WebsocketContext = createContext<IWebsocketContext>(
  {} as IWebsocketContext,
);

export const useWebsocketContext = () => {
  return useContext(WebsocketContext);
};

export const WebsocketProvider = ({ children }: Props) => {
  const { loggedInAccount } = useAuthContext();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (loggedInAccount) {
      const accessToken = LocalStorageService.getItem('accessToken');

      const _socket = io(URL as string, {
        // query: { accessToken },
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setSocket(_socket);
    }
  }, [loggedInAccount]);

  return (
    <WebsocketContext.Provider value={{ socket }}>
      {children}
    </WebsocketContext.Provider>
  );
};
