import styled from 'styled-components';

import { COLORS } from '../../../../config/constants';

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 375px;
  height: 713px;
  background-color: #fff;
`;

export const DropdownHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  height: 57px;
  padding-left: 19px;
  border-bottom: 1px solid ${COLORS.COLOR_LIGHT_GRAY_1};
  margin-bottom: 13px;
`;

export const DropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 29px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`;
