import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import { NavigationPlug, Wrapper } from './Navigation.styles';
import { Icon } from '../Icon/Icon';

import { UserDrawerContent } from './DrawerContent/UserDrawerContent';
import { observer } from 'mobx-react-lite';
import { CompanyDrawerContent } from './DrawerContent/CompanyDrawerContent';
import { useAuthContext } from '../../contexts/authContext';
import { useProfile, useUserCompanies } from '../../hooks/query';
import { CompanyV2 } from '../../types/company.types';
import {
  isCompanyLoggedIn,
  isUserLoggedIn,
} from '../../utils/react-query-config.util';
import { ifDesktop, isDesktop } from '../../utils/ifDesktop.util';
import { useWindowSize } from 'usehooks-ts';
import { useWebsocketContext } from '../../contexts/websocketContext';
import { Message } from '../../types/messages.types';
import { useUnseenNotifications } from '../../hooks/query/useUnseenNotifications';
import { useIsCompanyOnboard } from '../../hooks/query/useIsCompanyOnboard';

const ICON_COLOR = '#000';
const NAVIGATION_HEIGHT = 64;

interface NavigationProps {
  absolute?: boolean;
}

export const Navigation = observer(({ absolute = false }: NavigationProps) => {
  const { width } = useWindowSize();
  const { socket } = useWebsocketContext();
  const location = useLocation();
  const [hasNotification, setHasNotification] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const { loggedInAccount } = useAuthContext();
  const id = loggedInAccount?.id as number;
  const userId = loggedInAccount?.userId as number;
  const { data: user, isSuccess } = useProfile({
    enabled: !!loggedInAccount,
  });
  const { data: companies = [], isSuccess: isCompaniesSuccess } =
    useUserCompanies(
      { id: loggedInAccount?.type === 'user' ? id : userId },
      {
        enabled:
          isUserLoggedIn(loggedInAccount) || isCompanyLoggedIn(loggedInAccount),
      },
    );
  const { data: unseenNotifications = 0 } = useUnseenNotifications(id, {
    enabled: isUserLoggedIn(loggedInAccount),
  });
  const { mutate: isCompanyOnboard } = useIsCompanyOnboard();

  const company = useMemo(() => {
    return companies.find(({ id: companyId }) => id === companyId) as CompanyV2;
  }, [companies, id]);

  const handleOpen = (open: boolean) => {
    setIsOpen(open);
    setHasNotification(false);
    isCompanyOnboard({ companyId: company.id });
  };

  useEffect(() => {
    if (unseenNotifications) {
      setHasNotification(true);
    }
    if (!socket) {
      return;
    }

    const onReceiveMessageNotification = (message: Message) => {
      if (
        message.reflectMessage ||
        location.pathname.includes('account/messages')
      ) {
        return;
      }
      setHasNotification(true);
    };

    socket.on('receive-message', onReceiveMessageNotification);

    return () => {
      socket.off('receive-message', onReceiveMessageNotification);
    };
  }, [socket, socket?.connected, location]);

  return (
    <div>
      <Wrapper height={NAVIGATION_HEIGHT} width={ifDesktop(width)}>
        {!!loggedInAccount ? (
          <>
            {hasNotification ? (
              <Icon
                name="burger-notification"
                height={37}
                color={ICON_COLOR}
                pointer
                onClick={() => {
                  handleOpen(true);
                }}
              />
            ) : (
              <Icon
                name="burger"
                height={37}
                color={ICON_COLOR}
                pointer
                onClick={() => {
                  handleOpen(true);
                }}
              />
            )}
          </>
        ) : (
          <div />
        )}
        <Link to="/">
          <Icon name="wecobi" />
        </Link>
        <Link to="/search">
          <Icon
            name="search"
            width={23}
            height={22}
            color={ICON_COLOR}
            pointer
          />
        </Link>
      </Wrapper>
      {isDesktop(width) && isSuccess && isCompaniesSuccess && isOpen && (
        <div
          style={{
            position: 'fixed',
            zIndex: 500,
            height: 718,
            width: 300,
            display: 'flex',
          }}
        >
          <div>
            {loggedInAccount?.type === 'user' && (
              <UserDrawerContent user={user} />
            )}
            {loggedInAccount?.type === 'company' && (
              <CompanyDrawerContent company={company} user={user} />
            )}
          </div>
          <div
            onClick={() => {
              handleOpen(false);
            }}
            style={{
              minWidth: 75,
            }}
          />
        </div>
      )}
      {!isDesktop(width) && !!loggedInAccount && isSuccess && (
        <Drawer anchor="left" open={isOpen} onClose={() => handleOpen(false)}>
          {loggedInAccount?.type === 'user' && (
            <UserDrawerContent user={user} />
          )}
          {loggedInAccount?.type === 'company' && (
            <CompanyDrawerContent company={company} user={user} />
          )}
        </Drawer>
      )}
      {!absolute && <NavigationPlug height={NAVIGATION_HEIGHT} />}
    </div>
  );
});
