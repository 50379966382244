import React, { ChangeEvent } from 'react';

import './ProductImages.scss';
import { ImageBlock } from './ImageBlock/ImageBlock';
import { ImageBlockUpload } from './ImageBlock/ImageBlockUpload';
import { UploadIcon } from '../../../../../../assets/icons';
import { Image } from '../../../../../../utils/profileImage.util';

interface ProductImagesProps {
  images: Array<File | Image>;
  onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  onImageRemove: (image: Image | File, isUploadedImage?: boolean) => void;
}

export const ProductImages = ({
  images,
  onUpload,
  onImageRemove,
}: ProductImagesProps) => {
  return (
    <div className="productDetails__productImages">
      <div className="title">Product images</div>
      <div className="images">
        <ImageBlockUpload Image={UploadIcon} onUpload={onUpload} />
        {images.map((image, index) => (
          <ImageBlock
            image={
              (image as Image).url
                ? (image as Image).url
                : URL.createObjectURL(image as File)
            }
            key={index}
            onImageRemove={() => {
              if ((image as File).name) {
                onImageRemove(image);
              } else {
                onImageRemove(image, true);
              }
            }}
          />
        ))}
      </div>
      <div className="description">
        Upload high-quality product images against a clean, preferably white,
        background to showcase your product effectively.
      </div>
    </div>
  );
};
