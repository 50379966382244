import styled from 'styled-components';
import { COLORS } from '../../../config/constants';

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  font-family: 'Montserrat';
  padding: 14px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 157.2%;
  text-align: center;
  color: ${COLORS.COLOR_DARK_GRAY};
`;

export const OrderActionButton = styled(Button)`
  border-radius: 34px;
  display: flex;
  justify-content: center;
`;

export const OrderActionButtonCTA = styled(OrderActionButton)`
  background-color: ${COLORS.COLOR_ORANGE_1};
  color: ${COLORS.COLOR_WHITE};
  width: 100%;
`;
