import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CampaignV2 } from '../../types/company.types';
import { CreateCampaignFormData } from '../../pages/CampaignRegistration/CampaignRegistration';

interface CreateCampaignArgs {
  data: CreateCampaignFormData;
  id: number;
  stripeCompanyId: string;
}

export const useCreateCampaign = () => {
  return useMutation(
    async ({
      id,
      stripeCompanyId,
      data,
    }: CreateCampaignArgs): Promise<CampaignV2> =>
      axios.post(
        'campaigns',
        { companyId: id, stripeCompanyId, ...data },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
  );
};
