import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CampaignV2 } from '../../types/company.types';

export const useTrendingCampaigns = () => {
  return useQuery(
    [QUERY_KEYS.GET_TRENDING_CAMPAIGNS],
    async (): Promise<CampaignV2[]> => {
      return axios.get('campaigns/trending', {});
    },
  );
};
