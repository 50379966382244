import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { User } from '../../types/user.types';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

interface Data {
  companyId: number;
}

export const useFollowCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ companyId }: Data): Promise<User> =>
      axios.post('users/follow-company', {
        companyId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.PROFILE]);
        queryClient.invalidateQueries([QUERY_KEYS.GET_COMPANY]);
      },
    },
  );
};
