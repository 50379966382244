import styled from 'styled-components';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../config/constants';

interface WrapperProps {
  isActive: boolean;
  pointer: boolean | undefined;
}

interface IconProps {
  isActive: boolean;
}

export const ButtonFollowWrapper = styled.button`
  display: flex;
  align-items: center;
  min-width: 106px;
  height: 44px;
  background-color: white;
  box-shadow: ${(p: WrapperProps) =>
    !p.isActive ? '-2px 2px 12px rgba(0, 0, 0, 0.13)' : 'none'};
  border-radius: 25px;
  border: ${(p: WrapperProps) =>
    !p.isActive ? 'none' : `2px solid ${COLORS.COLOR_LIGHT_GRAY_1}`};
  cursor: ${(p: WrapperProps) => (p.pointer ? 'pointer' : 'default')};
`;
export const IconWrapper = styled.div`
  margin-left: ${(p: IconProps) => (p.isActive ? '15px' : '20px')};
`;

export const StyledText = styled(Text)`
  margin-left: 5px;
`;
