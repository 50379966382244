import React from 'react';

import { Icon } from '../../../../components';
import {
  Wrapper,
  Container,
  Title,
  CardText,
  CopyContainer,
  CopyText,
} from './CommunityCard.style';

// TODO Copy link
export const CommunityCard = () => {
  const textToCopy = 'This is amazing! Check out Wecobi.com!';
  const handleCopyClick = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(textToCopy);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Wrapper>
      <Container>
        <Icon name="logo-sliced" width={186} height={80} />
        <Title>i co-buy</Title>
        <CardText>Hey! Join us in wecobi</CardText>
        <CopyContainer>
          <Icon
            onClick={handleCopyClick}
            name="copy"
            width={26}
            height={30}
            color="#e6e6e6"
          />
          <CopyText onClick={handleCopyClick}>Click to copy the link</CopyText>
        </CopyContainer>
      </Container>
    </Wrapper>
  );
};
