import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 19}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.236 16.906l-.002-.002C6.64 14.47 4.566 12.516 3.13 10.695 1.703 8.889 1 7.329 1 5.695 1 3.015 3.004 1 5.5 1c1.425 0 2.818.692 3.73 1.792l.77.929.77-.93C11.682 1.693 13.076 1 14.5 1 16.996 1 19 3.016 19 5.695c0 1.634-.703 3.194-2.129 5-1.437 1.82-3.511 3.774-6.105 6.21h-.002l-.764.72-.764-.72z"
        stroke={props.color || '#D5D5D5'}
        strokeWidth={2}
      />
    </svg>
  );
}

export const HeartIcon = React.memo(SvgComponent);
