import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CampaignV2 } from '../../types/company.types';

interface AddProductImageArgs {
  images: File[];
  id: number;
}

export const useAddProductImages = () => {
  return useMutation(
    async ({ id, images }: AddProductImageArgs): Promise<CampaignV2> =>
      axios.post(
        `campaigns/${id}/product-images`,
        { images },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
  );
};
