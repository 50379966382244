import React from 'react';

const SVGComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '8'}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.35345 0.305097C1.94389 -0.0867024 1.2634 -0.101726 0.833513 0.271543C0.403629 0.644812 0.387146 1.26502 0.796698 1.65682C0.796698 1.65682 7.21473 7.70291 7.23337 7.7194C7.67687 8.10579 8.38008 8.09134 8.80403 7.68713L15.2021 1.65688C15.6123 1.2657 15.597 0.645466 15.1678 0.271542C14.7386 -0.102381 14.0581 -0.0883957 13.6478 0.302779L8.34039 5.22008C8.1487 5.39768 7.85257 5.39771 7.66084 5.22015L2.35345 0.305097Z"
        fill={props.color || '#434343'}
      />
    </svg>
  );
};

export const ArrowDownBoldIcon = React.memo(SVGComponent);
