import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { User } from '../../types/user.types';

export const useUsers = (config: Config = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_USERS],
    async (): Promise<User[]> => {
      return axios.get('users');
    },
    config,
  );
};
