import React, { forwardRef, LegacyRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './datePicker.module.scss';

interface DatePickerProps {
  selected: Date | undefined;
  onChange: (value: Date) => void;
  placeholder: string;
  disabled?: boolean;
}

interface InputProps {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Input = forwardRef(
  (
    { value, onClick, placeholder, disabled }: InputProps,
    ref: LegacyRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        className={styles.button}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref}
      >
        {value || placeholder}
      </button>
    );
  },
);

const DatePicker = ({
  selected,
  onChange,
  placeholder,
  disabled = false,
}: DatePickerProps) => {
  return (
    <div>
      <ReactDatePicker
        selected={selected}
        onChange={onChange}
        customInput={<Input />}
        placeholderText={placeholder}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
      />
    </div>
  );
};

export default DatePicker;
