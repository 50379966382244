import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Country } from '../../types/country.types';

export const useCountries = () => {
  return useQuery([QUERY_KEYS.GET_COUNTRIES], async (): Promise<Country[]> => {
    return axios.get('countries');
  });
};
