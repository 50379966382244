import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 36}
    height={props.height || 36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.173 10.225c0-.48.169-.869.508-1.165.339-.31.783-.466 1.334-.466.55 0 .995.155 1.334.466.339.296.508.685.508 1.165 0 .466-.17.854-.508 1.164-.339.297-.784.445-1.334.445s-.995-.148-1.334-.445a1.514 1.514 0 0 1-.508-1.164Zm3.324 3.367v11.731h-2.986V13.592h2.986Z"
      fill="#D5D5D5"
    />
    <rect
      x={1}
      y={1}
      width={34}
      height={34}
      rx={17}
      stroke="#D5D5D5"
      strokeWidth={2}
    />
  </svg>
);

export const InfoIcon = React.memo(SvgComponent);
