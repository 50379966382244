import { DrawerItemProps } from './components/DrawerItem/DrawerItem';

export interface ContentProps {
  mainItems: Omit<DrawerItemProps, 'type'>[];
  secondaryItems?: Omit<DrawerItemProps, 'type' | 'accessoryText'>[];
}

export const userContent: Pick<ContentProps, 'secondaryItems'> = {
  secondaryItems: [
    { iconName: 'user-account', title: 'My account', navigatePath: '/account' },
    {
      iconName: 'settings',
      title: 'Settings',
      navigatePath: '/account/settings',
    },
    { iconName: 'book', title: 'User guide', navigatePath: '/terms' },
  ],
};

export const companyContent: ContentProps = {
  mainItems: [
    {
      iconName: 'price-tag',
      title: 'Campaigns',
      navigatePath: '/account/company/campaigns',
    },
  ],
  secondaryItems: [
    {
      iconName: 'user-account',
      title: 'Company Account',
      navigatePath: '/account/brand',
    },
    {
      iconName: 'settings',
      title: 'Settings',
      navigatePath: '/account/settings',
    },
    { iconName: 'book', title: 'Brand guide', navigatePath: '/terms' },
  ],
};
