import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { CampaignV2 } from '../../types/company.types';

interface CampaignsParams {
  companyId?: number;
  sort?: any;
  filter?: any;
}

interface CampaignsConfig {
  enabled?: boolean;
}

export const useCampaigns = (
  { companyId, sort, filter }: CampaignsParams = {},
  config: CampaignsConfig = {},
) => {
  return useQuery(
    [QUERY_KEYS.GET_CAMPAIGNS, companyId, sort, filter],
    async (): Promise<CampaignV2[]> => {
      return axios.get('campaigns', {
        params: {
          companyId,
          ...sort,
          ...filter,
        },
      });
    },
    config,
  );
};
