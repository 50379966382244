import React from 'react';

import { OrderRow } from '../../components/OrderRow';
import { CampaignV2 } from '../../../../types/company.types';
import { FooterContainer, WarningText } from './OrderExpandedFinished.style';
import { OrderActionButton } from '../styles';
import { CampaignExpandedFinishedProps } from '../types';

export const OrderExpandedFinished = ({
  startDate,
  endDate,
}: Omit<CampaignV2, 'status'>) => (
  <>
    <OrderRow title="Campaign started:" date={startDate as Date} />
    {endDate && <OrderRow title="Campaign finished:" date={endDate} />}
  </>
);

export const CampaignExpandedFinished = ({
  startDate,
  endDate,
  goal,
  validIntents,
  onFinishCampaign,
  onCancelCampaign,
}: CampaignExpandedFinishedProps) => (
  <>
    <OrderRow title="Campaign started:" date={startDate as Date} />
    {endDate && <OrderRow title="Campaign finished:" date={endDate} />}
    {validIntents < goal && (
      <div>
        <WarningText>
          This campaign have {goal - validIntents} unsuccessful orders, do you
          want to finalize campaign?
        </WarningText>
        <FooterContainer>
          <OrderActionButton onClick={onFinishCampaign}>
            Finalize the campaign
          </OrderActionButton>
          <OrderActionButton onClick={onCancelCampaign}>
            Cancel the campaign
          </OrderActionButton>
        </FooterContainer>
      </div>
    )}
  </>
);
