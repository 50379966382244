import React from 'react';

import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { AccountInfo } from '../shared/AccountInfo/AccountInfo';
import { MyAccountTab } from './MyAccountTab/MyAccountTab';
import { Loader } from '../../../components/Loader';
import { TabsWrapper } from './MyAccountTab/MyAccountTab.style';
import { useHistory } from 'react-router-dom';
import {
  useProfile,
  useDeleteProfileImage,
  useUpdateProfileImage,
  useWishlist,
} from '../../../hooks/query';
import { getImage } from '../../../utils/profileImage.util';
import { useAuthContext } from '../../../contexts/authContext';
import { useUserOrderedCampaigns } from '../../../hooks/query/useUserOrderedCampagins';

export const MyAccount = () => {
  const { loggedInAccount } = useAuthContext();
  const history = useHistory();
  const id = loggedInAccount?.id as number;

  const { data: user, isSuccess } = useProfile();
  const { data: campaigns = [] } = useUserOrderedCampaigns(
    { userId: id },
    { enabled: !!loggedInAccount },
  );
  const { data: likes = [] } = useWishlist(
    {
      id: loggedInAccount?.id as number,
    },
    {
      enabled: !!loggedInAccount,
    },
  );
  const { mutate: deleteProfileImage } = useDeleteProfileImage();
  const { mutate: updateProfileImage } = useUpdateProfileImage(
    { id },
    { enabled: !!loggedInAccount },
  );

  const onAvatar = async (e: any) => {
    const image = e.target.files?.item(0);
    updateProfileImage(image);
  };

  const onRemoveAvatar = () => {
    if (user) {
      deleteProfileImage(user.id);
    }
  };

  return (
    <>
      {isSuccess ? (
        <PageWrapper style={{ backgroundColor: '#F7F7FA', minHeight: '100vh' }}>
          <AccountInfo
            type="owner-view"
            title={user.name}
            username={user.username}
            activity={{
              followers: user.followers.length + user.followersCompanies.length,
              following: user.following.length + user.followingCompanies.length,
              shares: user.shares.length,
            }}
            avatar={getImage(user.profileImage)}
            isFollowed={false}
            onAvatar={onAvatar}
            onRemoveAvatar={onRemoveAvatar}
          />
          <TabsWrapper>
            <MyAccountTab
              onPlus={() => history.push('/search')}
              tabTitle="Co-orders"
              tabIcon="cart-filled"
              products={campaigns}
            />
            <MyAccountTab
              onPlus={() => history.push('/search')}
              tabTitle="Wish list"
              tabIcon="like"
              products={likes}
            />
          </TabsWrapper>
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  );
};
