import React from 'react';
import Modal from 'react-modal';
import { WINDOW } from '../../config/constants';
import styles from './errorModal.module.scss';

interface Props {
  isOpen: boolean;
  onButtonClick?: () => void;
  children?: JSX.Element;
  title?: string;
  subtitle?: string;
  buttonText?: string;
}

const ErrorModal = ({
  isOpen = false,
  onButtonClick,
  children,
  title = 'Error',
  subtitle = 'Something went wrong',
  buttonText = 'OK',
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: {
          width: '337px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          borderRadius: '20px',
          gap: '20px',
          border: '1px solid #EEEEEE',
          transform: 'translate(-50%, -50%)',
          maxWidth: WINDOW.MAX_WIDTH - 100,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '-2px 2px 12px 0px #00000021',
          padding: '40px 30px 30px 30px',
        },
        overlay: {
          zIndex: 100,
        },
      }}
    >
      {children ? (
        children
      ) : (
        <>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          <button className={styles.button} onClick={onButtonClick}>
            <div className={styles.buttonText}>{buttonText}</div>
          </button>
        </>
      )}
    </Modal>
  );
};

export default ErrorModal;
