import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CampaignV2 } from '../../types/company.types';
import { UpdateCampaignFormData } from '../../pages/CampaignRegistration/CampaignRegistration';

interface UpdateCampaignArgs {
  data: UpdateCampaignFormData;
  id: number;
}

export const useUpdateCampaign = () => {
  return useMutation(
    async ({ id, data }: UpdateCampaignArgs): Promise<CampaignV2> =>
      axios.patch(`campaigns/${id}`, data),
  );
};
