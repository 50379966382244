import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LocalStorageService } from '../services/core/localStorage.service';
import {
  LoginPayload,
  useCreateUser,
  useLogin,
  useProfile,
} from '../hooks/query';

interface LayoutProps {
  children: React.ReactNode;
}

interface IFormInput {
  name: string;
  username: string;
  email: string;
  password: string;
}

interface IAuthContext {
  login: ({
    email,
    password,
  }: Pick<IFormInput, 'email' | 'password'>) => Promise<void>;
  logout: () => void;
  setLoggedInAccount: Dispatch<any>;
  loggedInAccount: SelectedAccount | null;
  signup: ({ name, username, email, password }: IFormInput) => Promise<void>;
  googleLogin: (loginPayload: LoginPayload) => void;
}

export interface SelectedAccount {
  id: number;
  username?: string;
  userId?: number;
  type: 'company' | 'user';
}

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider = observer(({ children }: LayoutProps) => {
  const accessToken = LocalStorageService.getItem('accessToken');

  const [loggedInAccount, setLoggedInAccount] =
    useState<SelectedAccount | null>(null);

  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: signIn } = useLogin();
  const { data: userProfile, isLoading } = useProfile({
    enabled: accessToken !== null,
  });

  useEffect(() => {
    const currentAccount = LocalStorageService.getItem(
      'currentAccount',
    ) as SelectedAccount;
    if (currentAccount) {
      setLoggedInAccount(currentAccount);
    }
  }, []);

  useEffect(() => {
    const currentAccount = LocalStorageService.getItem(
      'currentAccount',
    ) as SelectedAccount;

    // Check if account was changed (if id and type are different)
    if (
      currentAccount &&
      loggedInAccount &&
      loggedInAccount.id !== currentAccount.id
    )
      LocalStorageService.setItem('currentAccount', loggedInAccount);
  }, [loggedInAccount]);

  const login = async ({
    email,
    password,
  }: Pick<IFormInput, 'email' | 'password'>) => {
    const {
      accessToken,
      user: { id, username },
    } = await signIn({ email, password });
    LocalStorageService.setItem('accessToken', accessToken);
    const currentAccount: SelectedAccount = {
      id,
      type: 'user',
      username,
    };
    LocalStorageService.setItem('currentAccount', currentAccount);
    setLoggedInAccount(currentAccount);
  };

  const googleLogin = ({
    accessToken,
    user: { id, username },
  }: LoginPayload) => {
    LocalStorageService.setItem('accessToken', accessToken);
    const currentAccount: SelectedAccount = {
      id,
      type: 'user',
      username,
    };
    LocalStorageService.setItem('currentAccount', currentAccount);
    setLoggedInAccount(currentAccount);
  };

  const logout = () => {
    LocalStorageService.removeItem('accessToken');
    LocalStorageService.removeItem('currentAccount');
    setLoggedInAccount(null);
  };

  const signup = async ({ name, username, email, password }: IFormInput) => {
    const {
      accessToken,
      user: { id },
    } = await createUser({ name, username, email, password });
    LocalStorageService.setItem('accessToken', accessToken);
    const currentAccount: SelectedAccount = {
      id,
      type: 'user',
      username,
    };
    LocalStorageService.setItem('currentAccount', currentAccount);
    setLoggedInAccount(currentAccount);
  };

  useEffect(() => {
    if (!accessToken) {
      logout();
    }
  }, [isLoading, userProfile]);

  return (
    <AuthContext.Provider
      value={{
        signup,
        login,
        logout,
        loggedInAccount,
        setLoggedInAccount,
        googleLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
