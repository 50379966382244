import React, { useEffect, useMemo, useState } from 'react';

import './CampaignDetails.scss';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { Overview } from './components/Overview/Overview';
import { Description } from './components/Description/Description';
import { MainInfo } from './components/MainInfo/MainInfo';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { observer } from 'mobx-react-lite';
import {
  useBuyProduct,
  useCampaign,
  useLikeCampaign,
  useOrders,
  useProfile,
  useShareCampaign,
  useUnlikeCampaign,
  useWishlist,
} from '../../hooks/query';
import { getImage } from '../../utils/profileImage.util';
import {
  ProductOption,
  ProductSize,
  UploadedImage,
} from '../../types/company.types';
import { useAuthContext } from '../../contexts/authContext';
import companyLogo from '../../assets/images/DefaultAccountAvatar.svg';
import ErrorModal from '../../components/Error/ErrorModal';
import { getRemainingDays } from '../../utils/campaignDuration.util';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../config/constants';
import { useInitializeOrder } from '../../hooks/query/useInitializeOrder';
import SuccessPopup from '../../components/PopupMessage/SuccessPopup';

interface QueryParams {
  campaignId: string;
}

interface DetailsMemo {
  isLiked: boolean;
  shareData: ShareData;
  avatars: UploadedImage[];
  isBought: boolean;
}

export const CampaignDetails = observer(() => {
  const { campaignId } = useParams<QueryParams>();
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { loggedInAccount } = useAuthContext();
  const { data: campaign, isSuccess: isCampaignSuccess } = useCampaign({
    id: campaignId as string,
  });
  const { data: likes = [] } = useWishlist(
    {
      id: loggedInAccount?.id as number,
    },
    {
      enabled: !!loggedInAccount && loggedInAccount.type === 'user',
    },
  );
  const { data: user, isSuccess: isUserSuccess } = useProfile({
    enabled: !!loggedInAccount,
  });
  const { data: userOrders } = useOrders();
  const { mutate: likeCampaign } = useLikeCampaign();
  const { mutate: unlikeCampaign } = useUnlikeCampaign();
  const { mutate: buyProduct } = useBuyProduct();
  const { mutate: initializeOrder } = useInitializeOrder();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCoBuyButtonDisabled, setIsCoBuyButtonDisabled] = useState(false);
  const { mutate: shareCampaign } = useShareCampaign();
  const [errorNoShippingAddress, setErrorNoShippingAddress] =
    useState<boolean>(false);
  const [selectedProductOption, setSelectedProductOption] =
    useState<ProductOption>();
  const [selectedSizeOption, setSelectedSizeOption] = useState<ProductSize>();

  useEffect(() => {
    const sessionId = query.get('session_id');
    if (sessionId) {
      buyProduct({
        campaignId: parseInt(campaignId),
        sessionId,
        referralId:
          query.get('referral') == 'null' ? undefined : query.get('referral'),
        productOption: query.get('productOption') || '',
        productSize: query.get('productSize') || '',
      });
      history.push({ pathname: `/campaign/${campaignId}?success=true` });
    }
  }, []);

  const onBuy = async () => {
    if (
      !!user &&
      (!user?.shippingInfo?.city ||
        !user?.shippingInfo?.country ||
        !user?.shippingInfo?.street ||
        !user?.shippingInfo?.streetNumber ||
        !user?.shippingInfo?.zipCode)
    ) {
      setErrorNoShippingAddress(true);
      return;
    }
    setIsCoBuyButtonDisabled(true);
    if (!loggedInAccount) {
      query.get('referral') == 'null'
        ? history.push('/login')
        : history.push(
            `/login?referral=${query.get(
              'referral',
            )}&&campaignId=${campaignId}`,
          );
    }
    const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
    // Initialize Stripe if not already initialized
    if (!stripe) {
      return;
    }
    const link =
      query.get('referral') ||
      selectedProductOption?.option ||
      selectedSizeOption?.size
        ? `?referral=${query.get('referral')}&&productOption=${
            selectedProductOption?.option
          }&&productSize=${selectedSizeOption?.size}&&`
        : '?';
    // Create a Stripe Checkout session on your server
    await initializeOrder(
      {
        campaignId: parseInt(campaignId),
        url: `${window.location.origin}/campaign/${campaignId}${link}`,
      },
      {
        onError: (e: any) => {
          setErrorMessage(e.message as string);
        },
      },
    );
  };

  const onLogoClick = () => {
    history.push(loggedInAccount ? `/brand/${campaign?.company.id}` : '/login');
  };

  const { isLiked, shareData, avatars, isBought }: DetailsMemo = useMemo(() => {
    const isLiked = !!likes.find(({ id }) => parseInt(campaignId) === id);
    const isBought = !!userOrders?.find(
      ({ campaign }) => campaign?.id === parseInt(campaignId),
    );
    const shareData = {
      title: campaign?.title,
      text: campaign?.description,
      url: `${window.location.origin}/campaign/${campaignId}?referral=${user?.id}`,
    };

    const avatars = campaign?.buyers
      .map(({ profileImage }) => profileImage)
      .slice(0, 4) as UploadedImage[];

    return { isLiked, shareData, avatars, isBought };
  }, [likes, campaign, isUserSuccess, userOrders]);

  const handleLike = async () => {
    if (!loggedInAccount) {
      history.push('/login');
    }
    if (isLiked) {
      await unlikeCampaign(parseInt(campaignId));
    } else {
      await likeCampaign(parseInt(campaignId));
    }
  };

  const handleShare = () => {
    if (loggedInAccount) {
      shareCampaign(parseInt(campaignId));
    }
  };

  return (
    <>
      {isCampaignSuccess ? (
        <PageWrapper className="campaign">
          <div className="overview">
            <Overview
              images={campaign.productImages}
              likeCount={campaign.supporters.length}
              isLiked={isLiked}
              shareCount={campaign.shares.length}
              onLikeClick={handleLike}
              shareData={shareData}
              handleShare={handleShare}
            />
          </div>
          <div className="main-info">
            <MainInfo
              title={campaign.title}
              description={campaign.shortDescription}
              logo={getImage(campaign.company.profileImage, companyLogo)}
              price={campaign.price}
              retailPrice={campaign.retailPrice}
              ordered={campaign.buyers.length}
              goal={campaign.goal}
              daysLeft={
                campaign?.endDate ? getRemainingDays(campaign?.endDate) : null
              }
              commission={campaign.commissionRate * 100}
              onBuy={onBuy}
              isCoBuyButtonDisabled={isCoBuyButtonDisabled}
              status={campaign.status}
              isBought={isBought}
              moreThanOne={campaign.moreThanOne}
              shareData={shareData}
              avatars={avatars}
              onAvatarsClick={() =>
                !loggedInAccount
                  ? history.push('/login')
                  : history.push(`/campaign/${campaignId}/buyers`)
              }
              onLogoClick={onLogoClick}
              handleShare={handleShare}
            />
            <SuccessPopup isOpen={!!query.get('success')} />
          </div>
          <div className="description">
            <Description
              shipping={campaign?.country.name === user?.shippingInfo?.country}
              setSelectedProductOption={setSelectedProductOption}
              selectedProductOption={selectedProductOption}
              setSelectedSizeOption={setSelectedSizeOption}
              selectedSizeOption={selectedSizeOption}
              productOptions={campaign.productOptions}
              fullDescription={campaign.description}
              shippingInfo={campaign.shippingInfo || 'Free shipping'}
              returnPolicy={campaign.returnPolicy}
              id={campaign.id}
              categories={campaign.productCategories}
            />
          </div>
          <ErrorModal
            isOpen={!!errorMessage}
            subtitle={errorMessage}
            onButtonClick={() => setErrorMessage('')}
          />
          <ErrorModal
            isOpen={errorNoShippingAddress}
            subtitle="You need to enter shipping address!"
            onButtonClick={() => {
              setErrorNoShippingAddress(false);
              history.push('/account/settings');
            }}
          />
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  );
});
