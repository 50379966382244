import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';

export const useUserTotalCommissions = () => {
  return useQuery(
    [QUERY_KEYS.GET_USER_TOTAL_COMMISSIONS],
    async (): Promise<number> => {
      return axios.get(`users/commission/user-total-commission`);
    },
  );
};
