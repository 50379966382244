import React, { useState } from 'react';

import './Login.scss';
import { LogoIcon } from '../../../assets/icons';
import { Title } from '../components';
import { PageWrapper } from '../../../containers/PageWrapper/PageWrapper';
import { Form } from './components/form';
import { Footer } from '../components/Footer';
import { Loader } from '../../../components/Loader';

export const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <PageWrapper className="login" noFooter>
      <div className="logo__wrapper">
        <div className="logo">
          <LogoIcon />
        </div>
      </div>
      <Title text="Login to" type="login-title" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="form__wrapper">
            <Form />
          </div>
          <Footer setIsLoading={setIsLoading} />
        </>
      )}
    </PageWrapper>
  );
};
