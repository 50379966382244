import React from 'react';

import { IconNames } from '../../Icon/Icon.types';
import { IconWrapper, ItemWrapper } from './index.style';
import { Icon } from '../../Icon/Icon';
import { Text } from '../../Text';
import { COLORS } from '../../../config/constants';

interface Props {
  iconName: IconNames;
  title: string;
  size: 'small' | 'large';
  id: number;
  active: boolean;
  toggleCategories: (categoryId: number) => void;
}

export const CategoryDropdownItem = ({
  iconName,
  title,
  size,
  id,
  active,
  toggleCategories,
}: Props) => {
  return (
    <ItemWrapper
      bgColor={active ? COLORS.COLOR_ORANGE_GRADIENT_1 : COLORS.COLOR_WHITE}
      size={size}
      onClick={() => toggleCategories(id)}
    >
      <IconWrapper>
        <Icon
          name={iconName}
          color={active ? COLORS.COLOR_WHITE : COLORS.COLOR_ORANGE_1}
        />
      </IconWrapper>
      <Text color={COLORS.COLOR_BLACK}>{title}</Text>
    </ItemWrapper>
  );
};
