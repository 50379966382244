import { CSSProperties } from 'react';
import { CampaignV2 } from '../../types/company.types';

export enum PRODUCT_SIZE {
  SMALL = 150,
  LARGE = 310,
}

export type ProductSizes = 'small' | 'large';

export interface ProductProps extends CampaignV2 {
  isLiked?: boolean;
  progressBarHeight?: number;
  style?: CSSProperties;
  size?: ProductSizes;
}

export type ProductComponentTypes = 'filled' | 'outlined';

export interface ProductComponentProps extends ProductProps {
  type: ProductComponentTypes;
  size?: ProductSizes;
}
