import React from 'react';

import { Wrapper, Container, TextWrapper } from './CategoryItem.styles';
import {
  CategoryItemProps,
  CATEGORY_BLOCK_SIZES,
  CATEGORY_SIZES,
  Categories,
} from './CategoryItem.types';

import { COLORS } from '../../../config/constants';
import { Icon } from '../../Icon/Icon';
import { Text } from '../../Text';

export const CategoryItem = ({
  name,
  size,
  isActive,
  style,
  onClick,
}: CategoryItemProps) => {
  const textSize =
    size === 'small' ? CATEGORY_SIZES.SMALL : CATEGORY_SIZES.MEDIUM;
  const itemSize =
    size === 'small' ? CATEGORY_BLOCK_SIZES.SMALL : CATEGORY_BLOCK_SIZES.MEDIUM;
  const iconColor = isActive ? COLORS.COLOR_WHITE : COLORS.COLOR_ORANGE_1;
  const backgroundColor = isActive ? COLORS.COLOR_ORANGE_1 : COLORS.COLOR_WHITE;

  return (
    <Wrapper style={style}>
      <Container size={itemSize} color={backgroundColor} onClick={onClick}>
        <Icon name={Categories[name].iconName} color={iconColor} />
      </Container>
      <TextWrapper size={textSize}>
        <Text
          weight={500}
          size={12}
          lineHeight="16.5px"
          color={COLORS.COLOR_MIDDLE_GRAY}
        >
          {Categories[name].title}
        </Text>
      </TextWrapper>
    </Wrapper>
  );
};
