import React from 'react';
import { ItemContainer, ItemUserContainer } from './Item.styles';
import { Text } from '../../../../../../../components';
import { Circle } from '../../../../../../../components/RadioButtonItem/Circle';
import { COLORS } from '../../../../../../../config/constants';

interface ItemProps {
  name: string;
  isPicked: boolean;
  onClick: () => void;
}

export const Item = ({ name, isPicked, onClick }: ItemProps) => (
  <ItemContainer onClick={onClick}>
    <ItemUserContainer>
      <Text
        size={17}
        color={COLORS.COLOR_DARK_GRAY}
        letterSpacing={-0.41}
        lineHeight="22px"
      >
        {name}
      </Text>
    </ItemUserContainer>
    <div>
      <Circle isVisible={isPicked} />
    </div>
  </ItemContainer>
);
