import React from 'react';

import {
  RadioButtonItemWrapper,
  CircleWrapper,
  TitleWrapper,
} from './RadioButtonItem.style';
import { Circle } from './Circle';

interface RadioButtonItemProps {
  title: string;
  name: string;
  index: number;
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}

export const RadioButtonItem = ({
  title,
  name,
  index,
  active,
  setActive,
}: RadioButtonItemProps) => {
  return (
    <RadioButtonItemWrapper onClick={() => setActive(index)}>
      <CircleWrapper name={name} type="radio" />
      <Circle isVisible={index === active} />
      <TitleWrapper>{title}</TitleWrapper>
    </RadioButtonItemWrapper>
  );
};
