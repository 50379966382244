import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import default_account_avatar from '../../../../assets/images/DefaultAccountAvatar.svg';
import { COLORS } from '../../../../config/constants';
import { Text } from '../../../../components';
import { Icon, IconProps } from '../../../../components/Icon/Icon';
import { UserService } from '../../../../services/entities/user.service';
import { UploadedImage } from '../../../../types/company.types';
import { getImage } from '../../../../utils/profileImage.util';

export interface FollowerProps {
  avatar: UploadedImage;
  name: string;
  username: string;
  isFollowed?: boolean;
  borderBottom?: boolean;
  onFollow?: () => void;
  onClick?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 72px;
  padding: 12.5px;
  border-top: 1px solid ${COLORS.COLOR_LIGHT_GRAY_1};
  border-bottom: ${(p: Pick<FollowerProps, 'borderBottom'>) =>
    p.borderBottom ? `1px solid ${COLORS.COLOR_LIGHT_GRAY_1}` : 'none'};
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
`;

const CredentialsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 50%;
  margin-right: 18.5px;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Name = styled(Text)`
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${COLORS.COLOR_BLACK};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Username = styled(Text)`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const FollowCTA = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const followedIconConfig: IconProps = {
  name: 'account-mark',
  width: 28,
  height: 14,
  color: COLORS.COLOR_ORANGE_1,
  pointer: true,
};

const notFollowedIconConfig: IconProps = {
  name: 'account-plus',
  width: 21,
  height: 14,
  color: COLORS.COLOR_DARK_GRAY,
  pointer: true,
};

export const Follower = ({
  avatar,
  name,
  username,
  isFollowed,
  borderBottom,
  onFollow,
  onClick,
}: FollowerProps) => {
  const history = useHistory();

  const followIcon = isFollowed ? followedIconConfig : notFollowedIconConfig;

  const onPress = () => history.push(`/users/${username}`);

  return (
    <Wrapper borderBottom={borderBottom}>
      <CredentialsContainer onClick={onClick ? onClick : onPress}>
        <AvatarContainer>
          <img src={getImage(avatar, default_account_avatar)} alt="avatar" />
        </AvatarContainer>
        <NameContainer>
          <Name>{name}</Name>
          <Username>{UserService.getUsername(username)}</Username>
        </NameContainer>
      </CredentialsContainer>
      {onFollow && (
        <FollowCTA onClick={onFollow}>
          <Icon {...followIcon} />
        </FollowCTA>
      )}
    </Wrapper>
  );
};
