import React from 'react';

import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { GuiderHeader } from '../Guide/components/GuideHeader';
import { GuideRow, GuideStandaloneText } from '../Guide/components/GuideRow';

const ABOUT_DATA = [
  {
    title: 'Challenging Outdated Beliefs',
    text: `For far too long, the retail industry has operated under two faulty premises. The first of these premises is the notion that people do not know what they want. This idea originated in the early days of mass retail, when shopping was a journey of discovery due to limited information about available products.\n\nShoppers often ended up buying things they didn't plan to purchase, this in turn led the retail industry to draw the conclusion that peoples inherit shopping behavior was geared towards impulse purchases. Out of which they build an entire ecosystem around, designed to elicit this behavior.\n\nThis had a tremendous impact on the retail industry not only in the way that they operated but also on deeper psychological level.\n\nBecause if you hold the notion that people do not know what they want,  then the logical conclusion will be that neither does the retailer, so the retail business  became an exercise in trial and error.\nCreating an enormous financial burden on the retail industry and a tremendous waste of the world’s natural resources!`,
  },
  {
    title: 'A Vision for Change',
    text: 'At Wecobi, we questioned these assumptions and embarked on a journey to revolutionize the retail landscape. Our vision was clear: eliminate waste in retail and create a more sustainable consumption society. We envisioned a world where both retailers and consumers could make informed decisions, reducing waste and environmental impact.',
  },
  {
    title: 'The Wecobi Solution',
    text: `Wecobi is not just an e-commerce platform; it's a game-changer. We've created a unique platform that empowers individuals to pool their purchasing power, unlocking the benefits of lower large order quantity pricing previously reserved for major vendors. But that's just the beginning.\n\nWith Wecobi, we eliminate the major unknowns that have plagued the retail industry for decades. Retailers can now know exactly what products will sell, where, and how many. This knowledge allows them to minimize losses, increase profits, and lower prices for customers.`,
  },
  {
    title: 'Turning Customers into Vendors',
    text: `We didn't stop there. Wecobi introduces a revolutionary commission-style incentive that transforms every customer into a vendor. By sharing products with friends on social media, buyers become brand ambassadors, helping retailers achieve unprecedented brand exposure at a fraction of the cost.`,
  },
  {
    title: 'Our Global Impact',
    text: `The impact of Wecobi goes beyond just changing the retail game; it's about making a positive impact on the world. Consider these staggering statistics:\n
• Over 12 billion tonnes of raw materials extracted worldwide end up as waste.
• The industry standard for full-price sell-through is around 60 percent, leaving 40 percent as waste.
• Dead inventory costs the US retail industry $50 billion annually.
• Brands globally spent a whopping $593 billion on advertising in 2020.
• 100,000 cargo ships are currently transporting goods on our seas, with just 16 of the largest producing as much pollution as all the world's cars combined.\n\nWecobi is committed to reducing waste, minimizing environmental impact, and revolutionizing the retail industry.\n\nJoin us on our journey to create a more sustainable and responsible world of retail at wecobi.com. Together, we can change the way we shop, eliminate waste, and make a lasting impact on our planet.`,
  },
];
const TEXT_PREVIEW = `At Wecobi, we are on a mission to transform the world of retail and usher in a new era of sustainable consumption.
 Our story begins with a profound belief that there's a better way to provide goods and services – a way that benefits everyone in the ecosystem, 
 from manufacturers to consumer and every one in between, while minimizing the global and environmental impact to the absolute necessary minimum.`;
export const About = () => (
  <PageWrapper>
    <GuiderHeader>About Wecobi</GuiderHeader>
    <GuideStandaloneText>{TEXT_PREVIEW}</GuideStandaloneText>
    {ABOUT_DATA.map(({ title, text }, index) => (
      <GuideRow key={title} index={index + 1} title={title} data={[{ text }]} />
    ))}
  </PageWrapper>
);
