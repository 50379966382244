import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { LocalStorageService } from '../services/core/localStorage.service';
import qs from 'qs';

export const axios: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  },
});

axios.interceptors.request.use((config) => {
  // TODO refresh token
  const accessToken = LocalStorageService.getItem('accessToken');

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  } as InternalAxiosRequestConfig;
});

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // TODO Proper error handling
    if (error.response) {
      return Promise.reject(error.response?.data);
    }
    return Promise.reject(error);
  },
);
