import React, { useEffect, useMemo, useState } from 'react';
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper';
import { Icon } from '../../../../components';
import { Input } from '../../../../components/reusable';
import {
  Button,
  ButtonWrapper,
} from '../../../../components/reusable/Button/Button';
import { Text } from '../../../../components';
import { Wrapper } from '../../../../components/reusable/Wrapper/Wrapper';
import { COLORS } from '../../../../config/constants';
import { ListItem } from '../../../../components/reusable/ListItem/ListItem';
import { CategoriesList } from '../../../Home/layouts/CategoriesPreview/CategoriesList';
import { Header, InputWithAnchor, SettingsAvatar } from './Settings.styles';
import { Loader } from '../../../../components/Loader';
import {
  CategoriesContainer,
  CategoriesWrapper,
} from '../../../Home/layouts/CategoriesPreview/CategoriesPreview.styles';
import {
  useCountries,
  useProfile,
  useUpdateProfileImage,
  useUpdateUser,
} from '../../../../hooks/query';
import { UserFormData } from '../../../../types/user.types';
import Select from '../../../../components/Select/Select';
import { getImage } from '../../../../utils/profileImage.util';
import { useAuthContext } from '../../../../contexts/authContext';
import { isUserLoggedIn } from '../../../../utils/react-query-config.util';

const initialUserFormData: UserFormData = {
  name: '',
  username: '',
  shippingInfo: {
    country: '',
    city: '',
    street: '',
    streetNumber: '',
    zipCode: '',
  },
  categoryIds: [],
  deliveryLocationIds: [],
};

export const Settings = () => {
  const { loggedInAccount } = useAuthContext();
  const id = loggedInAccount?.id as number;
  const [userFormData, setUserFormData] = useState(initialUserFormData);
  const [errorMessage, setErrorMessage] = useState('');

  const { data: userProfile, isSuccess: isUserProfileSuccess } = useProfile();
  const { data: countries = [], isLoading: isCountriesLoading } =
    useCountries();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    { id },
    { enabled: isUserLoggedIn(loggedInAccount) },
  );
  const { mutate: updateProfileImage } = useUpdateProfileImage(
    { id },
    { enabled: isUserLoggedIn(loggedInAccount) },
  );

  useEffect(() => {
    if (!isUserProfileSuccess) {
      return;
    }
    setUserFormData({
      name: userProfile.name,
      username: userProfile.username,
      shippingInfo: {
        ...initialUserFormData.shippingInfo,
        ...(userProfile?.shippingInfo as any),
      },
      categoryIds: userProfile.feedCategories.map(({ id }) => id),
      deliveryLocationIds: userProfile?.deliveryLocations.map(({ id }) => id),
    });
  }, [userProfile, isUserProfileSuccess]);

  const onUpdateProfileImage = (e: any) => {
    const image = e.target.files?.item(0);
    updateProfileImage(image);
  };

  const onUpdateProfile = () => {
    updateUser(userFormData, {
      onError: (err: any) => {
        setErrorMessage(err.message);
      },
    });
  };

  const onInputChange = (value: string, field: string) => {
    const props = field.split('.');
    if (props[0] === 'shippingInfo') {
      setUserFormData({
        ...userFormData,
        shippingInfo: {
          ...userFormData.shippingInfo,
          [props[1]]: value,
        },
      });
    } else {
      setUserFormData({
        ...userFormData,
        [props[0]]: value,
      });
    }
  };

  const onCategoryClick = (categoryId: number) => {
    if (userFormData.categoryIds.includes(categoryId)) {
      setUserFormData({
        ...userFormData,
        categoryIds: userFormData.categoryIds.filter(
          (id: number) => id !== categoryId,
        ),
      });
    } else {
      setUserFormData({
        ...userFormData,
        categoryIds: [...userFormData.categoryIds, categoryId],
      });
    }
  };

  const onDeleteDeliveryLocation = (id: number) => {
    setUserFormData({
      ...userFormData,
      deliveryLocationIds: userFormData.deliveryLocationIds.filter(
        (_id) => id !== _id,
      ),
    });
  };

  const onAddDeliveryLocation = ({ value }: { value: number }) => {
    setUserFormData({
      ...userFormData,
      deliveryLocationIds: [...userFormData.deliveryLocationIds, value],
    });
  };

  const isFormLoading = () => {
    return isUpdatingUser;
  };

  const { countriesOptions } = useMemo(() => {
    const countriesOptions = countries
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
      .filter(({ value }) => !userFormData.deliveryLocationIds.includes(value));
    return { countriesOptions };
  }, [countries, userFormData.deliveryLocationIds]);

  return isUserProfileSuccess ? (
    <PageWrapper>
      <Header>
        <Icon name="settings" />
        <Text ml={10} color={COLORS.COLOR_DARK_GRAY} size={24}>
          Settings
        </Text>
      </Header>

      <Wrapper mx={19} mt={30} mb={92}>
        <Text weight={800} size={19}>
          Profile settings
        </Text>

        <SettingsAvatar mt={20}>
          <div>
            <img src={getImage(userProfile.profileImage)} alt="avatar" />
            <input
              type="file"
              name="image"
              accept=".jpeg, .png, .jpg"
              onChange={onUpdateProfileImage}
            />
          </div>
        </SettingsAvatar>

        <Input
          my={10}
          value={userFormData.name}
          onChangeText={(e) => onInputChange(e.target.value, 'name')}
        />
        <InputWithAnchor>
          <Text>@</Text>
          <Input
            pl={30}
            mb={10}
            value={userFormData.username}
            onChangeText={(e) => onInputChange(e.target.value, 'username')}
          />
        </InputWithAnchor>

        <Text weight={800} mt={20} size={19}>
          Shipping address
        </Text>
        <Input
          my={10}
          placeholder="Enter your country"
          value={userFormData.shippingInfo.country}
          onChangeText={(e) =>
            onInputChange(e.target.value, 'shippingInfo.country')
          }
        />
        <Input
          my={10}
          placeholder="Enter your current city"
          value={userFormData.shippingInfo.city}
          onChangeText={(e) =>
            onInputChange(e.target.value, 'shippingInfo.city')
          }
        />
        <Input
          mb={10}
          placeholder="Enter your current street"
          value={userFormData.shippingInfo.street}
          onChangeText={(e) =>
            onInputChange(e.target.value, 'shippingInfo.street')
          }
        />
        <Input
          mb={10}
          placeholder="Enter your current street number"
          value={userFormData.shippingInfo.streetNumber}
          onChangeText={(e) =>
            onInputChange(e.target.value, 'shippingInfo.streetNumber')
          }
        />
        <Input
          mb={10}
          placeholder="Enter your current zip code"
          value={userFormData.shippingInfo.zipCode}
          onChangeText={(e) =>
            onInputChange(e.target.value, 'shippingInfo.zipCode')
          }
        />

        {errorMessage && (
          <Text
            align="center"
            color={COLORS.COLOR_ORANGE_2}
            size={14}
            weight={300}
            mt={40}
            mb={10}
          >
            {errorMessage}
          </Text>
        )}

        <Text weight={800} size={19} mt={40} mb={10}>
          Feed settings
        </Text>

        <Text color={COLORS.COLOR_DARK_GRAY} weight={300} size={14} mb={20}>
          Choose categories you would like to follow
        </Text>

        <CategoriesWrapper>
          <CategoriesContainer>
            <CategoriesList
              selectedCategoryIds={userFormData.categoryIds}
              onClick={(categoryId) => onCategoryClick(categoryId)}
            />
          </CategoriesContainer>
        </CategoriesWrapper>

        <Text weight={800} size={19} mb={20}>
          Delivery location of campaigns
        </Text>

        {countries
          .filter(({ id }) => userFormData.deliveryLocationIds.includes(id))
          .map(({ id, name }) => (
            <ListItem
              onDelete={() => onDeleteDeliveryLocation(id)}
              mb={8}
              value={name}
            />
          ))}

        <Select
          placeholder="Add Country"
          fullWidth
          options={countriesOptions}
          isLoading={isCountriesLoading}
          menuPlacement="top"
          handleSelect={onAddDeliveryLocation}
        />

        <ButtonWrapper>
          <Button
            onClick={onUpdateProfile}
            disabled={isFormLoading()}
            isDisabled={isFormLoading()}
            isBottomFixed
            isRounded
            type="submit"
          >
            {isFormLoading() ? 'Loading...' : 'Save changes'}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </PageWrapper>
  ) : (
    <Loader absoluteCenter />
  );
};
