import React, { useState } from 'react';
import { COLORS, WINDOW } from '../../config/constants';
import ThemeChanger from '../ThemeChanger/ThemeChanger';
import { Navigation } from '../../navigation';
import wecobiWhite from '../../assets/images/wecobi-white.png';
import wecobiOrange from '../../assets/images/wecobi-orange.png';
import wecobi from '../../assets/images/wecobi.png';
import logo1 from '../../assets/images/logo1.svg';
import logo2 from '../../assets/images/logo2.svg';
import logo3 from '../../assets/images/logo3.svg';
import { LocalStorageService } from '../../services/core/localStorage.service';

const theme = [
  {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundImage: `url(${wecobiWhite})`,
    backgroundColor: COLORS.COLOR_LIGHT_GRAY_1,
  },
  {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundImage: `url(${wecobiOrange})`,
  },
  {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundImage: `url(${wecobi})`,
    backgroundColor: '#000000',
  },
];

const logos = [logo1, logo2, logo3];

const DesktopWrapper = () => {
  const themeIndex = LocalStorageService.getItem<string>('theme');
  const [selectedThemeIndex, setSelectedThemeIndex] = useState<number>(
    themeIndex ? parseInt(themeIndex) : 0,
  );

  const bgStyles = theme[selectedThemeIndex];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        height: '100vh',
        transition: 'background-color 0.5s ease-in-out',
        ...bgStyles,
      }}
    >
      {selectedThemeIndex === 1 && (
        <div
          style={{
            position: 'absolute',
            height: '100vh',
            width: '100%',
            background: `linear-gradient(180deg, #FCBA69 0%, #F98140 100%)`,
            zIndex: -1,
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 80,
          left: 80,
        }}
      >
        <img src={logos[selectedThemeIndex]} alt="logo" />
      </div>
      <div
        style={{
          display: 'flex',
          height: '790px',
          maxHeight: '790px',
          width: WINDOW.MAX_WIDTH_DESKTOP + 20,
          boxSizing: 'border-box',
          border: '10px solid black',
          borderRadius: 8,
          background: COLORS.BACKGROUND_COLOR,
        }}
      >
        <ThemeChanger
          setSelected={setSelectedThemeIndex}
          selectedIndex={selectedThemeIndex}
        />
        <Navigation />
      </div>
    </div>
  );
};

export default DesktopWrapper;
