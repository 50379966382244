import React from 'react';

import { AccountSlider } from '../../../../components/AccountSlider/AccountSlider';
import { DisplaySettings } from '../../../../components/DisplaySettings/DisplaySettings';
import {
  AccountProductsData,
  AccountProductsProps,
} from './AccountProducts.interface';
import { AccountProductsWrapper } from './AccountProducts.style';
import {
  DisplayGrid,
  DisplayVertical,
} from '../../../../components/DisplaySettings/displays/Displays';
import { DISPLAY_ICON_NAMES } from '../../../../components/DisplaySettings/DisplaySettings.config';

export const AccountProducts = ({
  index,
  setIndex,
  display,
  setDisplay,
  country,
  wishlist,
  orders,
}: AccountProductsProps) => {
  const data: AccountProductsData[] = [
    {
      title: 'Co-orders',
      icon: 'cart-filled',
      onRightArrow: () => setIndex(1),
      campaigns: orders,
    },
    {
      title: 'Wishlist',
      icon: 'like',
      onLeftArrow: () => setIndex(0),
      campaigns: wishlist,
    },
  ];

  const products = data[index].campaigns;

  return (
    <AccountProductsWrapper>
      <AccountSlider
        title={data[index].title}
        icon={data[index].icon}
        onLeftArrow={data[index].onLeftArrow}
        onRightArrow={data[index].onRightArrow}
      />
      <DisplaySettings
        display={display}
        setDisplay={setDisplay}
        country={country}
        disableCountry
        displayIcons={DISPLAY_ICON_NAMES.slice(0, 2)}
      />
      {display === 'grid' && <DisplayGrid products={products} />}
      {display === 'vertical' && <DisplayVertical products={products} />}
    </AccountProductsWrapper>
  );
};
