import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input, TextareaInput } from '../../components';
import { DropdownInput, Text } from '../../../../components';
import { COLORS } from '../../../../config/constants';
import { Company } from '../../../../types/company.types';
import {
  useCategories,
  useCountries,
  useCreateCompany,
} from '../../../../hooks/query';

interface CompanyProps {
  companyName: string;
  companyUsername: string;
  description: string;
  website: string;
  facebook: string;
  country: string;
  category: string;
  employeeCount: string;
  contactPerson: string;
  contactNumber: string;
  email: string;
}

interface CompanyForm {
  name: string;
  username: string;
  address: string;
  category: any;
  userId: number;
  country: any;
  email: string;
  contactPerson: string;
  contactNumber: string;
  employeesCount: string;
  notes: string;
}

export interface CompanyData
  extends Omit<CompanyProps, 'website' | 'facebook'>,
    Omit<Company, 'id'> {}

export const Form = () => {
  const history = useHistory();
  const [setError, setSetError] = useState('');
  const { data: categories = [], isFetching: isCategoriesFetching } =
    useCategories();
  const { data: countries = [], isFetching: isCountriesFetching } =
    useCountries();
  const { mutateAsync: createCompany, isLoading } = useCreateCompany();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CompanyForm>();

  const onSubmit = async (values: CompanyForm) => {
    const { country, category, ...rest } = values;
    try {
      await createCompany({
        countryId: country.value,
        categoryId: category.value,
        ...rest,
      });
      history.push('/');
    } catch (error: any) {
      setSetError(error.message);
    }
  };

  const { categoryOptions } = useMemo(() => {
    const categoryOptions = categories.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return { categoryOptions };
  }, [categories]);

  const { countryOptions } = useMemo(() => {
    const countryOptions = countries.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return { countryOptions };
  }, [countries]);

  return (
    <form className="signup-company-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs">
        <Input
          label="Company name"
          register={register('name', {
            required: 'You must specify a company name',
          })}
          error={errors.name?.message}
        />
        <Input
          label="Company username"
          register={register('username', {
            required: 'You must specify a company username',
          })}
          error={errors.username?.message}
        />
        <Controller
          name="country"
          control={control}
          rules={{ required: 'You must specify a country' }}
          render={({ field }) => (
            <DropdownInput
              options={countryOptions}
              isLoading={isCountriesFetching}
              label="Country"
              error={errors.country?.message}
              {...field}
            />
          )}
        />
        <Input
          label="Address"
          register={register('address', {
            required: 'You must specify a company name',
          })}
          error={errors.address?.message}
        />
        <Controller
          name="category"
          control={control}
          rules={{ required: 'You must specify a category' }}
          render={({ field }) => (
            <DropdownInput
              options={categoryOptions}
              isLoading={isCategoriesFetching}
              label="Category of goods"
              error={errors.category?.message}
              {...field}
            />
          )}
        />
        <Input
          label="Number of employees"
          register={register('employeesCount', {
            required: 'You must specify a number of employees',
          })}
          error={errors.employeesCount?.message}
        />
        <Input
          label="Contact person"
          register={register('contactPerson', {
            required: 'You must specify a contact person',
          })}
          error={errors.contactPerson?.message}
        />
        <Input
          label="Contact number"
          register={register('contactNumber', {
            required: 'You must specify a contact number',
          })}
          error={errors.contactNumber?.message}
        />
        <Input
          label="Email"
          register={register('email', {
            required: 'You must specify email',
            pattern: /^\S+@\S+$/i,
          })}
          error={errors.email?.message}
        />
        <TextareaInput
          label="Notes"
          register={register('notes')}
          error={errors.notes?.message}
        />
      </div>
      <div className="buttons">
        <Button type="submit" className="black" disabled={isLoading}>
          Send a request
        </Button>
        {setError && <p>{setError}</p>}
      </div>
      <div className="form_description_container">
        <Text size={14} color={COLORS.COLOR_MIDDLE_GRAY} weight={300}>
          We will contact you by email or phone number as soon as we can to
          proceed with registration of your company.
        </Text>
      </div>
    </form>
  );
};
