import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';

interface CreateStripeAccountArgs {
  email: string;
}

export const useCreateStripeAccount = () => {
  return useMutation(
    async ({ email }: CreateStripeAccountArgs): Promise<any> =>
      axios.post('orders/create-account', { email }),
  );
};
