import { useQuery } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { Config } from '../../types/react-query.types';
import { CampaignV2 } from '../../types/company.types';

interface Params {
  id: number;
}

export const useUsersWishlist = ({ id }: Params, config: Config = {}) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_WISHLIST],
    async (): Promise<CampaignV2[]> => {
      return axios.get(`users/${id}/wishlist`);
    },
    config,
  );
};
