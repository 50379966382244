import { useMutation } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { CompanyV2, CreateCompanyData } from '../../types/company.types';

export const useCreateCompany = () => {
  return useMutation(
    async (data: CreateCompanyData): Promise<CompanyV2> =>
      axios.post('companies', data),
  );
};
