import React from 'react';
import styled from 'styled-components';
import { Icon, Text } from '../../../../components';
import { COLORS } from '../../../../config/constants';
import { HeaderProps } from './Header';

interface BrandModalProps extends Pick<HeaderProps, 'modalData'> {
  title: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  line-height: 125.7%;
  margin-bottom: 16px;
`;

const Description = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  line-height: 157.2%;
  color: ${COLORS.COLOR_MIDDLE_GRAY};
  margin-bottom: 16px;
`;

const Links = styled.div`
  display: flex;
  margin-bottom: 16px;

  & a:not(:first-child) {
    margin-left: 10px;
  }
`;

// const WebsiteButton = styled(Button)`
//   width: 100%;
//   padding: 10px;
//   background: linear-gradient(270deg, #343434 0%, #000000 100%);
//   border-radius: 37px;
// `;

export const BrandModal = ({ title, modalData }: BrandModalProps) => (
  <Container>
    <Title>{title}</Title>
    <Description>{modalData?.description}</Description>
    <Links>
      <a href={`mailto:${modalData?.email}`}>
        <Icon name="email-black" />
      </a>
      <a href={`tel:${modalData?.contactNumber}`}>
        <Icon name="phone-black" />
      </a>
      {/*{modalData?.socials?.facebook && (*/}
      {/*  <a href={modalData?.socials?.facebook}>*/}
      {/*    <Icon name="facebook-black" />*/}
      {/*  </a>*/}
      {/*)}*/}
    </Links>
    {/*{modalData?.socials?.website && (*/}
    {/*  <a href={modalData?.socials?.website}>*/}
    {/*    <WebsiteButton>Visit Website</WebsiteButton>*/}
    {/*  </a>*/}
    {/*)}*/}
  </Container>
);
