import styled from 'styled-components';
import MainImage from '../../../../assets/images/home-main.png';

//MAIN CONTAINER

export const Wrapper = styled.div`
  width: 100%;
  height: 730px;
  background-image: url(${MainImage});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      #fff -491.74%,
      rgba(255, 255, 255, 0.84) -288.33%,
      rgba(255, 255, 255, 0.67) -51.02%,
      rgba(255, 255, 255, 0) 100%
    );
    width: 100%;
    height: 350px;
    mix-blend-mode: plus-lighter;
  }
`;

export const TextContainer = styled.div`
  width: 300px;
  display: flex;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 200px;
`;
