import React from 'react';

import { Product, ProductOutlined } from '../../Product/Product';
import { ProductProps } from '../../Product/Product.interfaces';
import {
  DisplayGridWrapper,
  DisplayGridProductWrapper,
  DisplayVerticalProductWrapper,
  DisplayVerticalWrapper,
  DisplayHorizontalWrapper,
} from './displays.style';
import { CompanyProducts } from '../../../pages/Search/components/CompanyProduct/CompanyProducts';
import { CompanyV2 } from '../../../types/company.types';
import { getImage } from '../../../utils/profileImage.util';

interface DisplayProductProps {
  products: ProductProps[];
}
interface DisplayHorizontalProps {
  companies: CompanyV2[];
}

export const DisplayGrid = ({ products }: DisplayProductProps) => (
  <DisplayGridWrapper>
    {products.map((product, index) => (
      <DisplayGridProductWrapper key={index}>
        <ProductOutlined {...product} size="small" />
      </DisplayGridProductWrapper>
    ))}
  </DisplayGridWrapper>
);

export const DisplayVertical = ({ products }: DisplayProductProps) => (
  <DisplayVerticalWrapper>
    {products.map((product, index) => (
      <DisplayVerticalProductWrapper key={index}>
        <Product {...product} size="large" progressBarHeight={13} />
      </DisplayVerticalProductWrapper>
    ))}
  </DisplayVerticalWrapper>
);

export const DisplayHorizontal = ({ companies }: DisplayHorizontalProps) => {
  return (
    <DisplayHorizontalWrapper>
      {companies
        .filter((company) => company.campaigns.length > 0)
        .map(({ profileImage, campaigns, name, id }) => (
          <CompanyProducts
            key={id}
            logo={getImage(profileImage)}
            products={campaigns}
            companyId={id}
            title={name}
          />
        ))}
    </DisplayHorizontalWrapper>
  );
};
