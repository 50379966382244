import React from 'react';
import { observer } from 'mobx-react-lite';

import { CampaignExpandable } from '../../components/Order/OrderExpandedable/OrderExpandable';
import { PageWrapper } from '../../containers/PageWrapper/PageWrapper';
import { AccountSlider } from '../../components/AccountSlider/AccountSlider';
import { useCampaigns } from '../../hooks/query';
import { useAuthContext } from '../../contexts/authContext';

export const CampaignList = observer(() => {
  const { loggedInAccount } = useAuthContext();
  const { data: campaigns = [] } = useCampaigns(
    {
      companyId: loggedInAccount?.id,
    },
    {
      enabled: !!loggedInAccount && loggedInAccount.type === 'company',
    },
  );

  return (
    <PageWrapper>
      <AccountSlider icon="price-tag" title="All campaigns" />
      {campaigns.map((campaigns, index) => (
        <CampaignExpandable key={index} {...campaigns} />
      ))}
    </PageWrapper>
  );
});
