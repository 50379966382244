import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { OrderExpandable } from '../../../../components/Order/OrderExpandedable/OrderExpandable';
import { PageWrapper } from '../../../../containers/PageWrapper/PageWrapper';
import { Follower } from '../../../Following/shared/components/Follower';
import {
  useCampaign,
  useFollowUser,
  useProfile,
  useUnfollowUser,
} from '../../../../hooks/query';

interface QueryParams {
  campaignId: string | undefined;
}

export const CampaignBuyers = observer(() => {
  const { campaignId } = useParams<QueryParams>();
  const { data: campaign, isSuccess } = useCampaign({
    id: campaignId as string,
  });
  const { data: user } = useProfile();
  const { mutate: followUser } = useFollowUser();
  const { mutate: unfollowUser } = useUnfollowUser();

  const onFollow = async (id: number, type: 'follow' | 'unfollow') => {
    if (type === 'follow') {
      await followUser({ userId: id });
    } else {
      await unfollowUser({ userId: id });
    }
  };

  return (
    <>
      {isSuccess ? (
        <PageWrapper>
          <OrderExpandable {...campaign} />
          {campaign.buyers.map(
            ({ profileImage, username, name, id }, index) => {
              const isFollowed = !!user?.following.find(
                ({ id: userId }) => id === userId,
              );
              return (
                <Follower
                  avatar={profileImage}
                  username={username}
                  name={name}
                  isFollowed={isFollowed}
                  key={index}
                  onFollow={() =>
                    onFollow(id, isFollowed ? 'unfollow' : 'follow')
                  }
                />
              );
            },
          )}
        </PageWrapper>
      ) : (
        <Loader absoluteCenter />
      )}
    </>
  );
});
