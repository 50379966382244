import React from 'react';

import { Wrapper, Input, Circle } from './SearchInput.styles';
import { Icon } from '../../../../components';
import { useSearchContext } from '../../../../contexts/searchContext';

export const SearchInput = () => {
  const { input, setInput } = useSearchContext();

  return (
    <Wrapper>
      <Input
        type="text"
        placeholder="Search products"
        value={input}
        onChange={(event) => setInput(event.target.value)}
      />
      <Circle>
        <Icon width={18} height={17} name="search-input" />
      </Circle>
    </Wrapper>
  );
};
