import React, { MutableRefObject } from 'react';
import { CSSObject } from 'styled-components';

import { Wrapper, IconWrapperProps } from './Icon.styles';
import { Icons, IconNames } from './Icon.types';

export interface IconProps
  extends React.SVGProps<SVGSVGElement>,
    IconWrapperProps {
  name: IconNames;
  ref?: MutableRefObject<SVGSVGElement>;
  pointer?: boolean;
  onClick?: () => void;
}

export const Icon = ({
  name,
  ref,
  pointer,
  containerStyles,
  onClick,
  ...props
}: IconProps) => {
  const Icon = Icons[name];

  const wrapperStyles: CSSObject = {
    width: props.width,
    height: props.height,
    cursor: onClick || pointer ? 'pointer' : 'none',
    ...containerStyles,
  };

  return (
    <>
      {pointer || containerStyles || onClick ? (
        <Wrapper containerStyles={wrapperStyles} onClick={onClick}>
          <Icon ref={ref} {...props} />
        </Wrapper>
      ) : (
        <Icon ref={ref} {...props} />
      )}
    </>
  );
};
