import React from 'react';

import {
  Content,
  Image,
  CampaignInfoWrapper,
  DescriptionWrapper,
  TitlesAndButtonWrapper,
  Titles,
  InfoWrapper,
} from './CampaignInfo.style';
import { ProgressBar } from '../../reusable';
import { OrderButton } from '../../Order/OrderButton/OrderButton';
import { ParticipantsList } from '../../ParticipantsList/ParticipantsList';
import { useHistory } from 'react-router-dom';
import { CampaignV2 } from '../../../types/company.types';
import TestBgImage from '../../../assets/images/test_bg.png';
import { getImage } from '../../../utils/profileImage.util';

export const CampaignInfo = (props: CampaignV2) => {
  const history = useHistory();
  const {
    id,
    title,
    description,
    status,
    price,
    buyers,
    goal,
    participants,
    productImages,
  } = props;

  const onClick = () =>
    history.push({ pathname: `/campaign/${id}`, state: JSON.stringify(props) });

  return (
    <CampaignInfoWrapper>
      <Content>
        <Image onClick={onClick}>
          <img
            src={getImage(productImages?.[0] || TestBgImage)}
            alt="campaign-image"
          />
        </Image>
        <DescriptionWrapper>
          <TitlesAndButtonWrapper>
            <Titles>
              <div className="title">{title}</div>
              <div className="subtitle">{description}</div>
            </Titles>
            <OrderButton status={status} />
          </TitlesAndButtonWrapper>
          <InfoWrapper>
            <p className="price">${price}</p>
            <p className="pcs">
              {buyers.length} / {goal}
            </p>
            {participants && participants.length > 0 && (
              <div className="participants">
                <ParticipantsList
                  width={53}
                  imageWidth={20}
                  images={participants}
                />
              </div>
            )}
          </InfoWrapper>
        </DescriptionWrapper>
      </Content>
      <ProgressBar height={6} pcs={buyers.length} pcsMax={goal} />
    </CampaignInfoWrapper>
  );
};
