import React from 'react';

import './Price.scss';
import { InputTitle } from './components/InputTitle/InputTitle';
import { InputRadioBlock } from './components/InputRadioBlock/InputRadioBlock';
import { CampaignDuration } from './components/CampaignDuration/CampaignDuration';
import {
  CreateCampaignFormData,
  KeyValueCollection,
} from '../../CampaignRegistration';
import { DateTime } from 'luxon';

interface Props<T> {
  formData: T;
  updateFormData: (key: keyof T, value: T[keyof T]) => void;
  batchUpdateFormData: (fields: KeyValueCollection<T>) => void;
}

export const Price = ({
  formData,
  updateFormData,
  batchUpdateFormData,
}: Props<CreateCampaignFormData>) => {
  return (
    <div className="campaignRegistration__price">
      <div className="container">
        <div className="price__title">
          Set a price and a goal of your campaign
        </div>
        <InputTitle
          type="price"
          measure="$"
          title="Retail price"
          value={String(formData.retailPrice)}
          onChange={(event) =>
            updateFormData('retailPrice', event.target.value)
          }
        />
        <InputTitle
          type="price"
          measure="$"
          title="Price of one product"
          value={String(formData.price)}
          onChange={(event) => updateFormData('price', event.target.value)}
        />
        <InputTitle
          type="goal"
          measure="pieces"
          title="Goal of the campaign"
          value={String(formData.goal)}
          onChange={(event) => updateFormData('goal', event.target.value)}
        />
        <div className="price__pricesCounter">
          <InputRadioBlock
            title="Can one user order more than one piece? "
            description={`If you focus on achieving more public engagements, we advice to set NO.
            If you focus on sales, we advice to set YES.`}
            leftRadioLabel="yes"
            rightRadioLabel="no"
            name="pricesCounter"
            setIsActive={(value) => updateFormData('moreThanOne', value)}
            isActive={formData.moreThanOne}
          />
        </div>
        <CampaignDuration
          index={1}
          isFirst={false}
          isActive={formData.hasDuration}
          setIsActive={(value) => {
            if (!value) {
              batchUpdateFormData({
                startDate: undefined,
                endDate: undefined,
                hasDuration: value,
              });
            } else {
              updateFormData('hasDuration', value);
            }
          }}
          onDurationChange={(e) => {
            const days = e.target.value;
            const currentDate = DateTime.now();
            const parsedDays = parseInt(days);

            if (Number.isNaN(parsedDays)) {
              return batchUpdateFormData({
                startDate: undefined,
                endDate: undefined,
              });
            }

            const endDate = currentDate.plus({
              days: !Number.isNaN(parsedDays) ? parsedDays : 0,
            });

            batchUpdateFormData({
              startDate: currentDate.toJSDate(),
              endDate: endDate.toJSDate(),
            });
          }}
          batchUpdateFormData={batchUpdateFormData}
          updateFormData={updateFormData}
          startDate={formData.startDate}
          endDate={formData.endDate}
        />
      </div>
    </div>
  );
};
