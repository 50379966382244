import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Input } from '../../components';
import { useAuthContext } from '../../../../contexts/authContext';

interface IFormInput {
  email: string;
  password: string;
}

export const Form = () => {
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const { login } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit = async (data: IFormInput) => {
    try {
      setLoading(true);
      setLoginError('');
      await login({
        email: data.email,
        password: data.password,
      });
      query.get('referral') === null
        ? history.push('/')
        : history.push(
            `/campaign/${query.get('campaignId')}?referral=${query.get(
              'referral',
            )}`,
          );
    } catch {
      setLoginError('Failed to login in account');
    }
    setLoading(false);
  };

  const onSignup = () => history.push('/signup');

  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="inputs">
        <Input
          type="email"
          label="Email"
          register={register('email', {
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
          error={
            errors.email?.type === 'required' ? 'Email is required' : undefined
          }
        />
        <Input
          type="password"
          label="Password"
          register={register('password', {
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
          error={errors.password && errors.password.message}
        />
      </div>
      <div className="buttons">
        <Button type="submit" className="orange" disabled={loading}>
          Login with email
        </Button>
        <Button className="white" disabled={loading} onClick={onSignup}>
          Sign up
        </Button>
      </div>
      {loginError && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>{loginError}</p>
        </div>
      )}
    </form>
  );
};
