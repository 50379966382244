import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '../../config/axios';
import { QUERY_KEYS } from '../../constants/queryKeys.const';
import { User } from '../../types/user.types';

type AddToMessageListData = {
  userId: number;
};

export const useAddToMessageList = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: AddToMessageListData): Promise<User> =>
      axios.post('users/add-to-message-list', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.PROFILE]);
      },
    },
  );
};
