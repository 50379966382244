import React from 'react';

import { DisplayType } from './DisplaySettings.types';
import {
  DisplayGrid,
  DisplayHorizontal,
  DisplayVertical,
} from './displays/Displays';
import { ProductProps } from '../Product/Product.interfaces';
import { CompanyV2 } from '../../types/company.types';

interface ProductListProps {
  display: DisplayType;
  products: ProductProps[];
  companies: CompanyV2[];
}

export const ProductList = ({
  display,
  products,
  companies,
}: ProductListProps) => {
  // TODO filter through companies and its campaigns (when horizontal display is selected)
  const renderList = () => {
    if (display === 'grid') {
      return <DisplayGrid products={products} />;
    } else if (display === 'vertical') {
      return <DisplayVertical products={products} />;
    } else if (display === 'horizontal') {
      return <DisplayHorizontal companies={companies} />;
    } else {
      return null;
    }
  };

  return <>{renderList()}</>;
};
