import { CSSObject } from 'styled-components';
import { COLORS } from './constants';

export const TEXT_DEFAULT_STYLES: CSSObject = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '157.2%',
  color: COLORS.COLOR_DARK_GRAY,
};
