import React from 'react';

import { useHistory } from 'react-router-dom';
import {
  NotificationDate,
  NotificationTitle,
  NotificationWrapper,
} from './Notification.style';
import { useSetSeen } from '../../hooks/query/useSetSeen';
import { CampaignInfo } from './CampaignInfo/CampaignInfo';

export const Notification = (props: {
  id: number;
  type: string;
  campaign: any;
  createdAt: Date;
}) => {
  const history = useHistory();
  const { id, type, campaign, createdAt } = props;
  const { mutateAsync: setSeen } = useSetSeen();

  const onClick = () => {
    setSeen(id);
    history.push({
      pathname: `/campaign/${campaign.id}`,
      state: JSON.stringify(props),
    });
  };

  const date = new Date(createdAt);
  let title;
  switch (type) {
    case 'campaign-successful':
      title = 'Campaign that you joined is successfully finished!';
      break;
    case 'campaign-cancelled':
      title =
        'Campaign that you joined was cancelled, you will receive the money back on your account';
      break;

    case 'new-order':
      title = ' has a new co-order';
      break;

    default:
      title = 'Unknown notification';
      break;
  }

  return (
    <NotificationWrapper onClick={onClick}>
      <NotificationTitle>{title}</NotificationTitle>
      <NotificationDate>
        {date.toLocaleString('de-DE', {
          dateStyle: 'short',
          timeStyle: 'short',
        })}
      </NotificationDate>
      <CampaignInfo buyers={campaign.orders} {...campaign} />
    </NotificationWrapper>
  );
};
