import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountSlider } from '../components/AccountSlider/AccountSlider';

import { DisplaySettings } from '../components/DisplaySettings/DisplaySettings';
import { DisplayType } from '../components/DisplaySettings/DisplaySettings.types';
import { ProductList } from '../components/DisplaySettings/ProductList';
import { Loader } from '../components/Loader';
import { PageWrapper } from '../containers/PageWrapper/PageWrapper';
import { useCompanies, useWishlist } from '../hooks/query';
import { useAuthContext } from '../contexts/authContext';
import { CompanyV2 } from '../types/company.types';

export const MyWishlist = () => {
  const history = useHistory();
  const { loggedInAccount } = useAuthContext();

  const { data: likes, isSuccess: isLikesSuccess } = useWishlist(
    {
      id: loggedInAccount?.id as number,
    },
    {
      enabled: !!loggedInAccount && loggedInAccount.type === 'user',
    },
  );
  const { data: companies = [], isSuccess: isCompaniesSuccess } =
    useCompanies();

  const [display, setDisplay] = useState<DisplayType>('vertical');

  return (
    <PageWrapper>
      <AccountSlider
        icon="heart"
        title="My wishlist"
        onRightArrow={() => history.push('/account/orders')}
      />
      <DisplaySettings
        country="All locations"
        display={display}
        setDisplay={setDisplay}
      />
      {isLikesSuccess && isCompaniesSuccess ? (
        <ProductList
          display={display}
          products={likes}
          companies={companies as CompanyV2[]}
        />
      ) : (
        <Loader absoluteCenter />
      )}
    </PageWrapper>
  );
};
