import React from 'react';
import styles from './userItem.module.scss';
import { getImage, Image } from '../../utils/profileImage.util';
import avatar from '../../assets/images/DefaultAccountAvatar.svg';

interface Props {
  profileImage: Image | null;
  name: string;
  username: string;
  unreadCount?: number;
  onClick: (username: string) => void;
}

const UserItem = ({
  profileImage,
  name,
  username,
  onClick,
  unreadCount,
}: Props) => {
  return (
    <div className={styles.container} onClick={() => onClick(username)}>
      <div className={styles.accountInfoContainer}>
        <div className={styles.profileImageContainer}>
          <img src={getImage(profileImage, avatar)} alt="avatar" />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.name}>{name}</div>
        </div>
      </div>
      <div className={styles.messageCountContainer}>
        <div className={styles.number}>{unreadCount}</div>
      </div>
    </div>
  );
};

export default UserItem;
