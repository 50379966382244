import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '../../../../../components';
import { COLORS } from '../../../../../config/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: #f8f8f8;
  padding: 15px;
`;

const EditContainer = styled.div`
  width: 59px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
`;

const AddContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 33px;
  cursor: pointer;
`;

export const ActionBar = () => {
  const history = useHistory();

  return (
    <Container>
      <EditContainer>
        <Icon name="dots" />
      </EditContainer>
      <AddContainer onClick={() => history.push('/campaign-registration')}>
        <Icon
          name="plus"
          height={22}
          width={22}
          color={COLORS.COLOR_MIDDLE_GRAY}
        />
      </AddContainer>
    </Container>
  );
};
