import styled from 'styled-components';
import { Text, Button as ComponentButton } from '../../components';
import { WINDOW } from '../../config/constants';

export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: WINDOW.MAX_WIDTH - 100,
    display: 'flex',
  },
  overlay: {
    zIndex: 100,
  },
};

export const Container = styled.div``;

export const Title = styled(Text)`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 125.7%;
  margin-bottom: 10px;
`;

export const Description = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.08px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled(ComponentButton)`
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  border: 1px solid grey;
  color: black;
`;
